import Tippy from '@tippyjs/react';
import React, { useEffect, useState } from 'react'
import { FaAccessibleIcon, FaAngleDown, FaAngleUp, FaBan, FaCheck, FaClock, FaLock, FaRegClock, FaTrash, FaUserClock } from 'react-icons/fa';
import { useGetCategoriesQuery } from '../../services/domainsAPI';
import TextPopOver from '../TextPopOver/TextPopOver'


const REQUIRED = "español"

function CategoryMultipleSelection({pending, rejected, selected, setNewSelections, placeholder}) {
    const [selection, setSelection] = useState([])
    const {data: items, isLoading, isError } = useGetCategoriesQuery()
    const [categories, setCategories] = useState([])
    const [itemsToSubmit, setItemsToSubmit] = useState([])
    const [dropdown, setDropdown] = useState(false);
    const [filteredCategories, setFilteredCategories] = useState([])
    useEffect(() => {
        if (isLoading || isError || !items) {
          return
        }
        const nCats = []
        items.map(item => {
          if (item.points < 1) {
              return
          }
          if (selected?.includes(item.category)) {
             return
          }
          nCats.push(item.category)
        })
        const sorted = nCats.sort((a,b) => {return a.localeCompare(b)})
        setCategories(sorted)
        setFilteredCategories(sorted)
      
        
      }, [items])

      useEffect(() => {
        const newSelection = []
        selected?.map(selec => {
          newSelection.push( {
            text: selec,
            removible: false
          })
        }) 
        if ( newSelection.length < 1) {
         const item = {
          text: REQUIRED,
          removible: false
        }
          
          newSelection.push(item)
          setItemsToSubmit([REQUIRED])
          setNewSelections([REQUIRED])
        }
        setSelection(newSelection)
        
      }, [])
      
     
      function handleDeleteTag(item) {
        const nItems = itemsToSubmit.filter(it => it !== item)
        setItemsToSubmit(nItems)
        setNewSelections(nItems)
        setSelection(selection.filter(selec => selec.text !== item))
      }

      function handleChange(event) {
        const input = event.target.value
        if (input.length > 0) {
            setDropdown(true)
            const nCats = categories.filter((cat) => {
              return cat.toLowerCase().includes(input.toLowerCase())
              
            })
            setFilteredCategories(nCats)
            return
        } 
        setDropdown(false)
        setFilteredCategories([...categories])
        
      }

      function handleSelection(item) {
        const newSelection = [...selection, {text:item, removible: true}]
        const newItemsToSubmit = [...itemsToSubmit, item]
        setSelection(newSelection)
        setItemsToSubmit(newItemsToSubmit)
        setNewSelections(newItemsToSubmit)
        setDropdown(false)
    }

  return (
    <div className='justify-center items-center gap-5'>
        <div className='flex px-2 flex-wrap gap-1 mb-4 '> 
            {selection.map(select => {
                
                return <span className='dark:border-gray-600   gap-1 dark:bg-gray-900 hover:shadow-lg dark:shadow-gray-800 group hover:border-transparent dark:hover:border-transparent  border-jacarta-100 font-display text-jacarta-500 flex h-9 items-center rounded-lg border bg-white px-4 text-sm font-semibold transition-colors  dark:text-white'>
                    {select.text}
                    {select.removible ? <FaTrash className='text-accent text-base hover:text-accent-dark cursor-pointer' onClick={() => handleDeleteTag(select.text)}/> : ''
                    }
                </span>
            })}
        </div>
        <div className='relative'>
        <input 
        onFocus={() => setDropdown(true)}
        placeholder={placeholder || 'Busca una categoría...'}
        className='text-gray-700 placeholder-gray-600 dark:placeholder-gray-300  dark:placeholder:gray-300 focus:ring-accent border-gray-100 w-full min-w-[250px] md:min-w-[400px] border py-3 px-4 rounded-md dark:border-transparent dark:bg-white/[.15] dark:text-white'
        onChange={handleChange}
        >
          
        </input>
        <button className='absolute right-4 top-3 text-gray-700 dark:text-gray-300 text-[24px] ' onClick={() => setDropdown(!dropdown)} >
          {dropdown?<FaAngleUp  className='hover:text-accent '  /> :  <FaAngleDown  className='hover:text-accent '  />}
        </button>

        <div className={dropdown ? "absolute w-full  bg-white rounded divide-y divide-gray-100 shadow dark:bg-gray-700" : "hidden"}>
                <ul className="max-h-48 px-3 pb-3 overflow-y-auto text-sm text-gray-700 dark:text-gray-200" >
            {filteredCategories.map(cat=> {
              if (rejected?.includes(cat)) {
                  return ( <li>
                    <TextPopOver text={"El dominio ya fue previamente solicitado y rechazado en esta categoría "} opacity="opacity-99">
                    <div className="flex items-center pl-2 rounded hover:bg-gray-100 dark:hover:bg-gray-600">
                    
                      <label  className="w-full py-2 ml-2 text-sm font-medium text-gray-900 rounded dark:text-gray-300">{cat}</label>
                      
                        <FaBan className=' mr-2 text-lg'/> 
                     
                    </div>
                    </TextPopOver>
                    </li> )
              }
              if (pending?.includes(cat)) {
                return (<li >
                   <TextPopOver text={"Ya existe una categorización pendiente en esta categoría"} opacity="opacity-99">
                  <div className="flex items-center pl-2 rounded hover:bg-gray-100 dark:hover:bg-gray-600">
                    
                    <label  className="w-full py-2 ml-2 text-sm font-medium text-gray-900 rounded dark:text-gray-300">{cat}</label>
                   
                      <FaClock className=' mr-2 text-lg'/>
                   
                   
                  </div>
                  </TextPopOver>
                  </li>)
            }
            if (itemsToSubmit?.includes(cat)) {
              return (<li >
                 
                <div className="flex items-center pl-2 rounded hover:bg-gray-100 dark:hover:bg-gray-600">
                  
                  <label  className="w-full py-2 ml-2 text-sm font-medium text-gray-900 rounded dark:text-gray-300">{cat}</label>
                 
                  <FaCheck className='text-accent mr-2'/>
                 
                 
                </div>
              
                </li>)
          }
            return (<li onClick={() => handleSelection(cat)}>
                  <div className="flex items-center pl-2 rounded hover:bg-gray-100 dark:hover:bg-gray-600 ">
                  
                  <label  className="w-full py-2 ml-2 text-sm font-medium text-gray-900 rounded dark:text-gray-300 cursor-pointer">{cat}</label>
                  </div>
            </li>)   
            })}
            
            </ul>

        </div>
        </div>
    </div>
  )
}

export default CategoryMultipleSelection