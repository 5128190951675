import React from 'react'
import { FaDiscord, FaHandHoldingHeart, FaTwitter } from 'react-icons/fa'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next';

function Footer() {
  const {t, i18n} = useTranslation('common');
  const footerLinks = [
    {
      id: "donate", 
      title: t('footer.donate'),
      link: "/donate", 
      icon: <FaHandHoldingHeart/>,
      externalLink: false,
    },
    {
      id: "about", 
      title: t('footer.about'),
      link: "/about", 
      icon: "",
      externalLink: false,
    },
    {
      id: "faq", 
      title: t('footer.faq'),
      link: "https://docs.ensesp.io/", 
      icon: "",
      externalLink: true,
    },
    {
      id: "twitter", 
      title: t('footer.twitter'),
      link: "https://twitter.com/ENSesp", 
      icon: <FaTwitter></FaTwitter>,
      externalLink: true,
    },
    {
      id: "discord", 
      title: t('footer.discord'),
      link: "https://discord.gg/ZxKVBCXA6n", 
      icon: <FaDiscord></FaDiscord>,
      externalLink: true,
    }
    
  ]
  /*
  return (
    <footer className='flex justify-center items-center '>
        <img className='h-[60px] mb-10 dark:hidden' src="/images/logow.png"/>
        <img className='h-[60px] mb-10 hidden dark:block' src="/images/logo.png"/>
    </footer>
  )*/
  return (
    <footer className="p-4 bg-gray-100 rounded-lg shadow flex flex-wrap justify-center md:items-center md:justify-between md:p-6 dark:bg-gray-800">
    <span className="text-sm text-gray-700 sm:text-center dark:text-gray-400 text-center mr-5">© 2022 <a href="/" className="hover:underline">ENS Español</a>
    </span>
    <ul className="flex flex-wrap items-center justify-center mt-3 text-sm text-gray-700 dark:text-gray-400 sm:mt-0 gap-y-2">
      {
        footerLinks.map(item => {
            return <li key={item.id} className='mr-4 md:mr-6  flex items-center gap-1 hover:underline hover:text-black dark:hover:text-white'>
              {item.externalLink?  <a key={item.id} href={item.link} className="  ">{item.title}</a> : <Link key={item.id} to={item.link}>
              {item.title}
              </Link>}
              {item.icon}</li>
        })
      }
    </ul>
</footer>
  )
}

export default Footer