import React, {useState} from 'react'
import { FaArrowUp } from 'react-icons/fa'

function BackToTop() {
    const [btnVisibilty, setBtnVisibilty] = useState("hidden")
    window.onscroll = function () {
        scrollFunction();
      };
      
      function scrollFunction() {
        if (document.body.scrollTop > 20 || document.documentElement.scrollTop > 20) {
          setBtnVisibilty("block")
        } else {
            setBtnVisibilty("hidden")
        }
      }
     
      
      function backToTop() {
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
      }
  return (
    <button onClick={backToTop} className={`${btnVisibilty} p-4 z-10 bg-accent text-white text-xl lg:text-4xl  rounded-full shadow-md hover:bg-accent-dark hover:shadow-lg   active:shadow-lg transition duration-150 ease-in-out  bottom-11 right-9 lg:bottom-20 lg:right-20 fixed`}>
        <FaArrowUp/>
    </button>
  )
}

export default BackToTop