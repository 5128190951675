import React from 'react'
import { useLocation } from 'react-router-dom'
import LoadingSpinner from '../../components/Utils/LoadingSpinner';
import NotFound from '../../components/Utils/NotFound';
import {useFilterDomainsQuery } from '../../services/domainsAPI';
import FilterResultBuilder from './FilterResultBuilder';


function FilterGlobalResult() {
  const { state} = useLocation();
  
  const {data, isLoading, isError} = useFilterDomainsQuery(state);
  if(isLoading) {
    return <div className='mt-5 flex justify-center items-center flex-col'>
     <LoadingSpinner />
    <div className='mt-2 dark:text-white'>Filtrando dominios...</div>
    </div>
  }
  
  if(!data || data.length < 1) {
    return <NotFound text="No encontramos resultados"/>
  }

  return (
    <FilterResultBuilder domains={data} from={"global"}/>
  )
}

export default FilterGlobalResult