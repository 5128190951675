import React from 'react'
import WalletButton from '../WalletButton/WalletButton'
import ThemeSwitcher from './ThemeSwitcher'
import {
    FaBars,
  } from 'react-icons/fa';
import LangSwitcher from './LangSwitcher';

function ToggleBarMobile(props) {
  const {openMenuCallback } = props

  return (
    <div className='ml-auto flex items-center justify-center '>
    <LangSwitcher/>
    <ThemeSwitcher/>
    <WalletButton></WalletButton>
    
    
    <div className="lg:hidden">
        
        <button
            className=" border-jacarta-100 hover:bg-accent dark:hover:bg-accent focus:bg-accent group ml-2 flex h-10 w-10 items-center justify-center rounded-full border bg-white transition-colors hover:border-transparent focus:border-transparent dark:border-transparent dark:bg-white/[.15]"
            aria-label="open mobile menu"
            onClick={() => {openMenuCallback()}}
        >
            <FaBars width="24"
                height="24" className="fill-jacarta-700 h-4 w-4 transition-colors group-hover:fill-white group-focus:fill-white dark:fill-white"/>
            
        </button>
    </div>
    </div>
  )
}

export default ToggleBarMobile