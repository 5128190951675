import React from 'react'
import { FaTwitter } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';

function Teams() {
  const {t, i18n} = useTranslation('common');
    const team = [
        {
            id: "pisco",
            image: "/images/team/pisco.jpg",
            name: "piscosour.eth",
            description: "Lead",
            twitter: "https://twitter.com/cerecrypto",
        },
        {
            id: "pablon",
            image: "/images/team/pablon.jpg",
            name: "pabl0n.eth",
            description: "Developer",
            twitter: "https://twitter.com/_pabl0n",
        },
        
        {
            id: "luiseto",
            image: "/images/team/luiseto.jpg",
            name: "luiseto.eth",
            description: "Data Analyst",
            twitter: "https://twitter.com/luiseto_eth",
        },
        {
            id: "khali",
            image: "/images/team/kevin.jpg",
            name: "Khali",
            description: "Designer",
            twitter: "https://twitter.com/Kevinml_07",
        },

    ]
  return (
    <div className='mb-20' >
            <h1 className='font-display text-gray-700 mb-10 mt-20 text-center text-3xl dark:text-white'>{t('about.team')}</h1>
            
          <div className="flex flex-wrap justify-center gap-12">
            {team.map((item) => {
              const { id, image, description, name, twitter } = item;
              return (
                <div
                  className="dark:bg-gray-700 rounded-xl max-w-[400px] max-h-[600px] dark:border-gray-600 border-gray-100 border bg-white p-6 text-center transition-shadow hover:shadow-xl shadow-md dark:shadow-gray-900"
                  key={id}
                >
                  <img
                    src={image}
                    className="rounded-2xl h-[300px] w-[300px] mx-auto mb-6 "
                    alt={name}
                  />
                  <h3 className="font-display text-gray-700 text-md dark:text-gray-300">
                    {name}
                  </h3>
                  <span className="text-gray-800 text-2xs dark:text-gray-100 font-medium tracking-tight">
                    {description}
                  </span>

                  <div className="mt-3 flex justify-center space-x-5">
                     <a target={"_blank"} href={twitter}>
                        <FaTwitter className='text-[#00acee] text-3xl '/>
                     </a>
                     
                  </div>
                </div>
              );
            })}
          </div>
        </div>
  )
}

export default Teams