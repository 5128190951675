import React from 'react'


  import Tippy from '@tippyjs/react';
import ActionsDropDown from '../ActionsDropdown/ActionsDropDown';
import DomainCard from './DomainCard';


function DomainsGrid({items, from}) {
    
    // grid grid-cols-1 gap-[1.875rem] sm:grid-cols-2  lg:grid-cols-4
  
  return (
    
    <div className='flex flex-wrap justify-center gap-[1.875rem]'>
       {
        items.map((item) => {
            const {
                id,
                name,
                label,
                tag,
                labelHash,
                categories,
                available,
                owner,
                ownerAlias,
            
            } = item
            
            return (
            <article key={name}>
                <DomainCard id={id} name={name} label={label} tag={tag} labelHash={labelHash} owner={owner} ownerAlias={ownerAlias} categories={categories} available={available} from={from}/>
            </article>
           
           )})
       }
    </div>
  )
}

export default DomainsGrid