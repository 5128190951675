import React, {useRef, useState} from 'react'
import useOutsideAlerter from '../../hooks/click_outside';
import useComponentVisible from '../../hooks/click_outside';
import { useTranslation } from 'react-i18next';



function GenericDropDown({buttonClassName,items, setValue}) {
    const {t, i18n} = useTranslation('common');
    const [selection, setSelection] = useState(t('utils.no_selection'))
    const wrapperRef = useRef(null)
    const {clickOutside, setClickOutside} = useOutsideAlerter(wrapperRef)
    const [dropdown, setDropdown] = useState(false);

    function handleSelection(item) {
        setSelection(item.text)
        setValue(item.value)
        setDropdown(false)
    }

    function handleDrownDown() {
        if (!clickOutside) {
            setDropdown(!dropdown)
        } 
        if (clickOutside) {
            setDropdown(true)
        }
        setClickOutside(false)

    }

    const noSelectionObject = {text:t('utils.no_selection'), value: undefined}
    const elements = [noSelectionObject, ...items]
    
    
  return (
    <div className='relative ' ref={wrapperRef}>
        <button class={`${buttonClassName}`}
         
         type="button"
         onClick={handleDrownDown}
         >
         <span>{selection}</span>
            <svg class="ml-2 w-4 h-4" aria-hidden="true" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7"></path></svg>
        </button>

        <div  className={!clickOutside && dropdown ? " z-50 absolute w-full bg-white rounded divide-y divide-gray-100 shadow dark:bg-gray-700" : "hidden"}>
            <ul className="py-1 text-sm text-gray-700 dark:text-gray-200">
           
                {elements.map(item => {
                    
                    return <li key={item.text} className="block py-2 px-4 hover:bg-gray-100 dark:hover:bg-gray-600 w-full text-start dark:hover:text-white cursor-pointer" onClick={()=> handleSelection(item)}>{item.text}</li>
                })}
        </ul>
                
           
        </div>
	</div>
  )
}

export default GenericDropDown