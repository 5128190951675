import Tippy from '@tippyjs/react'
import React from 'react'

function TextPopOver({text, children, opacity="opacity-90"}) {
  return (
    <Tippy content={<div className={`bg-gray-900 text-white p-2 shadow-lg ${opacity} rounded-lg `}> {text}</div>}>
        {children}
    </Tippy>
  )
}

export default TextPopOver