import React, {useState,useEffect} from 'react'
import { useCategoriesFromArrayQuery } from '../../services/domainsAPI'
import { useQuery, gql, useLazyQuery } from "@apollo/client";
import DomainsList from '../../components/DomainsList/DomainsList';
import { Store } from 'react-notifications-component';
import { computeHeadingLevel } from '@testing-library/react';

function FilterResultBuilder({domains, from, setStats, showSpanishCategoryFilter=true, hideRedundantCategory=undefined} ) {
    const {data: categories} = useCategoriesFromArrayQuery(domains)
    

    const [remaining, setRemaining] = useState([])
    const [payload, setPayload] = useState([])
    const [registrations, setRegistrations] = useState([])
    const [domainList, setDomainList] = useState([])
   
    const queryString = gql`
    query Registrations($payload: [String!]) {
      registrations(first: 1000, where: { labelName_in: $payload}) {
                expiryDate
                registrationDate
                registrant {
                  id
                }
                domain{
                  id
                  name
                  createdAt
                  labelName
                  labelhash
                  owner {
                    id
                  }
                }
              }
    }
    `;
    useEffect(() => {
        
        const firstK = domains.slice(0,1000)
        setPayload(firstK)
        
        setRemaining(domains.slice(1000,domains.length))
        /*
        if (domains.length > 1000)
        Store.addNotification({
          title: "Estamos filtrando el contenido para ti",
          message: "Son miles de resultados. Sea paciente.",
          type: "info",
          insert: "top",
          container: "bottom-right",
          dismiss: {
            duration: 5000,
            onScreen: true
          }
          
      });
      */
      }, [])

      const { data, loading, error } = useQuery(queryString, {
        variables: { payload },
      });

      useEffect(() => {
        if(registrations.length < 1 && !(from === "local" && !loading)) {
          return
        }
        const tableValues = []
        const uniqueOwner = new Map()
        registrations.map((registration) => {
          if(registration && registration.domain && registration?.domain?.labelName?.length >2)
          {
            
            const newDomain = {
                id: registration?.domain?.id,
                label: registration?.domain?.labelName,
                labelHash: registration?.domain?.labelhash,
                available: false,
                name:registration?.domain?.name,
                owner: registration?.registrant?.id,
                ownerAlias:registration?.registrant?.id,
                registrationDate: registration?.registrationDate,
                expiryDate: registration?.expiryDate,
                tag: "",
              }
              uniqueOwner.set(newDomain.name,true)
              if (categories && categories[registration?.domain?.labelName]) {
                
                newDomain.categories = categories[registration?.domain?.labelName]
                newDomain.tag= "español"
              }
              tableValues.push(newDomain)
              
              
          }
         
          
        })
        const registered = tableValues.length

        if(from === "local" && remaining.length<1 && domains.length > registrations.length) {
          
          for (let i =0; i < domains.length; i++) {
            let breaked = false
            for(let j =0; j < registrations.length; j++) {
              if (domains[i] === registrations[j].domain.labelName) {
                breaked = true
                break
              }
              
            }
            if (!breaked) {
              if(domains[i].length >2 ) {
                const newDomain = {
                  id: domains[i],
                  label: domains[i],
                  labelHash: "",
                  available: true,
                  name: `${domains[i]}.eth`,
                  owner: "",
                  ownerAlias:"",
                  registrationDate: "",
                  expiryDate: "",
                  tag: "",
                }
                if (categories && categories[domains[i]]) {
                  
                  newDomain.categories = categories[domains[i]]
                  newDomain.tag= "español"
                }
                tableValues.push(newDomain)
              }
              
              
            }
          }
        }
    
      
        const available = tableValues.length - registered
      
        
        setDomainList(tableValues)
        if (from === "local" && setStats) {setStats({available: available, uniqueOwners: uniqueOwner.size})}
      }, [registrations ,categories])

      useEffect(() => {
        if(data && data.registrations) 
        {  
          const registLen = registrations.length
          if(registLen === 0) {
            setRegistrations(data.registrations)
          }
          if (remaining.length>0) { 
          const firstK = remaining.slice(0, 1000)
          
          
          const rem = remaining.slice(1000, remaining.length)
          setRemaining(rem)
         
          
          
          setPayload(firstK)
          }
          if(registLen >0) {
            const newRg = [...registrations,...data.registrations]
            setRegistrations(newRg)
          }
        }
        

      }, [data])
  
      if (error) {
        
        return (
          <div>  Error</div>
        )
      }

      /*
      if(remaining.length< 1) {
        for(let i=0; i < domainList.length; i++) {
          for (let j =0; j <domainList.length; j++) {
            if (i === j) {
              continue
            }
            if(registrations[i].domain.labelName === registrations[j].domain.labelName) {
             
            }
          }
        }
      }
      
      
      
     
    */
      
      
  
  return (
  <DomainsList hideRedundantCategory={hideRedundantCategory} showSpanishCategoryFilter={showSpanishCategoryFilter} domains={domainList} from={"filter"} showAvailable={from==="local"} loading={remaining.length >0}/>
  )
}

export default FilterResultBuilder