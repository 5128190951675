import React from 'react'
import Tippy from '@tippyjs/react';
import TextPopOver from '../TextPopOver/TextPopOver';
import { Link } from 'react-router-dom'
import { ethers } from "ethers";
import ShortAddress from '../Utils/ShortAddress';
import { useTranslation } from 'react-i18next';

const omit = "español"



function DomainsTable({from, items}) {
    const {t, i18n} = useTranslation('common');
  return (
    <div className="scrollbar-custom overflow-x-auto">
        <div
            role="table"
            className="dark:bg-black dark:border-gray-600 border-gray-100 lg:rounded-2lg w-full min-w-[736px] border bg-white text-sm dark:text-white"
        >
            <div className="dark:bg-gray-900 bg-gray-100 flex" role="row">
                <div className="w-[20%] py-3 px-4" role="columnheader">
                    <span className="text-gray-700 dark:text-gray-100 w-full overflow-hidden text-ellipsis">
                        {t('domainlist.table.header.domain')}
                    </span>
                </div>
                <div className="w-[17%] py-3 px-4" role="columnheader">
                    <span className="text-gray-700 dark:text-gray-100 w-full overflow-hidden text-ellipsis">
                        {from === "filter"? t('domainlist.table.header.owner'): t('domainlist.table.header.spanish')}
                    </span>
                </div>
                <div className="w-[17%] py-3 px-4" role="columnheader">
                    <span className="text-gray-700 dark:text-gray-100 w-full overflow-hidden text-ellipsis">
                    {t('domainlist.table.header.categories')}
                    </span>
                </div>
                <div className="w-[17%] py-3 px-4" role="columnheader">
                    <span className="text-gray-700 dark:text-gray-100 w-full overflow-hidden text-ellipsis">
                    {t('domainlist.table.header.creation')}
                    </span>
                </div>
                <div className="w-[17%] py-3 px-4" role="columnheader">
                    <span className="text-gray-700 dark:text-gray-100 w-full overflow-hidden text-ellipsis">
                    {t('domainlist.table.header.expire')}
                    </span>
                </div>
                <div className="w-[12%] py-3 px-4" role="columnheader">
                    <span className="text-gray-700 dark:text-gray-100 w-full overflow-hidden text-ellipsis">
                    {t('domainlist.table.header.view')}
                    </span>
                </div>
                
                </div>

                {items.map((item => {
                  const {
                    id,
                    label,
                    labelHash,
                    name,
                    tag,
                    owner,
                    available,
                    ownerAlias,
                    registrationDate,
                    expiryDate ,
                    categories,
                  } = item
                  const formatRegistrationDate = available ? "-" : new Intl.DateTimeFormat('es-ES', { year: 'numeric', month: '2-digit', day: '2-digit'}).format(registrationDate*1000)
                  const formatExpirynDate = available ? "-" : new Intl.DateTimeFormat('es-ES', { year: 'numeric', month: '2-digit', day: '2-digit'}).format(expiryDate*1000)
                  const tokenID  = available ? "-" : ethers.BigNumber.from(labelHash).toString()
                  const categoriesString =  categories ? categories.filter(x => x !== omit).join(', '): ""
                  return (
                    
                    <a className="flex transition-shadow hover:shadow-lg" role="row">
                         
                        <div
                            className="dark:border-gray-700 border-gray-100 flex w-[20%] items-center border-t py-4 px-4"
                            role="cell"
                        >
                            <Link to={`/domain/${name}`} state={{ ownerAlias:ownerAlias }}>
                            <a>{name}</a>
                            
                            </Link>
                        </div>
                        
                        <div
                            className="dark:border-gray-700 border-gray-100 flex w-[17%] items-center whitespace-nowrap border-t py-4 px-4"
                            role="cell"
						>
                            {
                            from === "filter" ? <a href={`/user/${owner}`}><ShortAddress address={owner} length={12}/></a>  : 
                            (tag && tag.length > 3) ? <div className='bg-gray-700 flex items-center p-1 space-x-1 rounded-md '>
                                    <p className='text-white capitalize'>{tag}</p>         
                            </div> : <p className='pl-3'>---</p>}
                            
                        </div>
                        <div
                            className="dark:border-gray-700 border-gray-100 flex w-[17%] items-center whitespace-nowrap border-t py-4 px-4"
                            role="cell"
						>
                            {categoriesString.length > 1 ? 
                                    <a className='dark:text-white  whitespace-normal'>{categoriesString}</a>         
                            : <p className='pl-3'>---</p>}
                        </div>
                        <div
                            className="dark:border-gray-700 border-gray-100 flex w-[17%] items-center whitespace-nowrap border-t py-4 px-4"
                            role="cell"
						>
                           <p>{formatRegistrationDate}</p>
                        </div>
                        <div
                            className="dark:border-gray-700 border-gray-100 flex w-[17%] items-center whitespace-nowrap border-t py-4 px-4"
                            role="cell"
						>
                           <p>{formatExpirynDate}</p>
                        </div>
                        <div
                            className="dark:border-gray-700 border-gray-100 flex w-[12%] items-center whitespace-nowrap border-t py-4 px-4"
                            role="cell"
						>
                            <div className='flex justify-center'>
                           <figure className="relative mr-2 w-6 shrink-0  lg:mr-5">
                               <TextPopOver text={t('domainlist.table.etherscan')}>
                                    <a target="_blank"  href={`https://etherscan.io/nft/0x57f1887a8bf19b14fc0df6fd9b2acc9af147ea85/${tokenID}`}> <img src="https://etherscan.io/images/brandassets/etherscan-logo-circle.svg"></img> </a>
                               </TextPopOver>
                                    
                                
                            </figure>
                            <figure className="relative mr-2 w-6 shrink-0 lg:mr-5">
                                <TextPopOver text={t('domainlist.table.ensdomains')}>
                                    <a href={`https://app.ens.domains/name/${name}/details`} target="_blank">
                                        <img src="https://raw.githubusercontent.com/ensdomains/ensdomains-v2/5dd186fd7311249c3f28df5130a7dff30b487f38/src/assets/ensLogo.svg"></img>
                                    </a>
                                </TextPopOver>
                            </figure>
                            
                            </div>
                        </div>
                       
                    </a>
                   
                  )
                }))}
        </div>
    </div>
  )
}

export default DomainsTable