import React from 'react'

function ConnectSuggestion({text}) {
  return (
    <div className='flex flex-col items-center gap-5'>
        <img className='mt-5 max-w-[200px]' src="/images/connect-wallet-image.png"/>
        <span className='dark:text-gray-200 text-gray-900 text-xl font-medium  text-center lg:ml-5'>{text}</span>
    </div>
  )
}

export default ConnectSuggestion