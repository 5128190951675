import React from 'react'
import User from './User'
import { useAccount, useContractRead, useEnsName} from 'wagmi'
import ConnectSuggestion from '../components/Utils/ConnectSuggestion'
import { useBalance } from 'wagmi'
import { erc721ABI } from 'wagmi'
import { useTranslation } from 'react-i18next';




function UserProfile() {
  const {t, i18n} = useTranslation('common');
    const {address, isConnecting, isDisconnected } = useAccount()
    const { data: name, isError, isLoading } = useEnsName({
        address: address,
     })
     
    if (isConnecting) {
        return <div className='dark:text-white mt-10'>Conectando billetera...</div>
      }
      if (isDisconnected) {
        return <ConnectSuggestion  text={t('profile.notfound')} />
      }
  return (
    <User address={address} user={name} />
  )
}

export default UserProfile