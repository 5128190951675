import React from 'react'

function shortAddr(addr, charCount) {
    return `${addr.slice(0,charCount)}...`
}

function ShortAddress({address, length = 8}) {
  return (
    <>
    {address ? address.length > length  ? shortAddr(address, length): address :"---"  }
    </>
  )
}

export default ShortAddress