import React from 'react'
import StatsTiles from '../Stats/StatsTiles'
import CategoryIcon from './CategoryIcon'
import { useTranslation } from 'react-i18next';

function CategoryCard({domainsCount, categoryName,availables, uniqueOwners}) {
	const {t, i18n} = useTranslation('common');
  const statsDisplay = [
		{
			key: "domainsCount",
			label: t('category.card.domainsCount'),
			value: domainsCount,

		},
		{
			key: "availables",
			label: t('category.card.availables'),
			value:  availables,
		},
		{
			key: "uniqueOwners",
			label: t('category.card.uniqueOwners'),
			value: uniqueOwners,
		},
  ]
  return (
    <div className='flex flex-wrap justify-center mt-10'>
      <CategoryIcon categoryName={categoryName} textSize={ "text-2xl "} classN={"ml-2 h-40 w-full "} small={false}/>
      <StatsTiles stats={statsDisplay}/>
    </div>
    
  )
}

export default CategoryCard