import React from 'react'
import { FaTwitter } from 'react-icons/fa'
import Teams from '../../components/Hero/Teams'
import { useTranslation } from 'react-i18next';

function About() {
  const {t, i18n} = useTranslation('common');
  return (
    <>
    <div className="mx-auto max-w-3xl py-10 text-center">
        <h1 className="font-bold text-gray-700 mb-8 text-4xl  dark:text-white">
        {t('about.title')}
        </h1>
        <div className='bg-gray-100 p-10 dark:bg-gray-800  rounded-xl shadow-md shadow-accent'>
        <span className="dark:text-gray-300 text-lg">
          <p> {t('about.subtitle.p1')}</p>
          <br></br>
          <p>{t('about.subtitle.p2')}</p>
          <br></br>
          <p>{t('about.subtitle.p3')}</p>
        </span>
        <a target={"_blank"} href='https://twitter.com/ENSesp' className='flex justify-center mt-7 gap-2'>
            <span className='text-gray-600 dark:text-gray-300 mt-1'>{t('about.twitter')} </span>
            <FaTwitter className='text-[#00acee] text-3xl '/>
        </a>
        </div>

    </div>
    <Teams/>
    </>
  )
}

export default About