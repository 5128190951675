import React from 'react'
import { useParams } from 'react-router-dom'
import LoadingSpinner from '../../components/Utils/LoadingSpinner'
import NotFound from '../../components/Utils/NotFound'
import { useGetLastCategorizationDetailedQuery } from '../../services/domainsAPI'
import FilterResultBuilder from '../Filters/FilterResultBuilder'
import { useTranslation } from 'react-i18next';

function LastCategorizationsDetailed() {
  const {t, i18n} = useTranslation('common');
    const {interval} = useParams()
    const interv =  interval !== "day" && interval !== "week" && interval !== "month" ? "month" : interval
    const {data, isLoading, isError} = useGetLastCategorizationDetailedQuery({interval:interv})
    if(isLoading) {
		return <div className='mt-5 flex justify-center items-center flex-col'>
		 <LoadingSpinner />
		<div className='mt-2 dark:text-white'>{t('stats.loading')}</div>
		</div>
	 }
   
	 if(!data || !data.involved || isError) {
		return <NotFound text={t('stats.error')}/>
	  }
      const domains = []
      data?.involved.map(item => {
        const vals = item.split('-', 2)
        domains.push(vals[0])
      })
      if(!domains) {
       return <></>
      }
  return (
    <FilterResultBuilder domains={domains} from="local" ></FilterResultBuilder>
  )
}

export default LastCategorizationsDetailed