import React from 'react'

import {useParams, useLocation} from 'react-router-dom'
import User from './User'
import UserNoContext from './UserNoContextAlias'
import UserNoContextAddress from './UserNoContextAddress'

function UserRouter() {
  const {username, from} = useParams()
  const {state} = useLocation()
  

  if (!state || !state.ownerAddress) { 
    if(username.endsWith(".eth")) {
      return <UserNoContextAddress username={username} />
    } else {
      return <UserNoContext address={username}/>
    }
  }
  return (
    <User address={state.ownerAddress} user={username}/>
  )
}

export default UserRouter