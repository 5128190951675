import React from 'react'
import { FaInfoCircle } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import TextPopOver from '../TextPopOver/TextPopOver';
import ShortAddress from '../Utils/ShortAddress';
import { useTranslation } from 'react-i18next';



const REQUIRED = "español"
const ADMIN = "root"

function CategorizationTab({categories}) {
    const {t, i18n} = useTranslation('common');

    const statusMAP  = {
        "approved": {text: t('itemdetails.tabs.categorization.status_types.approved'), color: "text-green"},
        "pending": {text: t('itemdetails.tabs.categorization.status_types.pending'), color: "text-yellow"},
        "rejected": {text: t('itemdetails.tabs.categorization.status_types.rejected'), color: "text-red"},
    }

    function getCategoriesSlice(slice) {
        
        if (!slice) {
            return []
        }
        const response = slice.map(item => {
            const date = new Date(item.UpdatedAt)
            
            const options = {
            year: 'numeric', month: 'numeric', day: 'numeric',hour: 'numeric', minute: 'numeric',
            };
             const dateString = new Intl.DateTimeFormat('es-AR', options).format(date)

             const categoryProps = item.Category === REQUIRED ? { text: "Español (reconocimiento)", to: undefined} : { text: item.Category, to: `/category/${item.Category}`}
             const statusProps = statusMAP[item.Status]
             const requestedProps = item.RequestedBy === ADMIN ? { text: "ENS Español", to: undefined} : { text: item.RequestedBy, to: `/user/${item.RequestedBy}`}
            return {
                category: categoryProps,
                status: statusProps,
                color: statusProps.color,
                date: date,
                dateString: dateString,
                requested: requestedProps,
                reason: item.Reason,
            }
        })
        return response

    }
    const sortedCategorization = [...getCategoriesSlice(categories.approved),...getCategoriesSlice(categories.pending), ...getCategoriesSlice(categories.rejected)].sort((a, b) => {return b.date - a.date })
    
    
  return (
    <div
    role="table"
    className=" dark:bg-black dark:border-gray-600 border-gray-100 max-h-72 w-full overflow-y-auto overflow-x-hidden rounded-lg rounded-tl-none border bg-white text-sm dark:text-white"
    >
        <div className="dark:bg-gray-600 bg-gray-100  flex" role="row">
        <div className="w-[25%] py-2 px-4" role="columnheader">
            <span className="text-gray-700 dark:text-gray-100 w-full overflow-hidden text-ellipsis">
                {t('itemdetails.tabs.categorization.category')}
            </span>
        </div>
        <div className="w-[25%] py-2 px-4" role="columnheader">
            <span className="text-gray-700 dark:text-gray-100 w-full overflow-hidden text-ellipsis">
            {t('itemdetails.tabs.categorization.status')}
            </span>
        </div>
        <div className="w-[25%] py-2 px-4" role="columnheader">
            <span className="text-gray-700 dark:text-gray-100 w-full overflow-hidden text-ellipsis">
            {t('itemdetails.tabs.categorization.requested')}
            </span>
        </div>
        <div className="w-[25%] py-2 px-4" role="columnheader">
            <span className="text-gray-700 dark:text-gray-100 w-full overflow-hidden text-ellipsis">
            {t('itemdetails.tabs.categorization.date')}
            </span>
        </div>
       
    </div>
    {
        
        sortedCategorization.map((categorization, index) => {
            const { category, status, requested, dateString, reason} = categorization
						return (
							<div className="flex" role="row" key={index}>
								<div
									className="dark:border-gray-600 border-gray-100 flex w-[25%] items-center border-t py-4 px-4"
									role="cell"
								>
									{
                                        category.to ? <Link className='font-medium text-accent' to={category.to}>{category.text}</Link> :
                                        <span className='font-medium '>{category.text}</span>
                                    }
									 
                                   
								</div>
                                <div
									className="dark:border-gray-600 border-gray-100 flex w-[25%] items-center border-t py-4 px-4"
									role="cell"
								>
									
									 <span className={`font-medium ${status.color}`}>{status.text}</span>
                                     {status.text === "RECHAZADO" && reason.length > 1 ? 
                                        <TextPopOver text={reason} opacity={99}>
                                            <button className='ml-1 text-md text-accent'><FaInfoCircle /> </button>
                                        </TextPopOver>: 
                                     
                                     '' }
                                   
								</div>
                                <div
									className="dark:border-gray-600 border-gray-100 flex w-[25%] items-center border-t py-4 px-4"
									role="cell"
								>
                                    {
                                    requested.to ? <Link className='font-medium text-accent' to={requested.to}>
                                            <ShortAddress address={requested.text} length={8}/>
                                        </Link>: 
                                     <span className='font-medium ' >{requested.text}</span>
                                        
                                    }
									
                                   
								</div>
                                <div
									className="dark:border-gray-600 border-gray-100 flex w-[25%] items-center border-t py-4 px-4"
									role="cell"
								>
									
									 <span className='font-medium '>{dateString}</span>
                                   
								</div>
                            </div>

        )})
       }

    </div>
  )
}

export default CategorizationTab