import React, {useState, useEffect} from 'react'
import DomainsList from '../components/DomainsList/DomainsList'
import { useLazyQuery,useQuery, gql } from "@apollo/client";
import { skipToken } from "@reduxjs/toolkit/query"

import { useCategoriesFromArrayQuery, useWordsInSpanishFromArrayQuery } from '../services/domainsAPI';
import UserProfile from '../components/UserProfile/UserProfile';
import {  useEnsAvatar} from 'wagmi'

function User({address, user}) {
  const [domainsENS, setDomainsENS] = useState([]);
  const [domainsListMap, setDomainsListMap] = useState(new Map())
  const [arrOfWords, setArrOfWords] = useState(skipToken);
  const [date, setDate] = useState("0")
  const [list, setList] = useState([])
  const payload = address?.toLowerCase()
  const [spanishCount, setSpanishCount] = useState(0);
  /*const {  data: avatar, isError, isLoading   } = useEnsAvatar({
    address: "address",
  })
  */
  
  const tokensQuery = gql`
  query Registrations($payload: String!, $date: String!) {
     registrations(first: 1000, orderBy:registrationDate, orderDirection: asc, where: { registrant: $payload registrationDate_gte: $date } ) {
       expiryDate
       registrationDate
       domain{
         id
         name
         createdAt
         labelName
         labelhash
       }
     }
   }
   `


  
  const { data, loading, error } = useQuery(tokensQuery, {
    variables: { date, payload},
  })

   //const [query, { data}] = useLazyQuery(tokensQuery)

    useEffect(() => {

      if (!address || address.length < 1)  {
        return
      }
      if(!data || !data.registrations || loading || error) {
        return
      }
      setDomainsENS(data.registrations)

    }, [data, loading])

  

    useEffect(() => {
      if (list && list.length>0) {
        const nlist = []
        for(let i =0; i < list.length; i++) {
          nlist.push(list[i].label)

        }
       
        setArrOfWords(nlist)
      }
    }, [list])

    

    //const {data: spanishWords, isLoading: loadingLang} = useWordsInSpanishFromArrayQuery(arrOfWords)
    const {data: categories,  isLoading: loadingCategories} = useCategoriesFromArrayQuery(arrOfWords)
    
    useEffect(() => {
      if(loadingCategories || !domainsENS || domainsENS.length < 1) {
        return
      }
     const result = new Map()
      for(let i=0; i < domainsENS.length; i++) {
        const domain = {
          id: domainsENS[i].domain.id,
          label: domainsENS[i].domain.labelName,
          labelHash: domainsENS[i].domain.labelhash,
          name: domainsENS[i].domain.name,
          owner: address,
          ownerAlias: user,
          availabe: false,
          registrationDate: domainsENS[i].registrationDate,
          expiryDate: domainsENS[i].expiryDate,
          tag: "",
          
        }
       
        result.set(domain.id, domain)
      }

      domainsListMap.forEach((value, key) => {
        result.set(key, value)
      })
      setDomainsListMap(result)
      let spCount = 0
      const nlist = [] 
      result.forEach((value) => {
  
        
        if (categories && categories[value.label]) {
          value.categories = categories[value.label]
          value.tag = "español"
          spCount = spCount+1
        }
        nlist.push(value)
      })

      setList(nlist)
      setSpanishCount(spCount)
      
      if(domainsENS.length === 1000) {
        setDate(`${domainsENS[999].registrationDate}`)
    
      }
      


    }, [categories, domainsENS ])
    
    

    if(!domainsListMap) {
      return <div>nada</div>
    }
  return (
    <div> 
      <UserProfile  username={user} domainsCount={list.length} spanishCount={spanishCount} address={address}/>
      <DomainsList domains={list}/>
    </div>
  )
}

export default User