import React, {useState, useEffect} from 'react'
import { useGetCategoriesQuery, usePostCategoriesForLabelMutation } from '../../services/domainsAPI'
import DragDropContainers from '../DragDrop/DragDropContainers'
import { Store } from 'react-notifications-component';
import { FaAngleLeft, FaArrowLeft } from 'react-icons/fa';

const tag = "español"

function CategorizationModal({open, setOpen, domain, domainCategories, loadingCategories}) {
    const {data: categories} = useGetCategoriesQuery()
    const [selectedDomains, setSelectedDomains] = useState([])
    const [selectContainer, setSelectContainer] = useState([])
    const [optionsContainer, setOptionsContainer] = useState([])
    const [postCatgory, result] = usePostCategoriesForLabelMutation()
    const [sendText, setSendText] = useState("Enviar")
    const [firstState, setfirstState] = useState(0)

    
    useEffect(() => {
        if(open) {
            document.body.style.overflowY = "hidden";
        } else {
            document.body.style.overflowY = "scroll";
          }
      
    }, [open])

    useEffect(() => {
        if (!result) {
            return 
        }
        if (result.isSuccess) {
            Store.addNotification({
                title: "Solicitud enviada!",
                message: "Una vez moderada podrás visualizar los cambios",
                type: "success",
                insert: "top",
                container: "top-right",
                dismiss: {
                  duration: 5000,
                  onScreen: true
                }
            });
        }
        
      
    }, [result])

    useEffect(() => {

        if(!categories || loadingCategories ) {
            return
        }
        
        const selected = []
        const options = []
        const categoriesForDomainArray = domainCategories?.Categories
        if (categoriesForDomainArray && categoriesForDomainArray.length> 0) {
            if(!categoriesForDomainArray.includes("español") ) {
                selected.push({
                    id: "español", 
                    content: "español",
                    disable: true,
                })
               
            }  
            categoriesForDomainArray.map((categorie)=> {
                selected.push({
                    id: categorie, 
                    content: categorie,
                    disable: true,
                })
            })
            setfirstState(selected.length)
            setSendText("Continuar sin cambios")
        }   else {
            setfirstState(0)
            setSendText("Reconocer en Español")
            selected.push({
                id: "español", 
                content: "español",
                disable: true,
            })
        }
        
        
        
        
        
        [...categories].sort((a,b) => {return a.localeCompare(b)}).map((categorie) => {
            for (let i =0; i < selected.length; i++) {
                if (selected[i].id === categorie) {
                    return
                }
            }
            options.push({
                id: categorie, 
                content: categorie,
                disable: false,
            })

        })
        setSelectContainer(selected)
        setSelectedDomains(selected)
        setOptionsContainer(options)
        

    }, [categories, domainCategories, loadingCategories])

    function handleSelectedChange(selected) {
        setSelectedDomains(selected)
       
        if (selected.length <= firstState) {
            setSendText("Continuar sin cambios")
        }else if(selected.length > 1 && firstState === 0) {
            setSendText("Reconocer y categorizar")
        } else if (firstState === 0){
            setSendText("Reconocer en Español")
        } else {
            setSendText("Categorizar")
        }
    }


    function handleSend() {
        if(selectedDomains.length < 1 || !domain || selectedDomains.length <= firstState) {
            setOpen(false)
            return
        }
        const cats = []
        const label = domain.substring(0,domain.length-4)
        for (let i=0; i< selectedDomains.length; i++) {
            cats.push(selectedDomains[i].id)
        }
        postCatgory({"labelid": label, "categories": cats})
        setOpen(false)
        
    }

  return (
    <div id="defaultModal" tabindex="-1" className={ open ? " overflow-auto fixed top-0 right-0 left-0 z-50 w-full inset-0 bg-gray-600 bg-opacity-50 dark:bg-gray-900  dark:bg-opacity-10  h-full backdrop-blur-md" : "hidden"}>
    <div className="relative p-4 w-full  h-full md:h-auto">
        
        <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
            
            <div className="flex justify-between items-start p-4 rounded-t border-b dark:border-gray-600">
                <h3 className="text-xl font-semibold text-gray-900 dark:text-white">
                    {`Categoriza ${domain}`}
                </h3>
                <button  className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white" onClick={()=> setOpen(false)}>
                    <svg aria-hidden="true" className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>
                    
                </button>
            </div>
            
            <div className="p-6 space-y-6">
                <div className='flex flex-col  items-center'>
                
                <FaArrowLeft className='text-5xl text-accent'/>
                <p className="text-base leading-relaxed text-gray-700 dark:text-gray-400 text-center mt-2">
                    {`Arrastra y suelta una categoría dentro de ${domain} para categorizar
                    `}
                </p>
                </div>
                
                
                { (optionsContainer && optionsContainer.length>0) ? <DragDropContainers selectContainer={selectContainer} optionsContainer={optionsContainer} setSelected={handleSelectedChange}
                selectionTitle={domain}
                optionsTitle ={"Categorías"}
                >

                </DragDropContainers> : ''} 
            </div>
            
            <div className="flex items-center justify-end p-6 space-x-2 rounded-b border-t border-gray-200 dark:border-gray-600">
                <button className="text-gray-900 bg-white  focus:ring-2 focus:ring-accent focus:outline-none   rounded-lg text-sm px-5 py-2.5 text-center dark:bg-gray-700 dark:text-gray-100 dark:focus:ring-gray-600 " onClick={() => {setOpen(false)}}>Cancelar</button>
                <button className="text-black bg-white hover:bg-gray-100 focus:ring-2  focus:ring-accent  focus:outline-none  rounded-lg border border-gray-200 text-sm font-medium px-5 py-2.5 hover:text-gray-900 focus:z-10 dark:bg-gray-700 dark:text-gray-300 dark:border-gray-500 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-600" onClick={handleSend}>{sendText}</button>
            </div>
        </div>
    </div>
</div>
  )
}

export default CategorizationModal