import React, {useState, useEffect} from 'react'
import CopyToClipboard from 'react-copy-to-clipboard'
import {FaRegCopy, FaCopy} from 'react-icons/fa';
import CopyActionButton from './CopyActionButton';

function CopyButton({text, btnClass}) {
  return (
    <CopyActionButton Icon={FaRegCopy} ActionIcon={FaCopy} text={text} btnClass={btnClass}/>
  )
}

export default CopyButton