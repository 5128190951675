import React, {useState, useEffect} from 'react'
import Tippy from '@tippyjs/react';
import { useTranslation } from 'react-i18next';

function SortDropDown(props) {
	const {t, i18n} = useTranslation('common');
	const {domains, setDomains, loading, refresh} = props
	const [sortActive, setSortActive] = useState(0)
	const itemsSort = [
		{
			id: "no_selection",
			text: t('domainlist.sort.no_selection'),
			sortCondition: function(a, b) {return  1}
		},
		{
			id: "recentlyCreated",
			text: t('domainlist.sort.recentlyCreated'),
			sortCondition: function(a, b) {return  b.registrationDate - a.registrationDate}
		},
		{
			id: "firstCreated",
			text: t('domainlist.sort.firstCreated'),
			sortCondition: function(a, b) {return a.registrationDate - b.registrationDate}
		},
		{
			id: "expirySoon",
			text: t('domainlist.sort.expirySoon'),
			sortCondition: function(a, b) {return  a.expiryDate - b.expiryDate}
		},
		{
			id: "alfabetically",
			text: t('domainlist.sort.alfabetically'),
			sortCondition: function(a, b) {return a.label.localeCompare(b.label)}
		},
		{
			id: "alfabeticallyReverse",
			text: t('domainlist.sort.alfabeticallyReverse'),
			sortCondition: function(a, b) {return -a.label.localeCompare(b.label)}
		},
		{
			id: "lessletters",
			text: t('domainlist.sort.lessletters'),
			sortCondition: function(a, b) {return a.label.length - b.label.length}
		},
		{
			id: "moreletters",
			text: t('domainlist.sort.moreletters'),
			sortCondition: function(a, b) {return  b.label.length - a.label.length}
		},
	]

	function handleChange(index) {
		setSortActive(index);
		if (!itemsSort[index] || !itemsSort[index].sortCondition) {
			return
		}
		const sortedDomains = [...domains].sort(itemsSort[index].sortCondition)
		setDomains(sortedDomains)
		refresh()
		
	}

	
	

	

  return (
    <div className="dropdown relative my-1 cursor-pointer">
        <Tippy
						animation="fade"
						arrow={false}
						trigger="click"
						interactive="true"
						placement="bottom"
						className="tooltip-container"
						content={
							<div
								className="dropdown-menu dark:bg-gray-800 z-10 hidden min-w-[220px] whitespace-nowrap rounded-xl bg-white py-3 px-2 text-left shadow-xl dark:shadow-lg dark:shadow-gray-900 show dark:text-white"
								aria-labelledby="categoriesSort"
							>
								
								{
								itemsSort.map((item, index) => {
								const { id, text } = item;
									return (
									<button
										key={id}
										className="dropdown-item font-display text-gray-700 dark:hover:bg-gray-600 hover:bg-gray-100 flex w-full items-center justify-between rounded-xl px-5 py-2 text-left text-sm transition-colors dark:text-white"
										onClick={() => {
											handleChange(index)

								
										}}
									>
										{text}
										{itemsSort[sortActive].id === id && (
											<svg
												xmlns="http://www.w3.org/2000/svg"
												viewBox="0 0 24 24"
												width="24"
												height="24"
												className="fill-accent mb-[3px] h-4 w-4"
											>
												<path fill="none" d="M0 0h24v24H0z" />
												<path d="M10 15.172l9.192-9.193 1.415 1.414L10 18l-6.364-6.364 1.414-1.414z" />
											</svg>
										)}
									</button>)
								})}
                            </div>
                        }
        >
            <div className="dark:bg-gray-900 dropdown-toggle border-gray-100 dark:border-gray-700 inline-flex w-36 items-center justify-between rounded-lg border bg-white py-2 px-3 text-sm dark:text-white">
							<span className="font-display">{sortActive===0 ? t('domainlist.sort.title') : itemsSort[sortActive].text}</span>
							<svg
								xmlns="http://www.w3.org/2000/svg"
								viewBox="0 0 24 24"
								width="24"
								height="24"
								className="fill-jacarta-500 h-4 w-4 dark:fill-white"
							>
								<path fill="none" d="M0 0h24v24H0z"></path>
								<path d="M12 13.172l4.95-4.95 1.414 1.414L12 16 5.636 9.636 7.05 8.222z"></path>
							</svg>
			</div>
        </Tippy>
    </div>
  )
}

export default SortDropDown