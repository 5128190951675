import React, {useState, useEffect} from 'react'
import { TabPanel } from 'react-tabs';
import {FaWrench, FaExchangeAlt} from 'react-icons/fa';
import ShortAddress from '../Utils/ShortAddress';
import { useTranslation } from 'react-i18next';

function shortAdreess(addr, charCount=8) {
    return `${addr.slice(0,charCount)}...`
}


/*

const activity = [
    {
        event: "Transferencia",
        iconEvent: <FaExchangeAlt/>,
        from: "0x283af0b28c62c092c9727f1ee09c02ca627eb7f5",
        to: "0xa7ae820d9442366930bc79a809feaa132ad8abd8",
        block: 8448763467,
        txID: "0xdb3b683640abeccc459bc0ec90c0589ca03258bcf09ecbe9117b22149c56eadc",
    }, 
    {
        event: "Registro",
        
        iconEvent: <FaWrench/>,
        from: "0x283af0b28c62c092c9727f1ee09c02ca627eb7f5",
        to: "0xa7ae820d9442366930bc79a809feaa132ad8abd8",
        block: 8448763467,
        txID: "0xdb3b683640abeccc459bc0ec90c0589ca03258bcf09ecbe9117b22149c56eadc",
    }
]
*/

function ActivityTab({events, ownerAlias, ownerAddress}) {
    const {t, i18n} = useTranslation('common');
    const [activity, setActivity] = useState([])
    useEffect(() => {
        
        if (!events || events.length < 1 ) {
            return
        }
        let startRegistration =-1;
        for (let i=0; i < events.length; i++) {
            if (Object.hasOwn(events[i], 'newOwner')) {
                if (events[i].newOwner.id === "0x0000000000000000000000000000000000000000") {
                    continue
                }
                 startRegistration = i
                 break
            }
        }
       
        if (startRegistration < 0) {
            return
        }
        
        const activities = []
        const registration = {
            event: t('itemdetails.tabs.activities.event_types.registration'),
            iconEvent: <FaWrench/>,
            from: "ENS Registrar Controller",
            fromFixed: true,
            to: events.length < 2 ? ownerAddress:  events[startRegistration].newOwner.id,
            block: events[startRegistration].blockNumber,
            txID: events[startRegistration].transactionID,
        }
        activities.push(registration)
        if (events.length <2 ) {
            setActivity(activities)
            return
        }
        for(let i= startRegistration + 2; i<events.length; i++) {
            if (Object.hasOwn(events[i], 'newOwner')) {
                const newTransfer = {
                    event: t('itemdetails.tabs.activities.event_types.transfer'),
                    iconEvent: <FaExchangeAlt/>,
                    from: activities[activities.length-1].to,
                    fromFixed: false,
                    to: events[i].newOwner.id,
                    block: events[i].blockNumber,
                    txID: events[i].transactionID,
                }
                activities.push(newTransfer)
            }
        }

        setActivity(activities)
    
    }, [events])
  return (
    <div
    role="table"
    className=" dark:bg-black dark:border-gray-600 border-gray-100 max-h-72 w-full overflow-y-auto overflow-x-hidden rounded-lg rounded-tl-none border bg-white text-sm dark:text-white"
    >
    <div className="dark:bg-gray-600 bg-gray-100  flex" role="row">
        <div className="w-[14%] py-2 px-4" role="columnheader">
            <span className="text-gray-700 dark:text-gray-100 w-full overflow-hidden text-ellipsis">
                {t('itemdetails.tabs.activities.event')}
            </span>
        </div>
        <div className="w-[27%] py-2 px-4" role="columnheader">
            <span className="text-gray-700 dark:text-gray-100 w-full overflow-hidden text-ellipsis">
            {t('itemdetails.tabs.activities.from')}
            </span>
        </div>
        <div className="w-[27%] py-2 px-4" role="columnheader">
            <span className="text-gray-700 dark:text-gray-100 w-full overflow-hidden text-ellipsis">
            {t('itemdetails.tabs.activities.to')}
            </span>
        </div>
        <div className="w-[14%] py-2 px-4" role="columnheader">
            <span className="text-gray-700 dark:text-gray-100 w-full overflow-hidden text-ellipsis">
            {t('itemdetails.tabs.activities.block')}
            </span>
        </div>
        <div className="w-[18%] py-2 px-4" role="columnheader">
            <span className="text-gray-700 dark:text-gray-100 w-full overflow-hidden text-ellipsis">
            {t('itemdetails.tabs.activities.txID')}
            </span>
        </div>
    </div>
    {activity.slice(0).reverse().map((item, index) => {
						const { event,iconEvent, from, fromFixed, to, block, txID } = item;
						return (
							<div className="flex" role="row" key={index}>
								<div
									className="dark:border-gray-600 border-gray-100 flex w-[14%] items-center border-t py-4 px-4"
									role="cell"
								>
									 {iconEvent}
									 <span className='font-medium ml-1'>{event}</span>
                                   
								</div>
								<div
									className="dark:border-gray-600 border-gray-100 flex w-[27%] items-center whitespace-nowrap border-t py-4 px-4"
									role="cell"
								>
									{fromFixed ? <a target="_blank" href={`https://etherscan.io/address/0x283af0b28c62c092c9727f1ee09c02ca627eb7f5`} className="">{from}</a> :
									    <a href={`/user/${from}`} className="text-accent">{from === ownerAddress ? ownerAlias ?  <ShortAddress address={ownerAlias} length={14}/> : <ShortAddress address={ownerAddress} length={18}/> : <ShortAddress address={from} length={18}/>}</a>
                                    }
								</div>
								<div
									className="dark:border-gray-600 border-gray-100 flex w-[27%] items-center border-t py-4 px-4"
									role="cell"
								>
									
										<a href={`/user/${to}`} className="text-accent">{to === ownerAddress ? ownerAlias ?  <ShortAddress address={ownerAlias} length={14}/> : <ShortAddress address={ownerAddress} length={18}/> : <ShortAddress address={to} length={18}/>}</a>
									
								</div>
								<div
									className="dark:border-gray-600 border-gray-100 flex w-[14%] items-center border-t py-4 px-4"
									role="cell"
								>
									
										<a 
                                        target="_blank"
                                        href={`https://etherscan.io/block/${block}`}
                                        className="">{block}</a>
									
								</div>
								<div
									className="dark:border-gray-600 border-gray-100 flex w-[18%] items-center border-t py-4 px-4"
									role="cell"
								>
									
										<a
											className=" flex flex-wrap items-center hover:text-accent"
											target="_blank"
											href={`https://etherscan.io/tx/${txID}`}
										>
											<span className="mr-1">{shortAdreess(txID,18)}</span>
											<svg
												xmlns="http://www.w3.org/2000/svg"
												viewBox="0 0 24 24"
												width="24"
												height="24"
												className="h-4 w-4 fill-current "
											>
												<path fill="none" d="M0 0h24v24H0z" />
												<path d="M10 6v2H5v11h11v-5h2v6a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V7a1 1 0 0 1 1-1h6zm11-3v8h-2V6.413l-7.793 7.794-1.414-1.414L17.585 5H13V3h8z" />
											</svg>
										</a>
									
								</div>
							</div>
						);
					})}
    </div>
  )
}

export default ActivityTab