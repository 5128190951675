import React, {useState, useEffect} from 'react'
import { useCreatePendingCategorizationBatchMutation, useGetCategoriesQuery, usePostCategoriesForLabelMutation } from '../../services/domainsAPI'
import DragDropContainers from '../DragDrop/DragDropContainers'
import { Store } from 'react-notifications-component';
import { FaAngleLeft, FaArrowLeft } from 'react-icons/fa';
import CategoryMultipleSelection from '../CategoriesDropDown/CategoryMultipleSlection';
import SignButton from '../SignButton/SignButton';

import { useTranslation } from 'react-i18next';

const REQUIRED = "español"

function groupArray(group) {
    return group?.map(gr => {
        return gr.Category
    })
}



function CategorizationModalTagView({open, setOpen, domain, categories}) {
    const {t, i18n} = useTranslation('common');
    const name = domain?.substring(0,domain.length-4)
    const [postCatgory, result] = useCreatePendingCategorizationBatchMutation()
    

    const [newSelections, setNewSelections] = useState([])

    function buildBody() {
        return {domains:[name], categories: newSelections}
    }
    const selected = groupArray(categories.approved)
    const pending =  groupArray(categories.pending)
    const rejected =  groupArray(categories.rejected)
    
    
    useEffect(() => {
        if(open) {
            document.body.style.overflowY = "hidden";
        } else {
            document.body.style.overflowY = "scroll";
          }
      
    }, [open])

    useEffect(() => {
        
        if (!result) {
            return 
        }
        if (result && result.data && result.data.length >0 || result.isError) {
            if (!result.data[0].Success || result.isError) {
               
                Store.addNotification({
                    title: t('itemdetails.categorization.notification.failed.title'),
                    message: t('itemdetails.categorization.notification.failed.body'),
                    type: "danger",
                    insert: "top",
                    container: "top-right",
                    dismiss: {
                      duration: 7000,
                      onScreen: true
                    }
                });
                return
            }
        }
        if (result.isSuccess) {
            Store.addNotification({
                title: t('itemdetails.categorization.notification.sucess.title'),
                    message: t('itemdetails.categorization.notification.sucess.body'),
                type: "success",
                insert: "top",
                container: "top-right",
                dismiss: {
                  duration: 5000,
                  onScreen: true
                }
            });
            return
        }
        
      
    }, [result])





    function submitWithSignature(signature) {
        postCatgory({address:signature.address,signature:signature.signature, body: buildBody()})
        setOpen(false)
    }

    function submitAnonimous() {
        postCatgory({address:'',signature:'', body: buildBody()})
        setOpen(false)
    }


  return (
    <div id="defaultModal" tabindex="-1" className={ open ? " overflow-auto fixed top-0 right-0 left-0 z-50 w-full inset-0 bg-gray-600 bg-opacity-50 dark:bg-gray-900  dark:bg-opacity-10  h-full backdrop-blur-md" : "hidden"}>
    <div className="relative p-4 max-w-2xl m-auto w-full h-full md:h-auto">
        
        <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
            
            <div className="flex justify-between items-start p-4 rounded-t border-b dark:border-gray-600">
                <h3 className="text-xl font-semibold text-gray-900 dark:text-white">
                    {`${t('itemdetails.categorization.title')} ${domain}`}
                </h3>
                <button  className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white" onClick={()=> setOpen(false)}>
                    <svg aria-hidden="true" className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>
                    
                </button>
            </div>
            <h3 className="text-lg font-medium text-gray-900 px-5 pt-3 dark:text-white">
            {t('itemdetails.categorization.subtitle')}
            </h3>
            <div className="p-6 ">
                <CategoryMultipleSelection selected={selected} pending={pending} rejected={rejected} setNewSelections={setNewSelections} placeholder={t('itemdetails.categorization.placeholder')}/>
                {newSelections && newSelections.length < 1 ? <h3 className="text-sm font-light text-gray-900 dark:text-white mt-5 ml-1 ">
                    {t('itemdetails.categorization.toDiscord')} <a href='www.google.com' className='text-accent underline text-md'>Discord.</a>
                </h3>
                : ''}
            </div>
            
            { newSelections && newSelections.length>0 ? <div className='flex flex-col px-5 mb-4 mt-3'>
            <h3 className="text-md font-normal text-gray-900 dark:text-white text-center">
                {t('itemdetails.categorization.signature.title')}
            </h3>
            <p className="text-xs font-light text-gray-900  dark:text-white text-center">
            {t('itemdetails.categorization.signature.subtitle')}
            </p>

            <SignButton classname='bg-accent text-center my-3   shadow-xl dark:shadow-gray-900-dark rounded md hover:bg-accent-dark py-3 mx-20 font-bold text-white transition-all' 
            title={t('itemdetails.categorization.signature.button')}fields={buildBody()} setSignature={submitWithSignature} disabled={!newSelections || newSelections.length === 0}/>
            
           
            </div> : ''
            }
            
            <div className="flex items-center justify-end p-3 space-x-2 rounded-b border-t border-gray-200 dark:border-gray-600">
                <button className="text-gray-900 bg-white  focus:ring-2 focus:ring-accent focus:outline-none   rounded-lg text-sm px-5 py-2.5 text-center dark:bg-gray-700 dark:text-gray-100 dark:focus:ring-gray-600 " onClick={() => {setOpen(false)}}>{t('itemdetails.categorization.cancel')}</button>
                {newSelections && newSelections.length>0 ?  <button onClick={() => submitAnonimous()} className="text-black bg-white hover:bg-gray-100 focus:ring-2  focus:ring-accent  focus:outline-none  rounded-lg border border-gray-200 text-sm font-medium px-5 py-2.5 hover:text-gray-900 focus:z-10 dark:bg-gray-700 dark:text-gray-300 dark:border-gray-500 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-600" >{t('itemdetails.categorization.send_anonimous')}</button> : ''}
            </div>
        </div>
    </div>
</div>
  )
}

export default CategorizationModalTagView