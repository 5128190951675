import useDarkMode from '../../services/darkmode';

import React, { useEffect } from 'react'
import {
    FaMoon,
    FaSun,
  } from 'react-icons/fa';


function ThemeSwitcher() {
    const [darkTheme, setDarkTheme] = useDarkMode();
    useEffect(() => {
     if (typeof darkTheme === 'undefined') {
        setDarkTheme(!darkTheme)
     }
      
    }, [])
    const handleMode = () => setDarkTheme(!darkTheme);
    return (
      <span onClick={handleMode} className={"  pr-1"}>
        {darkTheme ? (
          <FaSun className='top-navigation-icon text-white '  size={22}/>
        ) : (
          <FaMoon className='top-navigation-icon' size={20} />
        )}
      </span>
    );
}

export default ThemeSwitcher