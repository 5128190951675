import React, { useState } from 'react'
import { useParams } from 'react-router-dom'
import CategoryCard from '../../components/CategoryIcon/CategoryCard'
import LoadingSpinner from '../../components/Utils/LoadingSpinner'
import { useGetCategoryQuery } from '../../services/domainsAPI'
import FilterResultBuilder from '../Filters/FilterResultBuilder'

import { useTranslation } from 'react-i18next';



function CategoryPage() {
  const {t, i18n} = useTranslation('common');
    const [stats, setStats] = useState({})
    const {categoryID} = useParams()
    
    const {data, isLoading, isError,isFetching} = useGetCategoryQuery({"categoryID":categoryID })
    if(isLoading) {
      return <div className='mt-5 flex justify-center items-center flex-col'>
       <LoadingSpinner />
      <div className='mt-2 dark:text-white'>{t('category.loading')}</div>
      </div>
    }

    function statsHandler(item) {
      setStats(item)
    }
    
    if(!data || data.length < 1) {
      return <div className='mt-5 dark:text-white'>{t('category.notfound')}</div>
    }
    
  return (
      <div >
        <CategoryCard domainsCount={data.length} categoryName={categoryID} availables={stats.available} uniqueOwners={stats.uniqueOwners}/>
        <FilterResultBuilder domains={data} from="local" setStats={statsHandler} showSpanishCategoryFilter={false} hideRedundantCategory={categoryID}/>
      </div>
      
  )

}

export default CategoryPage