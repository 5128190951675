import React from 'react'
import { FaCheckCircle } from 'react-icons/fa'
import { TwitterIcon, TwitterShareButton } from 'react-share'
import { useContractRead } from 'wagmi'
import CopyButton from '../CopyButton/CopyButton'
import TextPopOver from '../TextPopOver/TextPopOver'
import SeeInEtherScan from '../Utils/SeeInEtherScan'
import { useTranslation } from 'react-i18next';

function shortAdreess(addr, charCount=8) {
    return `${addr.slice(0,charCount)}...`
}

const abi = [
    {"inputs":[{"internalType":"address","name":"account","type":"address"},{"internalType":"uint256","name":"id","type":"uint256"}],"name":"balanceOf","outputs":[{"internalType":"uint256","name":"","type":"uint256"}],"stateMutability":"view","type":"function"},
  ]

function UserProfile({ username, address, domainsCount, spanishCount}) {
    const {t, i18n} = useTranslation('common');

    const { data: balance, isSuccess } = useContractRead({
        address: '0x2De45e2c679a2Fd7F1E524135cCfa5b8baEbD137',
        abi: abi,
        functionName: 'balanceOf',
        args: [address, "0"]
      })
      
    const verify = isSuccess ? balance?.toNumber() > 0 : false
    

const imageURL = `https://metadata.ens.domains/mainnet/avatar/${username}`
const details = [
    {
        id: "domainsCount",
        detailsNumber: domainsCount,
        detailsText: "Dominios",
    },
    {
        id: "spanishCount",
        detailsNumber: spanishCount,
        detailsText: "En Español",
    },
]
  return (
    <section className="  flex justify-center md:justify-between mt-10 bg-gray-100 md:rounded-3xl rounded-2xl mb-10 dark:bg-gray-800  py-4 md:py-7 px-3 md:mx-11 flex-wrap shadow-lg dark:shadow-gray-900">
        <div className="  flex justify-center md:justify-start gap-5 flex-wrap ">
            <div className=" items-center">
                <figure className=" h-40 w-40 dark:border-gray-600 rounded-xl shadow-xl  border-white  md:ml-7">
                    <img
                        src={imageURL}	
                        loading="lazy"		
                        className="dark:border-gray-600 rounded-xl "
                        onError={({ currentTarget }) => {
                            currentTarget.onerror = null; // prevents looping
                            currentTarget.src="/images/logo_perfil_no_avatar.png";
                        }}
                    />
                </figure>
                                        
            </div>
            <div className=" flex  flex-col justify-between ">
                        <div className='flex text-accent text-xl items-center gap-1'>
                            <h3 className=" text-black  text-3xl font-medium dark:text-white md:mt-1 ">
                            {  username?.length<20 ? username: shortAdreess(address)}
                            </h3>
                            {verify ? <TextPopOver opacity={94} text={ t('profile.OG')}><button className='mt-1 sm:mt-2 ml-1'><img className='max-w-[26px] animate-bounce rounded-2xl' src={"/images/og.png"}/></button></TextPopOver> : ''}
                        </div>

                    <div className='mb-2 dark:text-white mt-2 text-lg'>
                        <div>{`${t('profile.domains')}: ${domainsCount ? domainsCount: 0 }`}</div>
                        <div>{`${t('profile.spanish')}: ${spanishCount ? spanishCount: 0 }`}</div>
                    </div>
                    
                
            </div>
            
        </div>
        <div className='flex justify-start items-center md:mr-10  gap-3 mt-4'>
            <SeeInEtherScan route={`address/${address}`}/>

            <TextPopOver text={t('profile.twitter')}>
            <TwitterShareButton
                title={`Échale un vistazo a ${username} en @ENSesp`}
                url={`https://ensesp.io/user/${username}`}
                hashtags={["ENS", "ENSESP"]}
            >
                
                <TwitterIcon size={32} round />
            </TwitterShareButton>
            </TextPopOver>
            <CopyButton text={`https://ensesp.io/user/${username}`} btnClass="h-[28px] w-[28px]  cursor-pointer mb-[1px]"/>                   
        </div>
                        
        
    </section>
  )
}

export default UserProfile