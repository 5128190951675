import React from 'react'
import { useLocation, useParams } from 'react-router-dom'
import LoadingSpinner from '../../components/Utils/LoadingSpinner'
import NotFound from '../../components/Utils/NotFound'
import { useGetDomainsStatsDetailedQuery } from '../../services/domainsAPI'
import FilterResultBuilder from '../Filters/FilterResultBuilder'
import { useTranslation } from 'react-i18next';

function GenericStatsDetailedPage() {
	const {t, i18n} = useTranslation('common');
    const {state} = useLocation()
	
    const {data, isLoading, isError} = useGetDomainsStatsDetailedQuery({queryURL:state.query})
    if(isLoading) {
		return <div className='mt-5 flex justify-center items-center flex-col'>
		 <LoadingSpinner />
		<div className='mt-2 dark:text-white'>{t('stats.loading')}</div>
		</div>
	 }
   
	 if(!data || !data.involved || isError) {
		return <NotFound text={t('stats.error')}/>
	  }
      const domains = data.involved?.map(domain => {
		if (domain.endsWith(".eth")) {
			return domain.slice(0,domain.length - 4)
		}
		return domain
	  })
  return (
    <FilterResultBuilder domains={domains} from="local" ></FilterResultBuilder>
  )
}

export default GenericStatsDetailedPage