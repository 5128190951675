import React from 'react'
import ItemDetail from '../../components/ItemDetail/ItemDetail'
import ItemDetailNoRegistered from '../../components/ItemDetail/ItemDetailNoRegistered'

function ItemDetailPage({domainName, registration, ownerAlias, categories, tag, registered, loadingCategories}) {
 if (!registered) {
    return <ItemDetailNoRegistered name={domainName} categories={categories}/>
 }

  
  return (
    <ItemDetail loadingCategories={loadingCategories} ID={registration?.domain?.id} labelHash={registration?.domain?.labelhash}  expirationDate={registration?.expiryDate} registrationDate={registration?.registrationDate} name={domainName} owner={ownerAlias} categories={categories} ownerAddress={registration?.registrant.id} controllerAddress={registration?.domain?.owner?.id} events={registration?.events}/>
  )
}

export default ItemDetailPage