import React, {useState} from 'react'
import {
    FaEllipsisH,
    FaShareAlt,
    FaRegNewspaper,
    FaShare,
  } from 'react-icons/fa';
  import { useTranslation } from 'react-i18next';

  import { Link } from 'react-router-dom'
import CopyButton from '../CopyButton/CopyButton';
import ShareCopyButton from '../CopyButton/ShareCopyButton';

function ActionsDropDown({domain, owner, categories}) {
    const [dropdownShow, setDropdownShow] = useState(false);
	const {t, i18n} = useTranslation('common');
   
    

	const handleDropdown = () => {
        if (!dropdownShow) {
            setDropdownShow(true);
            return
        } 
        
        setDropdownShow(false)
        

	};
	return (
		<>
			<div className="">
				<button
					onClick={handleDropdown}
					className="inline-flex h-10 w-10 items-center justify-center text-sm show"
				>
					<FaEllipsisH className='dark:text-white'/>
				</button>

				<div
					className={
						dropdownShow
							? 'dropdown-menu-end dark:bg-gray-900 z-10 min-w-[200px] whitespace-nowrap rounded-xl bg-white py-4 px-2 text-left shadow-xl show text-gray-600 absolute top-full right-0'
							: 'dropdown-menu-end dark:bg-gray-900 z-10 min-w-[200px] whitespace-nowrap rounded-xl bg-white py-4 px-2 text-left shadow-xl hidden  text-gray-600  absolute top-full right-0'
					}
				>
					<Link to={`/domain/${domain}`} state={{ ownerAlias:owner , categories:categories}}>
					<div className="dark:hover:bg-gray-600  hover:bg-gray-100 w-full  rounded-xl px-5 py-2 text-left text-sm transition-colors dark:text-white">
					{t('domainlist.card.details')}
                        <FaRegNewspaper className='inline ml-1'/>
					</div>
					</Link>
					<button className="dark:hover:bg-gray-600   hover:bg-gray-100 block w-full rounded-xl px-5 py-2 text-left text-sm transition-colors dark:text-white">
						<span className='mr-1 float-left'>{t('domainlist.card.copy')}</span>
                        <CopyButton text={`https://ensesp.io/domain/${domain}`}/>
					</button>
					<a className="dark:hover:bg-gray-600  hover:bg-gray-100 block w-full rounded-xl px-5 py-2 text-left text-sm transition-colors dark:text-white" target="_blank" href={`https://app.ens.domains/name/${domain}/details`}>
						{t('domainlist.card.ensdomains')}
                        <FaShare className='inline ml-1'/>
					</a>
				</div>
			</div>
		</>
	);
}

export default ActionsDropDown