import React from 'react'
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';


function Functionalities() {
  const {t, i18n} = useTranslation('common');
    const services = [
        {
        id: "categories",
        title: t('landing.functionalities.functionality1.title'),
        icon: "/images/asset1.png",
        linktitle: t('landing.functionalities.functionality1.button'),
        link: "/categories",
        description: t('landing.functionalities.functionality1.description'),
        externaly: false,
        
        },

        {
        id: "library",
        title: t('landing.functionalities.functionality2.title'),
        icon: "/images/asset2.png",
        linktitle: t('landing.functionalities.functionality2.button'),
        link: "/filter",
        description: t('landing.functionalities.functionality2.description'),
        externaly: false,

        },

        {
        id: "search-engine",
        title: t('landing.functionalities.functionality3.title'),
        icon: "/images/asset3.png",
        linktitle:  t('landing.functionalities.functionality3.button'),
        link: "/filter-global",
        description: t('landing.functionalities.functionality3.description'),
        externaly: false,
        },

        {
        id: "ogpass",
        title: t('landing.functionalities.functionality4.title'),
        icon: "/images/asset4.png",
        linktitle: t('landing.functionalities.functionality4.button'),
        link: "https://opensea.io/es/assets/ethereum/0x2de45e2c679a2fd7f1e524135ccfa5b8baebd137/0",
        description: t('landing.functionalities.functionality4.description'),
        externaly: true,
        },
        {
          id: "spanish_detection",
          title: t('landing.functionalities.functionality5.title'),
          icon: "/images/asset6.png",
          linktitle: t('landing.functionalities.functionality5.button'),
          link: "/profile",
          description: t('landing.functionalities.functionality5.description'),
          externaly: false,
        },
        {
          id: "Búsqueda Masiva | Bulk Search",
          title: t('landing.functionalities.functionality6.title'),
          icon: "/images/asset5.png",
          linktitle: t('landing.functionalities.functionality6.button'),
          link: "/bulk-search",
          description: t('landing.functionalities.functionality6.description'),
          externaly: false,
        },
]
  return (
    <div>
			
			<section className="py-24 md:mt-[180px] mt-[110px] dark:bg-black">
				<div className="container">
					<div className="mx-auto mb-12 max-w-xl text-center">
						<h2 className="mb-6 text-center font-display text-4xl font-medium text-gray-700 dark:text-white">
							{t('landing.functionalities.title')}
						</h2>
						
					</div>
					<div className="grid grid-cols-1 gap-10 md:grid-cols-2 lg:grid-cols-2">
						{services.map((item) => {
							const { id, icon, title, description, link, linktitle, externaly } = item;
							return (
								<div
									key={id}
									className=" relative flex flex-col items-center rounded-3xl border border-gray-100 bg-gray-200 p-10 pt-5 transition-shadow dark:transition-shadow hover:shadow-xl dark:border-gray-700 dark:bg-gray-800 dark:hover:bg-gray-900 dark:hover:shadow-xl dark:hover:shadow-gray-900"
								>
									<img src={icon} className="max-h-[150px] pr-4"/>
									<h3 className="mb-4 font-display text-xl mt-5 text-gray-700 dark:text-white">
										{title}
									</h3>
									<p className="dark:text-jacarta-300">{description}</p>
                  {
                    externaly ? 
                    <a href={link} target="_blank" className="mt-7">
                                      <div className='bg-accent  shadow-xl dark:shadow-gray-900-dark rounded-full hover:bg-accent-dark py-3 px-8 text-center font-bold text-white transition-all'>{linktitle}</div>
                        </a> :
                  
                       <Link to={link} className="mt-7">
                                      <div className='bg-accent  shadow-xl dark:shadow-gray-900-dark rounded-full hover:bg-accent-dark py-3 px-8 text-center font-bold text-white transition-all'>{linktitle}</div>
                        </Link>
                }
								</div>
							);
						})}
					</div>
				</div>
			</section>
			{/* <!-- end services --> */}
		</div>
  )
}

export default Functionalities