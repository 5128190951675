import React, { useState } from 'react'
import { FaSearch } from 'react-icons/fa'
import CatagoriesDropDownWithSearch from '../CategoriesDropDown/CtagoriesDropDownWithSearch'
import GenericDropDown from './GenericDropDown'
import { useTranslation } from 'react-i18next';

const inputClass = "dark:bg-gray-700 border-gray-400 border  focus:ring-gray-600 dark:border-gray-600 dark:placeholder:text-gray-300 w-full  rounded-lg py-3 px-3  dark:text-white"

const dropDownClass = "dark:text-white bg-white border-gray-400 border dark:border-gray-600 rounded-lg w-full py-3 px-3  flex items-center  justify-between dark:bg-gray-700 "

const categoryDropDownClass = "dark:text-white bg-white border-gray-400 border dark:border-gray-600 rounded-lg w-full py-3 px-3  flex items-center  justify-between dark:bg-gray-700 "


function GenericForm({formsItems, title, onSubmit}) {
  const {t, i18n} = useTranslation('common');
  const [dropDownState, setDropDownState] = useState({})

  function updateDrownDownState(key, state) {
    const nObjet = dropDownState
    nObjet[key] = state
    setDropDownState(nObjet)
 
  }

  function handleSubmit(event) {
    event.preventDefault()
    const response = {}
    formsItems.map((item,index) => {
      if (event.target[index] && event.target[index].value.length > 0) {
        response[item.key] = event.target[index].value.toLowerCase();
      }
     
      if(dropDownState[item.key] !== undefined ) {
        response[item.key] = dropDownState[item.key]
      }
      
    })
    
    
    onSubmit(response)
  }
    
  return (
    <form
    onSubmit={handleSubmit}
    >
      <div className='flex flex-wrap gap-y-5 justify-between'>
        {
         formsItems.map(item => {
                return <div>
                    <div key={item.key} className={item.size == "large" ? " sm:min-w-[37rem]  md:min-w-[44rem]  lg:min-w-[48rem]" : item.size == "medium" ? "min-w-[13rem] max-w-[16rem] lg:min-w-[17rem] lg:max-w-[20rem]" : "min-w-[4rem] max-w-[7rem] lg:min-w-[8rem] lg:max-w-[12rem]" }>
                <label
                htmlFor={`item-${item.key}`}
                className=" text-gray-700 mb-2  dark:text-white"
                >
                {
                item.label   
                }
              </label>
              {item.inputType === "input" ? 
                <input className={inputClass} placeholder={item.options.placeholder} /> :
                item.inputType === "number" ? <input type="number" className={inputClass} placeholder={item.options.placeholder} min={item.options.min}/> :
              item.inputType === "dropdown" ? 
              <GenericDropDown buttonClassName={dropDownClass} items={item.options.items} setValue={(state) => updateDrownDownState(item.key, state)}> </GenericDropDown> : 
              item.inputType === "category-dropdown" ? 
              <CatagoriesDropDownWithSearch  setCategorie={(state) => updateDrownDownState(item.key, state)} buttonClassName={categoryDropDownClass}/> : ''
              }
            </div>
                </div>
               
           })
        }
      </div>
      <div className="mb-6 mt-16 flex justify-end gap-5">
        <button type="reset" className="text-gray-900 bg-white  focus:ring-2 focus:ring-accent focus:outline-none   rounded-lg text-sm px-5 py-2.5 text-center dark:bg-gray-700 dark:text-gray-100 dark:focus:ring-gray-600 "> {t('filters.shared.clear')}</button>
        <button type="submit" className='dark:border-gray-600   bg-accent group focus:ring-2 focus:ring-accent-light text-white border-jacarta-100 py-2.5   flex  items-center rounded-lg border  px-5 font-semibold transition-colors hover:border-transparent hover:text-white dark:text-white dark:hover:border-transparent dark:hover:text-white capitalize'>
          {t('filters.shared.search')}
          <FaSearch className='ml-2 mt-0.5 text-sm'/>
        </button>
        </div>
    </form>
  )
}

export default GenericForm