import React from 'react'
import Tippy from '@tippyjs/react';
import SkeletonIMG from '../SkeletonIMG/SkeletonIMG';
import { Link } from 'react-router-dom'
import TextPopOver from '../TextPopOver/TextPopOver';

function CanvasView(props) {
    const items = props.items
   
  return (
    <div className='flex justify-center flex-wrap'>
        {items.map((item) => {
            const {
                id,
                name,
                label,
                owner,
                ownerAlias,
                available,
                tag,
                categories,
                registrationDate,
                expiryDate,
                labelHash,
            } = item
            const imagesrc = available ? "/images/ensavailableh.png" : `https://metadata.ens.domains/mainnet/0x57f1887a8bf19b14fc0df6fd9b2acc9af147ea85/${labelHash}/image`
            const formatRegistrationDate = new Intl.DateTimeFormat('es-ES', { year: 'numeric', month: '2-digit', day: '2-digit'}).format(registrationDate*1000)
            const formatExpirynDate = new Intl.DateTimeFormat('es-ES', { year: 'numeric', month: '2-digit', day: '2-digit'}).format(expiryDate*1000)
        return (<a>
             
            <figure className="self-start relative">
            {tag && tag.length > 3 && <div className='bg-jacarta-700 absolute top-2 md:top-4 right-0 flex items-center space-x-1 rounded-md p-1 md:p-2'>
                        <Tippy >
                            <p className='text-white text-xs md:text-sm capitalize'>{tag}</p>
                        </Tippy>
                    </div>}
                <TextPopOver text={name}>
                <Link to={`/domain/${name}`} state={{ ownerAlias:ownerAlias  }}>
                 <SkeletonIMG imgClass="rounded-lg shadow-lg transition-shadow hover:shadow-2xl h-[100px] w-[100px] md:h-[200px] md:w-[200px] dark:shadow-gray-800" src={imagesrc} skClass="h-[100px] w-[100px] md:h-[200px] md:w-[200px] rounded-lg "/>
                </Link>
                </TextPopOver>
            </figure>

        
        </a>)
        })}
    </div>
  )
}

export default CanvasView