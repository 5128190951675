import React from 'react'
import { useTranslation } from 'react-i18next';

const Pagination = (props) => {
    const {t, i18n} = useTranslation('common');
    const { nPages, currentPage, setCurrentPage } = props
    const pageNumbers = [...Array(nPages + 1).keys()].slice(1)

    

    const nextPage = () => {
            if(currentPage !== nPages) setCurrentPage(currentPage + 1)
    }
    const prevPage = () => {
        if(currentPage !== 1) setCurrentPage(currentPage - 1)
    }
    return (
       
        
            <nav >
            <ul className="inline-flex  -space-x-px flex-wrap">
            <li>
                <button onClick={prevPage}   className="py-1.5 px-3 ml-0 border-gray-300 border  text-gray-500 bg-white rounded-l-lg  hover:shadow-md hover:bg-gray-100 hover:text-gray-700  dark:bg-gray-900 dark:border-gray-700 dark:text-gray-100 dark:hover:bg-gray-800 dark:shadow-gray-900 dark:hover:text-white min-w-[90px]">{t('domainlist.pagination.previous')}</button>
            </li>
            {
                <div className="py-1.5 px-4 border-gray-300 border text-gray-500  dark:bg-gray-900 dark:border-gray-700 dark:text-gray-100 ">{`${t('domainlist.pagination.page')} ${currentPage}/${pageNumbers.length}`}</div>
            }
            <li>
             <button onClick={nextPage} className="py-1.5 px-3  text-gray-500 dark:border-gray-700 bg-white rounded-r-lg border hover:shadow-md border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-900  dark:text-gray-100 dark:hover:bg-gray-800 dark:shadow-gray-900 dark:hover:text-white min-w-[90px]">{t('domainlist.pagination.next')}</button>
            </li>
            </ul>
            </nav>
        
    )
}

export default Pagination

