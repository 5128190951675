import React from 'react'

function getText(categoryN) {
    var splitStr = categoryN.toLowerCase().split(' ');
    if (splitStr.length < 2) {
        return `${categoryN.slice(0,7)}.`
    }
    var response = ""
    for (var i = 0; i < splitStr.length; i++) {
        response += (splitStr[i].charAt(0).toUpperCase())
    }
    return response
 
}

function CategoryIcon({categoryName, classN, small=true, textSize, short}) {
    const text =  short ? short: small && categoryName.length > 8  ? getText(categoryName) : categoryName
  return (
    <figure className={classN}>
        <div className='w-full h-full bg-accent rounded-xl border-4 border-gray-600 dark:border-gray-800 flex justify-center items-center  text-white py-4 px-3'>
            <p className={`font-bold  text-center ${textSize}`}>{text}</p>
        </div>
    </figure>
  )
}

export default CategoryIcon