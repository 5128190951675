import React from 'react'
import { FaEye } from 'react-icons/fa'
import { Link } from 'react-router-dom'

function StatsTiles({stats}) {
  return (

    <div className="my-5 w-full flex gap-4 flex-wrap justify-center shadow-lg ">
		{stats.map(stat => {
			return <div className="w-full flex flex-col items-center rounded-2lg bg-gray-100 p-4 text-center dark:bg-gray-900 sm:w-[32%] ">
				<span className="block font-display text-3xl text-transparent  bg-clip-text bg-gradient-to-r from-[#fca5a5] via-accent to-[#a855f7]">{stat.value || '-'}</span>
				<span className="block font-display  text-gray-600 dark:text-white mt-1">
				{stat.label}
				</span>
				{stat.to ? <Link to={stat.to ? stat.to : ''} className='text-accent text-2xl mt-1' state={stat.state}><FaEye></FaEye> </Link>: ''}
			</div>
		})}
								
							
	</div>
  )
}

export default StatsTiles