import React, {useState, useEffect} from 'react'
import Tippy from '@tippyjs/react';
import { toHaveAttribute } from '@testing-library/jest-dom/dist/matchers';
import { useTranslation } from 'react-i18next';

function AvailableDropDown(props) {
	const {t, i18n} = useTranslation('common');
	const {newCondition} = props
	const [available, setAvailable] = useState(0)
	const availables = [
		{
			id: "all",
			text: t('domainlist.available.all'),
			filterCondition: function(a) {return true}
		},
		{
			id: "no_available",
			text: t('domainlist.available.registered'),
			filterCondition: function(a) {return !a.available}
		},
		{
			id: "available",
			text: t('domainlist.available.noregistered'),
			filterCondition: function(a) {return a.available}
		},
		
		
	]


	

	function handleChange(index) {
		setAvailable(index);
		if (!availables[index] || !availables[index].filterCondition) {
			
			return
		}
		const condition = {
			name: "available",
			hasCondition: availables[index].filterCondition,
		}
		newCondition(condition, true)
		
	}

	

  return (
    <div className="dropdown relative my-1 cursor-pointer">
        <Tippy
						animation="fade"
						arrow={false}
						trigger="click"
						interactive="true"
						placement="bottom"
						className="tooltip-container"
						content={
							<div
								className="dropdown-menu dark:bg-gray-800 z-10 hidden min-w-[220px] whitespace-nowrap rounded-xl bg-white py-4 px-2 text-left shadow-xl dark:shadow-lg dark:shadow-gray-900 show dark:text-white"
								aria-labelledby="categoriesSort"
							>
								<span className="dark:text-white text-black block px-5 py-2 text-sm">
									{t('domainlist.available.title')}
								</span>
								{
								availables.map((item, index) => {
								const { id, text } = item;
									return (
									<button
										key={id}
										className="dropdown-item font-display text-gray-700 dark:hover:bg-gray-600 hover:bg-gray-100 flex w-full items-center justify-between rounded-xl px-5 py-2 text-left text-sm transition-colors dark:text-white"
										onClick={() => {
											handleChange(index)
										}}
									>
										{text}
										{availables[available].id === id && (
											<svg
												xmlns="http://www.w3.org/2000/svg"
												viewBox="0 0 24 24"
												width="24"
												height="24"
												className="fill-accent mb-[3px] h-4 w-4"
											>
												<path fill="none" d="M0 0h24v24H0z" />
												<path d="M10 15.172l9.192-9.193 1.415 1.414L10 18l-6.364-6.364 1.414-1.414z" />
											</svg>
										)}
									</button>)
								})}
                            </div>
                        }
        >
            <div className="dark:bg-gray-900 dropdown-toggle border-gray-100 dark:border-gray-700 inline-flex w-36 items-center justify-between rounded-lg border bg-white py-2 px-3 text-sm dark:text-white">
							<span className="font-display">{availables[available].text}</span>
							<svg
								xmlns="http://www.w3.org/2000/svg"
								viewBox="0 0 24 24"
								width="24"
								height="24"
								className="fill-gray-600 h-4 w-4 dark:fill-white"
							>
								<path fill="none" d="M0 0h24v24H0z"></path>
								<path d="M12 13.172l4.95-4.95 1.414 1.414L12 16 5.636 9.636 7.05 8.222z"></path>
							</svg>
			</div>
        </Tippy>
    </div>
  )
}

export default AvailableDropDown