import '@rainbow-me/rainbowkit/styles.css';
import {
  darkTheme,
  getDefaultWallets,
  lightTheme,
  midnightTheme,
  RainbowKitProvider,
} from '@rainbow-me/rainbowkit';
import {
  chain,
  configureChains,
  createClient,
  WagmiConfig,
} from 'wagmi';
import { alchemyProvider } from 'wagmi/providers/alchemy';
import { publicProvider } from 'wagmi/providers/public';
import WalletButton from './components/WalletButton/WalletButton';
import NavBar from './components/NavBar/NavBar';
import Layout from './components/Layout/Layout';
import { ApolloProvider, ApolloClient, InMemoryCache } from "@apollo/client";
import { ReactNotifications } from 'react-notifications-component'
import 'react-notifications-component/dist/theme.css'
import useDarkMode from './services/darkmode';
import { useEffect } from 'react';
import { Analytics } from '@vercel/analytics/react';




const { chains, provider } = configureChains(
  [chain.mainnet],
  [
    alchemyProvider({ apiKey: "FxkCI6WVT4GWQVr43KflH9fYlgIjc6Cn" }),
    publicProvider()
  ]
);
const { connectors } = getDefaultWallets({
  appName: 'ENS Español',
  chains
});
const wagmiClient = createClient({
  autoConnect: true,
  connectors,
  provider
})

const apolloClient = new ApolloClient({
  uri: "https://api.thegraph.com/subgraphs/name/ensdomains/ens",
  cache: new InMemoryCache()
});

function App() {
  
  
  
  return (
    <>
    <WagmiConfig client={wagmiClient}>
    <RainbowKitProvider modalSize="compact" chains={chains}
    theme={lightTheme({
      accentColor: '#CD2B4C',
      
  
    })}

    >
    <ApolloProvider client={apolloClient}>
    <ReactNotifications/>
      <Layout>
      </Layout>
    </ApolloProvider>
    </RainbowKitProvider>
   </WagmiConfig>
    <Analytics />
    </>
  )
}

export default App;
