import React from 'react'
import User from './User'
import { useEnsName } from 'wagmi'

function UserNoContext({address}) {
  const { data} = useEnsName({
    address: address,
  })
  
  return (
    <User address={address} user={data}/>
  )
}

export default UserNoContext