import {createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'


//const baseUrl = "https://api.ensespañol.com/"

const baseUrl = "https://apiv2.ensespañol.com/"



export const domainsAPI = createApi({
    reducerPath: 'domainsAPI',
    baseQuery: fetchBaseQuery({baseUrl}),
    endpoints: (builder) => ({
        wordsInSpanishFromArray: builder.query({
            query: (words)=> ({
                url: "/words/spanish",
                method: "PUT",
                body: words,
                headers: {
                    "Content-Type" : "application/json"
                }
            }), 
            
        }),
        categoriesFromArray: builder.query({
            query: (words)=> ({
                url: "/categorization/batch/get",
                method: "PUT",
                body: words,
                headers: {
                    "Content-Type" : "application/json"
                }
            }), 
            
        }),
        categoriesForLabel: builder.query({
            query: (label) => ({
                    url: `/label/${label}`,
                    method: "GET",
                    headers: {
                        "Content-Type" : "application/json"
                    }
                }),
        }),
        createPendingCategorizationBatch: builder.mutation({
            query: (props) => ({
                    url: `/categorization/batch/create`,
                    method: "POST",
                    body: props.body,
                    headers: {
                        "Content-Type" : "application/json",
                        "Address": props.address,
                        "Signature": props.signature,
                    }
                }),
        }),
        postCategoriesForLabel: builder.mutation({
            query: (props) => ({
                    url: `/label/${props.labelid}`,
                    method: "POST",
                    body: props.categories,
                    headers: {
                        "Content-Type" : "application/json"
                    }
                }),
        }),
        approveCategoryForLabels: builder.mutation({
            query: (props) => ({
                    url: `/category/${props.approve}/`,
                    method: "POST",
                    body: props.message,
                    headers: {
                        "Content-Type" : "application/json",
                        "Address": props.address,
                        "Signature": props.signature,
                    }
                }),
        }),
        poulateCategories: builder.mutation({
            query: (props) => ({
                    url: `/category/populate/${props.category}`,
                    method: "POST",
                    body: props.message,
                    headers: {
                        "Content-Type" : "application/json",
                        "Address": props.address,
                        "Signature": props.signature,
                    }
                }),
        }),
        getCategories: builder.query({
            query: () => ({
                    url: `/category`,
                    method: "GET",
                    headers: {
                        "Content-Type" : "application/json"
                    }
                }),
        }),
        search: builder.mutation({
            query: ({word, limit}) => ({
                    url: `/search?word=${word}&limit=${limit}`,
                    method: "GET",
                    headers: {
                        "Content-Type" : "application/json"
                    }
                }),
        }),
        getCategorizationHistory: builder.query({
            query: (props) => ({
                    url: `/categorization/history/domain/${props.domainID}`,
                    method: "GET",
                    headers: {
                        "Content-Type" : "application/json"
                    }
                }),
        }),
        getCategory: builder.query({
            query: (props) => ({
                    url: `/categorization/batch/get-domains/${props.categoryID}`,
                    method: "GET",
                    headers: {
                        "Content-Type" : "application/json"
                    }
                }),
        }),
        getPendingCategories: builder.query({
            query: () => ({
                    url: `/category/pending/`,
                    method: "GET",
                    headers: {
                        "Content-Type" : "application/json"
                    }
                }),
        }),
        getCategoriesTickets: builder.query({
            query: () => ({
                    url: `/category/resume`,
                    method: "GET",
                    headers: {
                        "Content-Type" : "application/json"
                    }
                }),
        }),
        getStatsResume: builder.query({
            query: () => ({
                    url: `/stats/resume`,
                    method: "GET",
                    headers: {
                        "Content-Type" : "application/json"
                    }
                }),
        }),
        getLastRegistrationsStatsResume: builder.query({
            query: () => ({
                    url: `/stats/spanish-registered/last/resume`,
                    method: "GET",
                    headers: {
                        "Content-Type" : "application/json"
                    }
                }),
        }),
        getLastCategorizationStatsResume: builder.query({
            query: () => ({
                    url: `/stats/categorization/last/resume`,
                    method: "GET",
                    headers: {
                        "Content-Type" : "application/json"
                    }
                }),
        }),
        getLastCategorizationDetailed: builder.query({
            query: ({interval}) => ({
                    url: `/stats/categorization/last?verbose=true&interval=${interval}`,
                    method: "GET",
                    headers: {
                        "Content-Type" : "application/json"
                    }
                }),
        }),
        getDomainsStatsDetailed: builder.query({
            query: ({queryURL}) => ({
                    url: `${queryURL}`,
                    method: "GET",
                    headers: {
                        "Content-Type" : "application/json"
                    }
                }),
        }),
        filterWords: builder.query({
            
            query: ({query}) => ({
                    
                    url: `/filter/${query}`,
                    method: "GET",
                    headers: {
                        "Content-Type" : "application/json"
                    }
                }),
        }),
        filterDomains: builder.query({
            query: (props) => ({
                    url: `/filter/domains?length=${props.length}&contains=${props.contains}&nocontains=${props.nocontains}&start=${props.start}&end=${props.end}&special=${props.special}&emojis=${props.emojis}`,
                    method: "GET",
                    headers: {
                        "Content-Type" : "application/json"
                    }
                }),
        }),
    })
})

export const {
    useWordsInSpanishFromArrayQuery,
    useCategoriesForLabelQuery,
    usePostCategoriesForLabelMutation,
    useApproveCategoryForLabelsMutation,
    useGetCategoriesQuery,
    useGetPendingCategoriesQuery,
    useFilterWordsQuery,
    useFilterDomainsQuery,
    useCategoriesFromArrayQuery,
    usePoulateCategoriesMutation,
    useGetCategoryQuery,
    useGetCategoriesTicketsQuery,
    useSearchMutation,
    useGetCategorizationHistoryQuery,
    useCreatePendingCategorizationBatchMutation,
    useGetStatsResumeQuery,
    useGetLastRegistrationsStatsResumeQuery,
    useGetLastCategorizationStatsResumeQuery,
    useGetLastCategorizationDetailedQuery,
    useGetDomainsStatsDetailedQuery
} = domainsAPI;