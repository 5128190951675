import React from 'react'
import { useEnsName } from 'wagmi'
import ItemDetailPage from './ItemDetailPage'

function ItemDetailNoAliasContextButCategory({domainName, data, registered, categories}) {
    const { data: alias, isError, isLoading } = useEnsName({
        address: data.registrations[0]?.registrant?.id,
      })
  return (
    <ItemDetailPage domainName={domainName} ownerAlias={alias} categories={categories} registration={data?.registrations[0]} registered={registered} loadingCategories={false}/>
  )
}

export default ItemDetailNoAliasContextButCategory