
import React, { useRef, useEffect, useState } from "react";

export default function useOutsideAlerter(ref) {
    const [clickOutside, setClickOutside] = useState(false);
    useEffect(() => {
        
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
            
          setClickOutside(true)
        } 
      }
      
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
       document.removeEventListener("mousedown", handleClickOutside);
      };
      
    }, [ref]);
    return {clickOutside, setClickOutside}
  }

