import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import DropDownNavButton from '../DropDownNavButton/DropDownNavButton';
import SearchBar from '../SearchBar/SearchBar';
import WalletButton from '../WalletButton/WalletButton';
import ToggleBarMobile from './ToggleBarMobile';
import { useTranslation } from 'react-i18next';
import {
    FaUserCircle,
    FaTimes,
  } from 'react-icons/fa';

function NavBar() {
const {t, i18n} = useTranslation('common');
const [scroll, setScroll] = useState(false);
const [isMobile, setIsMobile] = useState(false);
const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false)
const [hfull, sethfull] = useState('')

useEffect(() => {
    window.addEventListener('scroll', handleSticky);
}, []);

const handleSticky = function () {
    if (window.scrollY >= 100) {
        setScroll(true);
    } else {
        setScroll(false);
    }
};

function openMobileMenu() {
    setIsMobileMenuOpen(true)
    sethfull("h-full")
}

function closeMobileMenu() {
    setIsMobileMenuOpen(false)
    sethfull('')
}

function handleWindowSizeChange() {
    if (window.innerWidth < 1024) {
        setIsMobile(true);
        return
    }
    setIsMobile(false)
    closeMobileMenu()
}
useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
        window.removeEventListener('resize', handleWindowSizeChange);
    }
}, []);




const pageTextData = [
    {
        id: 10,
        text: 'Bulk Search',
        ref: '/bulk-search',
    },
    {
        id: 11,
        text: 'Filtro en español',
        ref: '/filter',
    },
    {
        id: 12,
        text: 'Filtro global',
        ref: '/filter-global',
    },
]




  return (
    
    <header
		className={
		    scroll
			? `js-page-header fixed top-0 z-20 w-full backdrop-blur transition-colors js-page-header--is-sticky  ${hfull}`
			: `js-page-header fixed top-0 z-20 w-full backdrop-blur transition-colors  dark:bg-black ${hfull} `
			}
		    >
               <div className="flex items-center px-6 py-4 xl:px-24 ">
                    <Link to="/">
						<span className="shrink-0 dark:hidden hidden  lg:block">
							<img src="/images/logow.png" alt="" className="max-h-10 h-auto" />
						</span>
                        <span className="hidden h-50 lg:dark:block">
							<img src="/images/logob.png" alt="" className="max-h-10 h-auto" />
						</span>
                        <span className="shrink-0 lg:hidden ">
							<img src="/images/logosm.png" alt="" className="max-h-10 h-auto" />
						</span>
				    </Link>
                    <SearchBar className={"relative ml-12 mr-8 hidden basis-3/12 lg:block xl:ml-[8%]"} placeholder={t('navbar.searchbar')}></SearchBar>
                    

                    {/* <!-- Mobile --> */}
                    <div
                        className={
                            isMobileMenuOpen
                            ? 'js-mobile-menu dark:bg-black invisible fixed inset-0 z-10 ml-auto items-center bg-white opacity-0 lg:visible lg:relative lg:inset-auto lg:flex lg:bg-transparent lg:opacity-100 dark:lg:bg-transparent nav-menu--is-open '
                            : 'js-mobile-menu dark:bg-black invisible fixed inset-0 z-10 ml-auto items-center bg-white opacity-0 lg:visible lg:relative lg:inset-auto lg:flex lg:bg-transparent lg:opacity-100 dark:lg:bg-transparent '
                        }
                    >
                            
                        <div className="t-0 dark:bg-black fixed left-0 z-10 flex w-full items-center justify-between bg-white p-6 lg:hidden">
                        {/* <!-- Mobile Logo --> */}

                       
                            <Link to="/" onClick={() => closeMobileMenu()}>
                                <img
                                    src="/images/logow.png"
                                    className="max-h-10 h-auto  dark:hidden"
                                    
                                />

                                <img
                                    src="/images/logob.png"
                                   
                                    className="max-h-10 h-auto dark:block hidden"
                                />
                            </Link>
                        

                        {/* <!-- Mobile Menu Close --> */}
                        <button
                            className="js-mobile-close border-jacarta-100 hover:bg-accent focus:bg-accent group dark:hover:bg-accent ml-2 flex h-10 w-10 items-center justify-center rounded-full border bg-white transition-colors hover:border-transparent focus:border-transparent dark:border-transparent dark:bg-white/[.15]"
                            onClick={() => {
                                closeMobileMenu()
                            }}
                        >
                            <FaTimes width="24"
                                height="24" className="fill-jacarta-700 h-4 w-4 transition-colors group-hover:fill-white group-focus:fill-white dark:fill-white"/>
                            
                        </button>
                        </div>

                        <SearchBar className={"relative mt-24 mb-8 w-full lg:hidden"} onNavigate={closeMobileMenu} placeholder={t('navbar.searchbar')}></SearchBar>
                        
                        <nav className="navbar w-full">
                            <ul className="flex flex-col lg:flex-row">
                               
                                
                                <DropDownNavButton onNavigate={closeMobileMenu} mainRoute={"/filter"} title={t('navbar.item1')} ></DropDownNavButton>
                                <DropDownNavButton onNavigate={closeMobileMenu} mainRoute={"/categories"}  title={t('navbar.item2')}></DropDownNavButton>
                                <DropDownNavButton onNavigate={closeMobileMenu} mainRoute={"/bulk-search"} title={t('navbar.item3')}></DropDownNavButton>
                                <DropDownNavButton onNavigate={closeMobileMenu} mainRoute={"/profile"} title={<div><FaUserCircle className='mr-1 pb-1 text-accent inline-block ' size={22}/>{t('navbar.item4')}</div>}></DropDownNavButton>
                                
                                
                        
                            </ul>
                            
                        </nav>
                    </div>
                    
                     <ToggleBarMobile openMenuCallback={openMobileMenu}></ToggleBarMobile>

                   
               </div>
    </header>
  )
}

export default NavBar