import React, {useState} from 'react'
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import useDarkMode from '../../services/darkmode'


function SkeletonIMG({imgClass, src,loading, skClass}) {
const [isLoadedIMG, setIsLoadedIMG] = useState(false)


  return (
    <>
    <img
        src={src}
        loading={loading}
        onLoad={() => setIsLoadedIMG(true)}
        className={isLoadedIMG ? imgClass : "hidden"}
    /> 
        {!isLoadedIMG?       
          <div className={`dark:bg-gray-700 bg-gray-300 animate-pulse ${skClass}`}> </div> 
        : ''
        
        }
    </>
    )}

export default SkeletonIMG