import React, {useState, useEffect} from 'react'
import DomainsGrid from './DomainsGrid'
import {
    FaList,
    FaThLarge,
    FaTh,
    FaFileCsv,
    FaFileDownload,
    FaSearch,
    FaPlus,
    FaEyeSlash,
  } from 'react-icons/fa';
import SortDropDown from './SortDropDown';
import Pagination from './Pagination';
import DomainsTable from './DomainsTable';
import ListView from './ListView';
import CanvasView from './CanvasView';
import AvailableDropDown from './AvailableDropDown';
import BackToTop from '../BackToTopButton/BackToTop';
import LoadingSpinner from '../Utils/LoadingSpinner';
import { CSVLink } from 'react-csv';
import { useTranslation } from 'react-i18next';



const omit = "español"

function DomainsList(props) {
    const {t, i18n} = useTranslation('common');
    const [view, setView] = useState("grid")
    const [domains, setDomains] = useState([])
    const [categories, setCategories] = useState({"todos": true})
    const [filter, setFilterActive] = useState("todos")
    const [filteredDomain, setFilteredDomain] = useState([])
    const [currentPage, setCurrentPage] = useState(1);
    const [recordsPerPage] = useState(70);
    const [filteredConditions, setFilteredConditions] = useState([])
    const indexOfLastRecord = currentPage * recordsPerPage;
    const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
    const currentRecords = filteredDomain.slice(indexOfFirstRecord, indexOfLastRecord);
    const nPages = Math.ceil(filteredDomain.length / recordsPerPage)
    const list = props.domains
    const [csvData, setCSVData] = useState([])
    const [displaySharedCats, setDisplaySharedCats] = useState(false)

    function handleCSVExport(event, done) {
        const nData = []
        for(let i =0; i < filteredDomain.length; i++) {
            const formatRegistrationDate = filteredDomain[i].available ? "-" : new Intl.DateTimeFormat('es-ES', { year: 'numeric', month: '2-digit', day: '2-digit'}).format(filteredDomain[i].registrationDate*1000)
            const formatExpirynDate = filteredDomain[i].available ? "-" : new Intl.DateTimeFormat('es-ES', { year: 'numeric', month: '2-digit', day: '2-digit'}).format(filteredDomain[i].expiryDate*1000)
            nData.push({
                Dominio: filteredDomain[i].name,
                Label: filteredDomain[i].label,
                Propietario: filteredDomain[i].ownerAlias,
                Registro: formatRegistrationDate,
                Vencimiento: formatExpirynDate,
                Tag: filteredDomain[i].tag ? filteredDomain[i].tag : '',
                Categorías: filteredDomain[i].categories ? filteredDomain[i].categories.filter((cat) => {
                    return cat !== "español"
                }).join() : '',


            })
        }
        setCSVData(nData)
        done(true);
    }
    function handleFilterChange(key) {
        setFilterActive(key)
        const condition = {
            name: "hasCategory",
            hasCondition: function(domain) {return true}
        }
        if (key === "todos"){
            newCondition(condition, true)
            return
        }
        condition.hasCondition = function(domain) {return (domain.categories && domain.categories.indexOf(key) >= 0)}
        newCondition(condition, true)
        return
    }
    
    
    function newCondition(condition, removePrevious = false) {
        if(!condition) {
            return
        }
        const newFilters = filteredConditions.map((cond) => {
            if(!cond)  {
                return
            }
            if (!removePrevious) {
                return cond
            }
            if(cond.name != condition.name) {
                return cond
            }
        }) 
        newFilters.push(condition)
        setFilteredConditions(newFilters)
    }

    useEffect(() => {
        const filtered = domains.filter((domain) => {
            let brek = false;
            for(let i =0; i < filteredConditions.length; i++) {
                if ( filteredConditions[i] && !filteredConditions[i].hasCondition(domain)) {
                    brek = true
                    break;
                }
                
            }
            return !brek;
        })
        setCurrentPage(1)
        setFilteredDomain(filtered)
    
    }, [filteredConditions])

    function refreshFilters() {
        const nFilters = [...filteredConditions]
        setFilteredConditions(nFilters)
    }
    
   
    useEffect(() => {
        
        const cats = {"todos": true};
        const doms = []
        const catArray = ["todos"]
        for(let i =0; i < list.length; i++) {
            const nv = list[i]
            doms.push(nv)
            if (!nv.categories) {
                continue
            }
           
            for(let j=0; j<nv.categories.length; j++) {
                if (props.showSpanishCategoryFilter === false && nv.categories[j] === omit || props.hideRedundantCategory === nv.categories[j] ) {
                    continue
                }
                if (!cats[nv.categories[j]]) {
                    cats[nv.categories[j]] = true;
                    catArray.push(nv.categories[j])
                }
                
            }
        }
        setDomains(doms)
        setFilteredDomain(doms)
        setCategories(catArray)
        
    }, [list])

    if(!domains || domains.length < 1) {
        return <div className='mt-5 flex justify-center items-center flex-col'>
       <LoadingSpinner />
      <div className='mt-2 dark:text-white'>{t('domainlist.loading')}</div>
      </div>
    }
  return (
    <>
    <BackToTop/>
    <section className="relative py-25 mt-5">
    <div className="container">
        <div className="mb-8 flex flex-wrap items-center justify-between">
            { displaySharedCats && categories.length>1 ? 
            <ul className="flex flex-wrap items-center">
                {
                    categories.map((key) => {
                        if (key && key.length>1) {
                            return <li className="my-1 mr-2.5" key={key}>
                            <button className={key===filter ? 
                                'dark:border-gray-600  bg-accent group text-white border-jacarta-100 font-display  flex h-9 items-center rounded-lg border  px-4 text-sm font-semibold transition-colors hover:border-transparent hover:text-white dark:text-white dark:hover:border-transparent dark:hover:text-white capitalize':
                                'dark:border-gray-600 dark:bg-gray-900 dark:hover:bg-accent group hover:bg-accent border-jacarta-100 font-display text-jacarta-500 flex h-9 items-center rounded-lg border bg-white px-4 text-sm font-semibold transition-colors hover:border-transparent hover:text-white dark:text-white dark:hover:border-transparent dark:hover:text-white capitalize'
                                }
                                onClick={() => {handleFilterChange(key)}}
                                >
                                {key === "todos" ? t('domainlist.all'): key}
                            </button>
                        </li>
                        }
                        
                    }
                    )
                }
            </ul> : ''
            }
            
            {
                categories.length>1 ? 
                <div className='flex flex-wrap gap-2 items-center'>
                     { !props.hideRedundantCategory && !displaySharedCats ? 
                    <button className={omit===filter ? 
                        'dark:border-gray-600  bg-accent group text-white border-jacarta-100 font-display  flex h-9 items-center rounded-lg border  px-4 text-sm font-semibold transition-colors hover:border-transparent hover:text-white dark:text-white dark:hover:border-transparent dark:hover:text-white capitalize':
                        'dark:border-gray-600 dark:bg-gray-900 dark:hover:bg-accent group hover:bg-accent border-jacarta-100 font-display text-jacarta-500 flex h-9 items-center rounded-lg border bg-white px-4 text-sm font-semibold transition-colors hover:border-transparent hover:text-white dark:text-white dark:hover:border-transparent dark:hover:text-white capitalize'
                        }
                    onClick={() => {handleFilterChange(omit)}}
                    >
                    {omit}
                     </button> : ''
                    }
                    { displaySharedCats  ? 
                    <button  className='dark:border-gray-600   bg-accent group   text-white border-jacarta-100 py-2 mt-1   flex  items-center rounded-lg border  px-5 font-semibold transition-colors hover:border-transparent hover:text-white dark:text-white dark:hover:border-transparent dark:hover:text-white'
                    onClick={() => setDisplaySharedCats(false)}
                    >
                   {t('domainlist.hideCategories')}
                    <FaEyeSlash className='ml-2 text-md font-semibold'/>
                   </button> : 
                   <>
                  
                    <button  className='dark:border-gray-600   bg-accent group   text-white border-jacarta-100 py-2   flex  items-center rounded-lg border  px-5 font-semibold transition-colors hover:border-transparent hover:text-white dark:text-white dark:hover:border-transparent dark:hover:text-white'
                    onClick={() => setDisplaySharedCats(true)}
                    >
                     {t('domainlist.showCategories')}
                    <FaPlus className='ml-2 text-md font-semibold'/>
                    </button> 
                    </>
                    }
                    
                    </div>
                :
                
                ''
            }
            
            <div className=" relative flex flex-wrap gap-4 gap-y-2 items-center justify-start  mt-2 md:mt-0">
               
                
                { props.showAvailable &&
                <div className=''>
                    <AvailableDropDown  newCondition={newCondition}/>
                </div>
            }
                <div >
                    <SortDropDown domains={domains} setDomains={setDomains} loading={props.loading} refresh={refreshFilters}/>
                </div>
                <div>
                    <div class="inline-flex rounded-md shadow-sm dark:shadow-gray-800 h-9">
                    <button className="py-2 px-4 text-sm font-medium text-blue-700 bg-white rounded-l-lg border border-gray-200 hover:bg-gray-100 focus:z-10 focus:ring-2 focus:ring-gray-200 dark:focus:ring-gray-600  dark:bg-gray-900 dark:border-gray-600 dark:text-white dark:hover:bg-gray-600"
                     onClick={() => {setView("list")}}
                    >
                        <FaList/>
                    </button>
                    <button className="py-2 px-4 text-sm font-medium text-gray-900 bg-white  border border-gray-200 hover:bg-gray-100  focus:z-10 focus:ring-2 focus:ring-gray-200 dark:focus:ring-gray-600  dark:bg-gray-900 dark:border-gray-600 dark:text-white  dark:hover:bg-gray-600"
                    onClick={() => {setView("canvas")}}
                    >
                        <FaTh/>
                        
                    </button>
                    <button className="py-2 px-4 text-sm font-medium text-gray-900 bg-white rounded-r-md border border-gray-200 hover:bg-gray-100  focus:z-10 focus:ring-2 focus:ring-gray-200 dark:focus:ring-gray-600  dark:bg-gray-900 dark:border-gray-600 dark:text-white  dark:hover:bg-gray-600"
                    onClick={() => {setView("grid")}}
                    >
                        <FaThLarge/>
                    </button>
                    </div>
                </div>
                <div className='dark:border-gray-600 dark:bg-gray-900 dark:hover:bg-accent group hover:bg-accent border-jacarta-100 font-display text-jacarta-500 flex h-[38px] items-center rounded-lg border bg-white px-2 text-sm font-semibold transition-colors hover:border-transparent hover:text-white dark:text-white dark:hover:border-transparent dark:hover:text-white'>
                <CSVLink data={csvData} className="flex items-center gap-1" filename="ENSEspañol_Dominios.csv"
                asyncOnClick={true}
                onClick={handleCSVExport}
                >
                <FaFileDownload />
                CSV
                    
                </CSVLink>
                </div>
                
                
            </div>
            
        </div>
       
        {view==="grid" ? <DomainsGrid items={currentRecords} from={props.from}/> : view==="canvas" ? <CanvasView items={currentRecords}/> : <DomainsTable items={currentRecords} from={props.from}/>  }
        
        
    </div>
    <div className='mt-20 mb-30 pb-10 text-center'>
    {nPages>1 ? <Pagination nPages={nPages} currentPage={currentPage} setCurrentPage={setCurrentPage}/> : ''}
    </div>
   
    </section>
    </>
  )
}

export default DomainsList