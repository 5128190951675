import React from 'react'
import { useLocation } from 'react-router-dom';
import NotFound from '../../components/Utils/NotFound';
import FilterResultBuilder from './FilterResultBuilder';

function BulkSearchResult() {
   const { state} = useLocation();
   if(!state || state.length < 1) {
    return <NotFound text="No encontramos resultados"/>
  }
  return (
    <FilterResultBuilder domains={state} from={"local"}/>
  )
}

export default BulkSearchResult