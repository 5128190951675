import React, { useEffect, useState } from 'react'
import { DragDropContext, Droppable } from "@hello-pangea/dnd";
import List from './List';

const removeFromList = (list, index) => {
    const result = Array.from(list);
    const [removed] = result.splice(index, 1);
    return [removed, result];
  };
  
  const addToList = (list, index, element) => {
    const result = Array.from(list);
    result.splice(index, 0, element);
    return result;
  };

function DragDropContainers({selectContainer, optionsContainer, selectionTitle,optionsTitle, setSelected }) {
    const [elements, setElements] = useState({});
    useEffect(() => {
      const initElements = {
        "selection": selectContainer,
        "options": optionsContainer,
      }
      setElements(initElements)
      
    }, [])

    const onDragEnd = (result) => {
        if (!result.destination) {
          return;
        }
        const listCopy = { ...elements };
    
        const sourceList = listCopy[result.source.droppableId];
        const [removedElement, newSourceList] = removeFromList(
          sourceList,
          result.source.index
        );
        listCopy[result.source.droppableId] = newSourceList;
        const destinationList = listCopy[result.destination.droppableId];
        listCopy[result.destination.droppableId] = addToList(
          destinationList,
          result.destination.index,
          removedElement
        );
    
        setElements(listCopy);
        setSelected(listCopy["selection"])
      };
    
  return (
    <DragDropContext onDragEnd={onDragEnd}>
        <div className='flex justify-around'>
          <List elements={elements["selection"]} key={"selection"} listkey={"selection"} title={selectionTitle}/>
          <List elements={elements["options"]} key={"options"} listkey={"options"} title={optionsTitle}/>
        </div>
    </DragDropContext>
  )
}

export default DragDropContainers