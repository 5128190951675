import React from 'react'
import LastCategorizations from '../../components/Stats/LastCategorizations'
import LastRegistrations from '../../components/Stats/LastRegistrations'
import Resume from '../../components/Stats/Resume'

function StatsPage() {
  return (
    <div className='pb-32 pt-10'>
     
    <Resume showLoadSpinner={true}/>
   
    <LastCategorizations/>

    <div className='mt-20'>
    <LastRegistrations />
    </div>
    
    
    </div>
  )
}

export default StatsPage