import React, {useState} from 'react'
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import {FaNewspaper, FaExchangeAlt, FaRegCopy, FaList} from 'react-icons/fa';
import CopyToClipboard from 'react-copy-to-clipboard';
import CopyButton from '../CopyButton/CopyButton';
import ActivityTab from './ActivityTab';
import CategorizationTab from './CategorizationTab';
import { useTranslation } from 'react-i18next';

function MoreDetails({labelHash,registrationDate, tokenID, ID, expirationDate, ownerAddress, controllerAddress, ownerAlias, events, categories}) {
	const [tabsActive, setTabsActive] = useState(1);
	const {t, i18n} = useTranslation('common');
	const tabsHeadText = [
		{
			id: 1,
			text: t('itemdetails.tabs.details.title'),
			icon: <FaNewspaper/>,
		},
        {
			id: 2,
			text: t('itemdetails.tabs.activities.title'),
			icon: <FaExchangeAlt/>,
		},
		{
			id: 3,
			text: t('itemdetails.tabs.categorization.title'),
			icon: <FaList/>,
		},
	];
    
	return (
		<>
			<div className="scrollbar-custom mt-14 overflow-x-auto ">
				{/* <!-- Tabs Nav --> */}
				<Tabs className="min-w-fit tabs ">
					<TabList className="  flex items-center ">
						{/* <!-- Offers --> */}
						{tabsHeadText.map(({ id, text, icon }) => {
							return (
								<Tab className="nav-item border-none text-white dark:bg-black mb-0.5"  key={id}>
									<button
										className={
											tabsActive === id
												? ' rounded-lg border-1.5 dark:border-gray-800 dark:text-white shadow-md dark:shadow-gray-700 dark:bg-black text-black relative flex items-center py-3 px-4 dark:hover:text-white '
												: ' hover:text-gray-700 text-gray-600 relative flex items-center whitespace-nowrap py-3 px-6 dark:hover:text-white'
										}
										onClick={() => setTabsActive(id)}
									>
										<span className='mr-2'> {icon}</span>
										{text}
									</button>
								</Tab>
							);
						})}
					</TabList>

					
					<TabPanel>
						{/* <!-- Details --> */}
						<div
							className="tab-pane fade"
							id="details"
							role="tabpanel"
							aria-labelledby="details-tab"
						>
							<div className="dark:bg-black rounded-2xl  dark:border-gray-600 border-gray-100 border bg-white p-6 md:p-10">
								<div className="mb-2 flex items-center">
									<span className="dark:text-gray-400 text-gray-700 mr-2 min-w-[9rem]">{t('itemdetails.tabs.details.id')}</span>
									
									
									<span className='dark:text-white mr-2'>{ID}</span>
									<CopyButton text={ID}></CopyButton>
										
								</div>
								
								<div className="mb-2 flex items-center">
									<span className="dark:text-gray-400 text-gray-700 mr-2 min-w-[9rem]">{t('itemdetails.tabs.details.registrant')}</span>
									<a href={`/user/${ownerAddress}`} className="text-accent">
										{ownerAddress}
									</a>
								</div>
                                <div className="mb-2 flex items-center">
									<span className="dark:text-gray-400  text-gray-700 mr-2 min-w-[9rem]">{t('itemdetails.tabs.details.controller')}</span>
									<a href={`/user/${controllerAddress}`} className="text-accent">
									{controllerAddress}
									</a>
								</div>
								
								<div className="mb-2 flex items-center">
									<span className="dark:text-gray-400 text-gray-700 mr-2 min-w-[9rem]">{t('itemdetails.tabs.details.registration')}</span>
									<span
										className=" dark:text-white"								
									>
										{registrationDate}
									</span>
								</div>
                                <div className="mb-2 flex items-center">
									<span className="dark:text-gray-400 text-gray-700 mr-2 min-w-[9rem]">{t('itemdetails.tabs.details.expiration')}</span>
									<span
										className=" dark:text-white"			
									>
										{expirationDate}
									</span>
								</div>
								<div className="mb-2 flex items-center">
									<span className="dark:text-gray-400 text-gray-700 mr-2 min-w-[9rem]">{t('itemdetails.tabs.details.labelhash')}</span>
									<span className='dark:text-white  mr-2'>{labelHash}</span>
									<CopyButton text={labelHash}></CopyButton>
								</div>
                                <div className="mb-2 flex items-center">
									<span className="dark:text-gray-400 text-gray-700 mr-2 min-w-[9rem]">{t('itemdetails.tabs.details.tokenID')}</span>
									<a href={`https://etherscan.io/nft/0x57f1887a8bf19b14fc0df6fd9b2acc9af147ea85/${tokenID}`} className='dark:text-white mr-2'>{tokenID}</a>
									
								</div>
								<div className="mb-2 flex items-center">
									<span className="dark:text-gray-400 text-gray-700 mr-2 min-w-[9rem]">{t('itemdetails.tabs.details.tokenStandard')}</span>
									<span className=" dark:text-white">ERC-721</span>
								</div>
							</div>
						</div>
					</TabPanel>
					
					<TabPanel>
						<ActivityTab ownerAddress={ownerAddress} ownerAlias={ownerAlias} events={events}/>
					</TabPanel>
					<TabPanel>
						<CategorizationTab categories={categories}/>
					</TabPanel>
				</Tabs>
			</div>
		</>
	);
};

export default MoreDetails