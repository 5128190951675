import React from 'react'
import { useQuery, gql } from "@apollo/client";
import ItemDetailNoContext from './ItemDetailNoContext';
import ItemDetailPage from './ItemDetailPage';
import { useLocation } from 'react-router-dom';
import {useParams} from 'react-router-dom'
import LoadingSpinner from '../../components/Utils/LoadingSpinner';
import ItemDetailNoAliasContextButCategory from './ItemDetailNoAliasContextButCategory';
import { useGetCategorizationHistoryQuery } from '../../services/domainsAPI';

import { useTranslation } from 'react-i18next';



function ItemDetailRouter() {
  const {domainName} = useParams()
  const {state} = useLocation()
  const {t, i18n} = useTranslation('common');
  const queryString = gql`
{
  registrations(first: 1, where: {domain_:{ name: "${domainName}" }}) {
            expiryDate
            registrationDate
            registrant {
              id
            }
            domain{
              id
              createdAt
              labelName
              labelhash
              owner {
                id
              }
            }
            events {
              ... on NameRegistered {
                id
                blockNumber
                transactionID
              }
              ... on NameTransferred {
                newOwner {
                  id
                }
                blockNumber
                transactionID
              }
            }
          }
}
`;
const { data: registrations, loading, error } = useQuery(queryString);

const {data: categories, isLoading} = useGetCategorizationHistoryQuery({domainID: domainName.substring(0,domainName.length-4)})

if (loading || isLoading) {
  return  <div className='mt-5 flex justify-center items-center flex-col'>
  <LoadingSpinner />
 <div className='mt-2 dark:text-white'>{t('itemdetails.loading')}</div>
 </div>
} 

const registered = registrations?.registrations?.length > 0


   if  (!state  || !state.ownerAlias || (state.ownerAlias.length === 42 && state.ownerAlias.startsWith("0x"))) {
    return <ItemDetailNoContext data={registrations} domainName={domainName} registered={registered} categories={categories}/>
  }

  const {ownerAlias, tag} = state



  
  return (
    <ItemDetailPage loadingCategories={false} domainName={domainName} ownerAlias={ownerAlias} categories={categories} tag={tag} registration={registrations?.registrations[0]} registered={registered}/>
  )
  
}

export default ItemDetailRouter