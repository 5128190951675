import React, {useState, useEffect} from 'react'
import CopyToClipboard from 'react-copy-to-clipboard'
import {FaRegCopy, FaCopy} from 'react-icons/fa';
import TextPopOver from '../TextPopOver/TextPopOver';
import Tippy from '@tippyjs/react'

function CopyActionButton({text, Icon, ActionIcon, btnClass}) {
    const [copied, setCopied] = useState(false);

    useEffect(() => {
        setTimeout(() => setCopied(false), 2000)
    }, [copied])

  return (
    <>
    
    <CopyToClipboard text={text} onCopy={() => setCopied(true)}> 
          <a >{copied ? <ActionIcon className={`dark:text-white cursor-pointer ${btnClass}`}/>:  <Icon className={`dark:text-white cursor-pointer ${btnClass}`}/> }
           
          </a>
    </CopyToClipboard>
    
    </>
  )
}

export default CopyActionButton