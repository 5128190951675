import React from 'react'
import { useLocation } from 'react-router-dom'
import LoadingSpinner from '../../components/Utils/LoadingSpinner';
import NotFound from '../../components/Utils/NotFound';
import {useTranslation} from "react-i18next";
import { useFilterWordsQuery } from '../../services/domainsAPI';
import FilterResultBuilder from './FilterResultBuilder';



 function serialize(obj) {
  var str = [];
  for (var p in obj)
    if (obj.hasOwnProperty(p)) {
      str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
    }
  return str.join("&");
}

function FiltersResult() {
  const { state} = useLocation();
  const [t, i18n] = useTranslation('common');
  const query = `${state.filter}?${serialize(state.params)}`

  
  const {data, isLoading, isError,isFetching} = useFilterWordsQuery({query: query});
  if(isLoading) {
    return <div className='mt-5 flex justify-center items-center flex-col'>
     <LoadingSpinner />
    <div className='mt-2 dark:text-white'>Filtrando dominios...</div>
    </div>
  }
  if(!data || data.length < 1) {
    return <NotFound text={t('category.notfound')}/>
  }

  return (
    <FilterResultBuilder domains={data} from={"local"}/>
  )
}

export default FiltersResult