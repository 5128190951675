import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import {Provider} from 'react-redux';
import store from './store/store'
import { BrowserRouter as Router} from 'react-router-dom';
import {I18nextProvider} from "react-i18next";
import i18next from "i18next";
import HttpApi from 'i18next-http-backend';



import common_es from "./locales/es/common.json";
import common_en from "./locales/en/common.json";
import common_pt from "./locales/pt/common.json";
import common_fr from "./locales/fr/common.json";
import common_it from "./locales/it/common.json";
import common_ro from "./locales/ro/common.json";


function getLanguageFromLocalStorage() {
  const lang = localStorage.getItem('es');
  return lang ? JSON.parse(lang) : 'es';
}


const savedLanguage = getLanguageFromLocalStorage();


i18next.init({
    interpolation: { escapeValue: false },  
     lng: savedLanguage,                             
    resources: {
        en: {
            common: common_en               
        },
        es: {
            common: common_es
        },
        fr: {
          common: common_fr
        },
        pt: {
          common: common_pt
      },
      it: {
        common: common_it
    },
    ro: {
      common: common_ro
  },

    },
});



const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Provider store={store}>
    <Router>
    <I18nextProvider i18n={i18next}>
      <App />
      </I18nextProvider>
    </Router>
  </Provider>
);


