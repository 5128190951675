import React from 'react'
import User from '../../pages/User'
import NavBar from '../NavBar/NavBar'
import { Routes, Route, Link} from 'react-router-dom';
import UserRouter from '../../pages/UserRouter';
import UserProfile from '../../pages/UserProfile';
import ItemDetailRouter from '../../pages/itemDetails/ItemDetailRouter';
import FilterSpanish from '../../pages/Filters/filterSpanish';
import Categories from '../../pages/Categories/Categories';
import FiltersResult from '../../pages/Filters/filtersSpanishResult';
import FilterGlobal from '../../pages/Filters/FilterGlobal';
import FilterGlobalResult from '../../pages/Filters/filterGlobalResult';
import BulkSearch from '../../pages/Filters/BulkSearch';
import BulkSearchResult from '../../pages/Filters/BulkSearchResult';
import Landing from '../../pages/Landing/Landing';
import Footer from '../Footer/Footer';
import About from '../../pages/About/about';
import CategoryPage from '../../pages/Categories/CategoryPage';
import StatsPage from '../../pages/Stats/StatsPage';
import LastCategorizationsDetailed from '../../pages/Stats/LastCategorizationsDetailed';
import GenericStatsDetailedPage from '../../pages/Stats/GenericStatsDetailedPage';
import GenericDomainCategoryDetailed from '../../pages/Stats/GenericDomainCategoryDetailed';
import Donate from '../../pages/Donate/Donate';


function Layout() {
  return (
    <div className='flex flex-col h-screen justify-between'>
    <NavBar/>
    <main >
    <section className=" relative container pt-20 px-10 md:pt-32  ">
      <Routes>
        <Route exact path="/" element={<Landing/>}></Route>
        <Route exact path="/profile" element={<UserProfile/>}></Route>
        <Route exact path="/user/:username" element={<UserRouter/>}></Route>
        <Route exact path="/domain/:domainName" element={<ItemDetailRouter/>}></Route>
        <Route exact path="/filter" element={<FilterSpanish/>}></Route>
        <Route exact path="/bulk-search" element={<BulkSearch/>}></Route>
        <Route exact path="/bulk-search/result" element={<BulkSearchResult/>}></Route>
        <Route exact path="/filter-global" element={<FilterGlobal/>}></Route>
        <Route exact path="/filter/result" element={<FiltersResult/>}></Route>
        <Route exact path="/filter-global/result" element={<FilterGlobalResult/>}></Route>
        <Route exact path="/categories" element={<Categories/>}></Route>
        <Route exact path="/category/:categoryID" element={<CategoryPage/>}></Route>
        <Route exact path="/about" element={<About/>}></Route>
        <Route exact path="/donate" element={<Donate/>}></Route>
        <Route exact path="/stats" element={<StatsPage/>}></Route>
        <Route exact path="/stats/last/categorization/:interval" element={<LastCategorizationsDetailed/>}></Route>
        <Route exact path="/stats/detailed" element={<GenericStatsDetailedPage/>}></Route>
        <Route exact path="/stats/categorization/detailed" element={<GenericDomainCategoryDetailed/>}></Route>
      </Routes>
      
    </section>
    </main>
    <Footer/>
    </div>
  )
}

export default Layout