import Tippy from '@tippyjs/react';
import React, {useEffect, useRef, useState} from 'react'
import { Hint } from 'react-autocomplete-hint';
import {Link, useNavigate } from "react-router-dom";
import {ens_normalize} from '@adraffy/ens-normalize';
import { useSearchMutation } from '../../services/domainsAPI';
import { useTranslation } from 'react-i18next';
import useOutsideAlerter from '../../hooks/click_outside';

function SearchBar({className, onNavigate, placeholder="dominio | @usuario | dirección eth"}) {
	const {t, i18n} = useTranslation('common');
	const [text, setText] = useState('')
	const [suggestions, setSuggestions] = useState([])
	const [dropwdown, setDropDown] = useState(false)
	const navigate = useNavigate();
	const [postSearch, result] = useSearchMutation()
	
	const wrapperRef = useRef(null)
    const {clickOutside, setClickOutside} = useOutsideAlerter(wrapperRef)



	function handleDrownDown() {
       setClickOutside(false)
	   setDropDown(true)

    }

	function handleChange(e) {
		const input = e.target.value
		setText(modifyValue(input))
		if (!input ||  input.length <3) {
			setSuggestions([])
			setDropDown(false)
			return
		}
		
		try {
			const normalized = ens_normalize(input + ".eth")
			if (input.indexOf('.')>-1) {
				setSuggestions([])
				setDropDown(false)
				return
			}
			setSuggestions([normalized])
			handleDrownDown()
		
		  }
		  catch(err) {
			setSuggestions([])
			setDropDown(false)
		  }
		
		
		
		if (result.isError) {
			return
		}
		
		postSearch({word:input, limit:5})
		
	}

	function handleSuggesstionClick(sug) {
		setText(sug)
	    setSuggestions([])
		handleNavigate(sug)
	}


	useEffect(() => {
		
		

		
		if (!result || result.isLoading || result.isError || !result.data) {
			return
		}
		const newSuggestion = [text+".eth"]
		
		
		result.data.map((domain) => {
		if (domain === text) {
			return
		}
		newSuggestion.push(domain+".eth")
		})
		
		setSuggestions(newSuggestion)
	}, [result])
	



	
	

	const modifyValue = (value) => value.toLowerCase();

	function goToNavigate(route) {
		onNavigate && onNavigate()
		
		navigate(route)
		setText("")
		
	}


	function handleNavigate(to = text) {
		
		const domain = to.replace(/\s/g, "");
		if (domain.length < 3) {
			return
		}
		setDropDown(false)
		if(domain.startsWith("0x") && domain.length === 42) {
			
			goToNavigate(`/user/${domain}`)
			return
		}
		if(domain.startsWith("@") && domain.endsWith(".eth")) {
			const normal = ens_normalize(domain.substring(1))
			goToNavigate(`/user/${normal}`)
			return
		}
		if(domain.endsWith(".eth")) {
			const normal = ens_normalize(domain)
			if (normal.indexOf(".") !== normal.length - 4) {
				return
			}
			goToNavigate(`/domain/${domain}`)
			return
		} 
		
		
		if(domain.startsWith("@") ) {
			const normal = ens_normalize(`${domain.substring(1)}.eth`)
			
			goToNavigate(`/user/${normal}`)
			return
		}

		if(domain.indexOf('.') < 0) {
			const normal = ens_normalize(domain)
			goToNavigate(`/domain/${normal}.eth`)
		}
		
		
	}

	
  return (
	<>
    <div
		className={`${className} relative`}
		ref={wrapperRef}
		>
			<form onSubmit={ (e) => {
				e.preventDefault()
					handleNavigate();
				}}>
			
			
			<input				
				className=" text-gray-700 placeholder-gray-600 dark:placeholder-gray-300  dark:placeholder:gray-300 focus:ring-accent border-gray-100 w-full rounded-2xl border py-3 px-4 pl-10 dark:border-transparent dark:bg-white/[.15] dark:text-white"
				placeholder={placeholder}
				onChange={e => handleChange(e)}
				value={text}
			/>
			
			</form>
			<span className="absolute left-0 top-0 flex h-full w-12 items-center justify-center rounded-2xl" >
			<svg
			xmlns="http://www.w3.org/2000/svg"
			viewBox="0 0 24 24"
			width="24"
			height="24"
			className="fill-gray-500 h-4 w-4 dark:fill-white"
	        onClick={handleNavigate}
			>
			<path fill="none" d="M0 0h24v24H0z" />
			<path d="M18.031 16.617l4.283 4.282-1.415 1.415-4.282-4.283A8.96 8.96 0 0 1 11 20c-4.968 0-9-4.032-9-9s4.032-9 9-9 9 4.032 9 9a8.96 8.96 0 0 1-1.969 5.617zm-2.006-.742A6.977 6.977 0 0 0 18 11c0-3.868-3.133-7-7-7-3.868 0-7 3.132-7 7 0 3.867 3.132 7 7 7a6.977 6.977 0 0 0 4.875-1.975l.15-.15z" />
			</svg>
		    </span>
			{ !clickOutside && dropwdown  ?
			<div className='bg-gray-200  dark:bg-gray-900 border-[1px] dark:text-white shadow-xl py-1 absolute w-full mt-1 rounded-lg 	z-50'>
				{
				suggestions.map((sug) => {
					return <div onClick={() => handleSuggesstionClick(sug)} className='py-1 dark:hover:bg-gray-700 hover:bg-gray-300  border-gray-700 px-4  dark:text-gray-100 text-gray-900 font-light cursor-pointer'>{sug}</div>
				})
			}
			{
				result.isLoading ? <div className='py-1 hover:bg-gray-700  border-gray-700 px-4   text-gray-100 font-light cursor-pointer'>{`${t('navbar.loadingmore')}...`}</div> : ''
			}
			
			<div className='text-sm font-light text-accent text-center py-1 pr-4 hover:bg-gray-400 dark:hover:bg-gray-700'>
				<Link to={"/filter"} onClick={()=> {setDropDown(false); setSuggestions([])}}>{t('navbar.item1')}</Link>  
			</div>
			
			</div> : ''
			
			}
	</div>
	
	</>
  )
}

export default SearchBar