import React from 'react'
import SearchBar from '../SearchBar/SearchBar'
import { useTranslation } from 'react-i18next';

function SearchSection() {
  const {t, i18n} = useTranslation('common');
  return (
    <section className=" mt-20 mb-40  dark:bg-black">
        <div className="container">
            <h2 className="mb-2 text-center font-display text-4xl font-medium text-gray-700 dark:text-white">
            {t('landing.gosearch.title')}
            </h2>
            <p className="text-lg text-center dark:text-gray-300">
            {t('landing.gosearch.subtitle')}
			</p>
            <SearchBar  className={"relative pt-2 mx-2  basis-3/12  "} placeholder={t('landing.gosearch.placeholder')}/>
        </div>
    </section>
  )
}

export default SearchSection