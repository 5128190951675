import React from 'react'
import { useState } from 'react'
import { FaSearch } from 'react-icons/fa'
import CategoriesDropDown from '../../components/CategoriesDropDown/CategoriesDropDown'
import {useNavigate } from "react-router-dom";
import {ens_normalize} from '@adraffy/ens-normalize';
import { useTranslation } from 'react-i18next';

function BulkSearch() {
  const {t, i18n} = useTranslation('common');
  
  const navigate = useNavigate();

  function deleteETHAndEmptyDomains(values) {
    
    const response = []
     for( let i =0; i < values.length; i++) {
      
        let st = values[i].replace(/ /g,'').toLowerCase()
        if (st.length < 3 ) {
          continue
        }
        try {
          st = ens_normalize(st)
        }
        catch(err) {
          continue
        }
        
        if (st.endsWith(".eth")) {
            if (st.length < 7) {
                continue
            }
            if (st.indexOf(".") !== st.length - 4) {
              continue
            }
            response.push(st.slice(0,st.length - 4))
            continue
        } else {
          if (st.indexOf(".") > -1) {
            continue
          }
        }
        response.push(st)
     }
     return response
  }

  function handleSubmit(event) {
    event.preventDefault()
    const area = event.target[0].value
    if (!area || area.length < 3) {
        return
    }
    const arr = deleteETHAndEmptyDomains(area.split("\n"))
    if (!arr || arr.length<1) {
        return
    }
    
    navigate("result",{state: arr})
    /*
    for(let i=0; i <event.target.length; i++) {
      
    }

    
    const params = {
      contains: event.target[0].value,
      start: event.target[1].value,
      end: event.target[2].value,
      length: event.target[3].value,
      category: category,
    }
    
    navigate("result",{state: params})
    */
  }
  return (
    <section className="relative ">
        <div className="container">
          <h1 className=" text-gray-700 py-12 text-center text-4xl font-medium dark:text-white">
               {t('bulksearch.title')}
          </h1>
          <div className="mx-auto max-w-[48.125rem] ">
            <form onSubmit={handleSubmit}>
            <div className="mb-6 ">
              <label
                htmlFor="item-contains"
                className=" text-gray-700 mb-2  dark:text-white"
              >
                 {t('bulksearch.header')}
              </label>
              <textarea
                type="text"
                id="item-bulk"
                className="dark:bg-gray-700 border-gray-400 border   dark:border-gray-600 dark:placeholder:text-gray-300 w-full h-[200px] rounded-lg py-3  px-3  dark:text-white overflow-y-auto "
                placeholder={t('bulksearch.placeholder')}
                
              />
            </div>
           
            <div className="mb-6 flex justify-end gap-5">
            <button type="reset" className="text-gray-900 bg-white  focus:ring-2 focus:ring-accent focus:outline-none   rounded-lg text-sm px-5 py-2.5 text-center dark:bg-gray-700 dark:text-gray-100 dark:focus:ring-gray-600 "> {t('bulksearch.clean')}</button>
            <button type="submit" className='dark:border-gray-600   bg-accent group focus:ring-2 focus:ring-accent-light text-white border-jacarta-100 py-2.5   flex  items-center rounded-lg border  px-5 font-semibold transition-colors hover:border-transparent hover:text-white dark:text-white dark:hover:border-transparent dark:hover:text-white capitalize'>
              {t('bulksearch.search')}
              <FaSearch className='ml-2 mt-0.5 text-sm'/>
            </button>
            </div>
            </form>
          </div>
        </div>
    </section>
  )
}

export default BulkSearch