import React from 'react'
import { FaHandHoldingHeart } from 'react-icons/fa'
import CopyButton from '../CopyButton/CopyButton'
import DonateAddress from './DonateAddress'
import { useTranslation } from 'react-i18next';


const ADDR = "0xE1b34f128B572E10bd43f5E7D198E92EFAb312ae"
const ENS_ALIAS = "ensespañol.eth"
function DonateView() {
  const {t, i18n} = useTranslation('common');
  
  return (
    <>
    <div className='p-5 w-full flex bg-accent justify-center items-center rounded-xl shadow-md shadow-accent mt-5'>
        <img loading="lazy" className=' shadow-accent max-h-[150px] ' src="/images/donate_ens.png"></img>
    </div>
    
    <h3 className=' text-gray-700 py-7 italic text-center text-3xl font-medium dark:text-white'>{t('donate.title')} </h3>
    <div className='flex flex-col gap-5 sm:mt-10 mt-2 mb-16'>
    <DonateAddress title={t('donate.address')} address={ADDR}/>
    <DonateAddress title={"ENS Alias"} address={ENS_ALIAS}/>
    </div>
    
    
    
    </>
  )
}

export default DonateView