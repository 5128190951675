import React from 'react'
import DonateView from '../../components/Donate/DonateView'


function Donate() {
  
  return (
    <DonateView />
  )
}

export default Donate