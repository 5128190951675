import React from 'react'
import { Link } from 'react-router-dom'
import {useTranslation} from "react-i18next";

function Hero() {
  const {t, i18n} = useTranslation('common');
  return (
    <section className="relative pb-32 pt-20 lg:pt-30 ">
     
        <picture className="pointer-events-none  ">
        
          <img loading="lazy" className='max-h-[800px]  dark:opacity-40 opacity-55  absolute -z-50 inset-x-0 lg:top-2 top:10 text-center mr-auto ml-auto' src="/images/back2.png" alt="gradient" />
          
        </picture>
        <div className=' lg:mt-40 mt-8 ml-auto mr-auto h-full flex flex-col items-center gap-8 lg:gap-[100px] z-10'>
        <h1 className="text-center  font-bold text-4xl text-gray-700 dark:text-white lg:text-left lg:text-6xl  ">
         {t('landing.hero.title')}
				</h1>
        <span className='flex justify-center flex-wrap gap-3 mt-[20px]'>
					<span className="text-center font-display text-4xl text-gray-700 dark:text-white lg:text-left lg:text-6xl mt-[px]"> {t('landing.hero.subtitle')} </span>
          <span className="text-transparent  text-4xl lg:text-6xl  bg-clip-text bg-gradient-to-r from-[#fca5a5] via-accent to-[#a855f7] font-extrabold animate-pulse"> {t('landing.hero.subtitlelang')}</span> 
        </span>
       
        <Link to={"/categories"}> <div className='bg-accent shadow-accent  shadow-2xl dark:shadow-accent-dark  rounded-full py-3 px-8 text-center font-bold text-white transition-all animate-bounce mt-3'>{t('landing.hero.button')}</div></Link>
        </div>
    </section>
  )
}

export default Hero