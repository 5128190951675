import React from 'react'

function NotFound({text}) {
  return (
    <div className='flex flex-col items-center gap-5'>
        <img className='mt-5 max-w-[200px] opacity-80' src="/images/not-found-image.png"/>
        <span className='dark:text-gray-400 text-gray-600 text-xl font-medium opacity-80'>{text}</span>
    </div>
  )
}

export default NotFound