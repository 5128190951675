import React from 'react'
import { Link } from 'react-router-dom';
import DomainsGrid from '../../components/DomainsList/DomainsGrid';
import { useGetLastRegistrationsStatsResumeQuery } from '../../services/domainsAPI';
import LoadingSpinner from '../Utils/LoadingSpinner';
import NotFound from '../Utils/NotFound';
import StatsTiles from './StatsTiles';
import { useTranslation } from 'react-i18next';


const LIMIT_TO_LOAD = 50000

function LastRegistrations({showTitle=true, showLoadSpinner=false}) {
  const {t, i18n} = useTranslation('common');
    const {data, isLoading, isError} = useGetLastRegistrationsStatsResumeQuery()
    if(isLoading) {
      return showLoadSpinner ? <div className='mt-5 flex justify-center items-center flex-col'>
      <LoadingSpinner />
         <div className='mt-2 dark:text-white'>Cargando estadísticas</div>
       </div> :<div>
      </div>
     }
   
	 if(!data) {
		return <NotFound text="No encontramos estadísticas"/>
	  }
    const statsDisplay = [
		{
			key: "lastDay",
			label: t('stats.shared.lastDay'),
			value: data?.day,
      to: (data?.day > LIMIT_TO_LOAD || data?.day  <1 ) ? undefined : "/stats/detailed",
      state: {query: "/stats/spanish-registered/last?verbose=true&interval=day"}

		},
		{
			key: "lastWeek",
			label:  t('stats.shared.lastWeek'),
			value:data?.week ,
      to: (data?.week > LIMIT_TO_LOAD || data?.week  <1 ) ? undefined : "/stats/detailed",
      state: {query: "/stats/spanish-registered/last?verbose=true&interval=week"}
		},
		{
			key: "lastMonth",
			label: t('stats.shared.lastMonth'),
			value: data?.month ,
      to: (data?.month > LIMIT_TO_LOAD || data?.month  <1 ) ? undefined : "/stats/detailed",
      state: {query: "/stats/spanish-registered/last?verbose=true&interval=month"}
		},
    ]

    const toProp = statsDisplay[2].to ? 2 : statsDisplay[1].to ? 1 :  statsDisplay[0].to  ? 0 : undefined
    const items = data?.elements

  return (
    <>
    {
      showTitle ? 
    
    <h1 className=" text-gray-700 py-7  text-center text-3xl font-medium dark:text-white">
       {t('stats.registrations.title')}
     </h1> : ''
    } 
    <div className=' dark:bg-gray-900 bg-gray-100 rounded-md p-4 flex flex-col '>
    <div className='flex flex-wrap gap-4 '> 
    {
        
        items.map(item=> {
            
            return <div className='border-gray-100 dark:bg-gray-700 rounded-2xl flex items-center border bg-white py-2 px-6 transition-shadow hover:shadow-lg dark:border-transparent w-full sm:min-w-[200px] sm:max-w-[300px] shadow-lg  dark:shadow-black/50 '>
                <div className=" text-gray-700 hover:text-accent font-semibold dark:text-white justify-between">
                     <Link to={`/domain/${item}`}>{item} </Link>
                     
				</div>

            </div>
        }
            
        )
    }
    </div>
    <Link className="mt-6 self-center" to={toProp? statsDisplay[toProp].to : undefined} state={toProp ? statsDisplay[toProp].state : undefined}>
        <div className='bg-accent  shadow-xl dark:shadow-gray-900-dark rounded-xl hover:bg-accent-dark py-3 px-8 text-center font-bold text-white transition-all max-w-[300px]'>{t('stats.shared.showmore')}</div>
     </Link>
    </div>
    <StatsTiles stats={statsDisplay}/>
    </>
  )
}

export default LastRegistrations