import React from 'react'
import CopyButton from '../CopyButton/CopyButton'

function DonateAddress({title, address}) {
  return (
    <div className='bg-gray-100 dark:bg-gray-900 p-1 pt-2 items-center flex flex-col rounded-xl shadow-md dark:shadow-gray-800 transition-all '>
    <h4 className='font-semibold dark:text-white text-xl'>{title}</h4>
    <div className='flex items-center p-2' >
        <span className='dark:text-white text-xl break-all text-center'>{address}
        </span>
        <CopyButton text={address} btnClass={"text-xl ml-1"}></CopyButton>
    </div>
    </div>
  )
}

export default DonateAddress