import React, {useState} from 'react'
import TextPopOver from '../TextPopOver/TextPopOver'
import {FaShareAlt,
} from 'react-icons/fa';
import Tippy from '@tippyjs/react';
import MoreDetails from './MoreDetails';
import { ethers } from "ethers";
import SkeletonIMG from '../SkeletonIMG/SkeletonIMG';
import CategorizationModal from './CategorizationModal';
import { Link } from 'react-router-dom';
import ShareCopyButton from '../CopyButton/ShareCopyButton';
import CopyButton from '../CopyButton/CopyButton';
import ShortAddress from '../Utils/ShortAddress';
import { TwitterShareButton } from "react-share";
import {TwitterIcon } from "react-share";
import SeeInEtherScan from '../Utils/SeeInEtherScan';
import CategorizationModalTagView from './CategorizationModalTagView';
import { useTranslation } from 'react-i18next';



const omit = "español"

function ItemDetail({ID, labelHash,registrationDate,expirationDate, name, owner, categories, ownerAddress, controllerAddress, events, loadingCategories}) {
    const {t, i18n} = useTranslation('common');
    const formatRegistrationDate = new Intl.DateTimeFormat('es-ES', { year: 'numeric', month: '2-digit', day: '2-digit'}).format(registrationDate*1000)
    const approvedCategoriesArr = categories?.approved && categories?.approved.length > 0 ? categories?.approved.map(cat => cat.Category) : []
    const formatExpirynDate = new Intl.DateTimeFormat('es-ES', { year: 'numeric', month: '2-digit', day: '2-digit'}).format(expirationDate*1000)
    const imagesrc = `https://metadata.ens.domains/mainnet/0x57f1887a8bf19b14fc0df6fd9b2acc9af147ea85/${labelHash}/image`
    const tokenID  = ethers.BigNumber.from(labelHash).toString()
    const [modal, setmodal] = useState(false)
  return (
    <section className="relative lg:mt-10 lg:pt-10 lg:pb-24 mt-18 pt-12 pb-24">
        <div className="container">
            <div className="md:flex md:flex-wrap" key={labelHash}>
                {/* <!-- Image --> */}
                <figure className="mb-8 md:w-2/5 lg:w-3/8 md:flex-shrink-0 md:flex-grow-0 md:basis-auto ">
                    <button className=" w-full relative">
                    <SkeletonIMG imgClass="rounded-2xl cursor-pointer w-full" src={imagesrc} skClass="rounded-2xl w-full pt-[100%]"/>
                        {approvedCategoriesArr.includes(omit) ? <div className='bg-jacarta-700 text-lg  absolute top-10 right-0 flex items-center space-x-1 rounded-md p-2'>
                            <Tippy >
                                <p className='text-white capitalize'>{omit}</p>
                            </Tippy>
                </div>: ''}
                    </button>
                </figure>
                <div className="md:w-3/5 md:basis-auto md:pl-8 lg:w-1/2 lg:pl-[3.75rem] lg:pt-5">
                    <h1 className="font-display text-jacarta-700 mb-2 text-4xl font-semibold dark:text-white">
                        {name}
                    </h1>
                    <div className='pl-2 mt-5'>
                    {owner ? 
                    <p className="dark:text-gray-300 text-gray-600">{t('itemdetails.main.owner')}<Link className=' text-accent'  to={`/user/${owner}`} state={ {ownerAddress: ownerAddress }}> <ShortAddress address={owner} length={18}/> </Link></p> 
                    : 
                    ownerAddress ? <p className="dark:text-gray-300 text-gray-600 whitespace-normal">{t('itemdetails.main.owner')} <Link className=' text-accent'  to={`/user/${ownerAddress}`} state={ {ownerAddress: ownerAddress }}> <ShortAddress address={ownerAddress} length={18}/> </Link> </p>:
                    ''
                    }
                    <p className='dark:text-gray-300 mb-5 text-gray-600'>{`${t('itemdetails.main.expiration')} ${formatExpirynDate}`}</p>
                    <div className='flex justify-start mt-4 items-center gap-4'>
                        <SeeInEtherScan className="w-[32px] h-[32px] mt-1" route={`nft/0x57f1887a8bf19b14fc0df6fd9b2acc9af147ea85/${tokenID}`}/>
                        <figure className="relative w-[30px] h-[30px]">
                                <TextPopOver text={t('itemdetails.main.ensdomain')}>
                                    <a target={"_blank"} href={`https://app.ens.domains/name/${name}/details`}>
                                        <img src="https://raw.githubusercontent.com/ensdomains/ensdomains-v2/5dd186fd7311249c3f28df5130a7dff30b487f38/src/assets/ensLogo.svg"></img>
                                    </a>
                                </TextPopOver>
                        </figure>
                        <TextPopOver text={t('itemdetails.main.twitter')}>
                            <TwitterShareButton
                                title={"Échale un vistazo a este dominio en @ENSesp"}
                                url={`https://ensesp.io/domain/${name}`}
                                hashtags={["ENS", "ENSESP"]}
                            >
                                
                                <TwitterIcon size={32} round />
                            </TwitterShareButton>
                            </TextPopOver>
                        <CopyButton text={`https://ensesp.io/domain/${name}`} btnClass="h-[28px] w-[28px]  cursor-pointer mb-[1px]"/>                   
                    </div>
                        
                    </div>

                    <div className="mb-4 mt-10 flex flex-wrap gap-x-4 gap-y-3">
                    {approvedCategoriesArr.map((category)=> {
                
                        if (category === omit) {
                            return
                        }
                        return <a href={`/category/${category}`} className='dark:border-gray-600 dark:bg-gray-900 hover:shadow-lg dark:shadow-gray-800 group hover:border-transparent dark:hover:border-transparent  border-jacarta-100 font-display text-jacarta-500 flex h-9 items-center rounded-lg border bg-white px-4 text-sm font-semibold transition-colors   dark:text-white '>
                            {category}
                        </a>
                    })}
                    </div>
                    <button
                        className="bg-accent shadow-accent-volume hover:bg-accent-dark inline-block w-full rounded-full py-3 px-8 text-center font-semibold text-white transition-all"   
                        onClick={() =>setmodal(true)} 
                    >
                        {categories.approved?.length> 0 ? t('itemdetails.main.button_categorice') : t('itemdetails.main.button_spanish')}
                    </button>
                    <CategorizationModalTagView open={modal} setOpen={setmodal} domain={name} categories={categories} />
                </div>
                {/*Categorias*/}
                
            </div>
            <MoreDetails labelHash={labelHash} registrationDate={formatRegistrationDate} tokenID={tokenID} ID={ID} expirationDate={formatExpirynDate} ownerAddress={ownerAddress} controllerAddress={controllerAddress} ownerAlias={owner} events={events} categories={categories}/> 
        </div>

    </section>
  )
}

export default ItemDetail