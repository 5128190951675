import React, { useState } from 'react'
import { FaMinus, FaPlus, FaRegArrowAltCircleDown, FaRegArrowAltCircleUp } from 'react-icons/fa'
import { Link } from 'react-router-dom'
import CategoryIcon from '../CategoryIcon/CategoryIcon'
import { useTranslation } from 'react-i18next';

function CategoriesCollapsible({categories, group, imgSRC}) {
    const [expanded, setExpanded] = useState(false)
	const {t, i18n} = useTranslation('common');
    function handleExpand() {
        setExpanded(!expanded)
    }
	
  return ( categories && categories.length > 0 ?
    <div className={expanded ? " text-gray-900 dark:text-white " : "text-gray-500 dark:text-gray-400 rounded-lg bg-gray-100 dark:bg-gray-900 relative"} >
    <h2 >
      <button className="flex px-3 items-center justify-between w-full p-3 font-medium text-left text-gray-500 bg-gray-100 dark:bg-gray-900 rounded-lg  dark:border-gray-700 dark:text-gray-400" onClick={() => handleExpand()}>
        <div className='flex items-center gap-2 lg:gap-3'>
		<img src={imgSRC} className="max-w-[54px] min-h-[54px]"></img>
		<span>{group}</span>
		</div>
		
		
        {expanded ? <FaRegArrowAltCircleUp /> : <FaRegArrowAltCircleDown /> }
      </button>
    </h2>
    <div className={expanded ? "block" : "hidden"} >
      <div class=" py-2 border-gray-200 dark:border-gray-100 px-2 rounded-md">
      <div className="flex flex-wrap gap-5 dark:bg-gray-900 bg-gray-100 rounded-md p-4 justify-around">
					{

						categories?.map((category, index) => {
							
							return (
								<Link to={`/category/${category.category}`}>
								<div
									className="border-gray-100 dark:bg-gray-700 rounded-2xl flex items-center border bg-white py-4 px-7 transition-shadow hover:shadow-lg dark:border-transparent w-[300px] shadow-lg  dark:shadow-black/50"
									key={index}
								>
									<div className="mr-4 shrink-0">
										
											<span className="relative block">
												{/* <img src={image} alt={title} className="rounded-2lg" /> */}
												<div className="dark:border-gray-600 bg-gray-700 absolute -left-3 top-1/2 flex h-6 w-6 -translate-y-2/4 items-center justify-center rounded-full border-2 border-white text-xs text-white dark:bg-gray-600">
													{index+1}
												</div>
												<CategoryIcon classN=" ml-2 h-20 w-20 " categoryName={category.category} short={category.short}/>
												
												
												
											</span>
										
									</div>
									<div>
										
											<span className="">
												<span className=" text-gray-700 hover:text-accent font-semibold dark:text-white">
													{category.category}
												</span>
											</span>
											<div className=' text-gray-600 text-sm hover:text-accent dark:text-gray-400 mt-2'>
											{`${category.domains} ${t('utils.domains')}`}
											</div>
										
									</div>
								</div>
								</Link>
							);
						})}
						</div>
      </div>
    </div>
    
     
    
  </div> : ''
  )
}

export default CategoriesCollapsible