import React from 'react'
import Footer from '../../components/Footer/Footer'
import Functionalities from '../../components/Hero/Functionalities'
import Hero from '../../components/Hero/Hero'
import SearchSection from '../../components/Hero/SearchSection'
import LastRegistrations from '../../components/Stats/LastRegistrations'

function Landing() {
  return (
    <>
      <Hero/>
     
      <Functionalities/>
      <LastRegistrations />
      <SearchSection/>
      

    </>
  )
}

export default Landing