import React from 'react'
import { useState } from 'react'
import { FaSearch } from 'react-icons/fa'
import CategoriesDropDown from '../../components/CategoriesDropDown/CategoriesDropDown'
import {useNavigate } from "react-router-dom";
import GenericDropDown from '../../components/GenericDropDown/GenericDropDown';
import { useFilterDomainsMutation } from '../../services/domainsAPI';


function FilterGlobal() {
  const [emojis, setEmojis] = useState("")
  const [specialChars, setSpecialChars] = useState("")
  const navigate = useNavigate();

  function handleSubmit(event) {
    event.preventDefault()
    
    
    const params = {
      length: event.target[4].value,
      contains: event.target[0].value.toLowerCase(),
      nocontains: event.target[1].value.toLowerCase(),
      start: event.target[2].value.toLowerCase(),
      end: event.target[3].value.toLowerCase(),
      special: specialChars,
      emojis: emojis,
    }
    
    navigate("result",{state: params})

  }
  return (
    <section className="relative ">
        <div className="container">
          <h1 className=" text-gray-700 py-14 text-center text-4xl font-medium dark:text-white">
                Filtra entre millones de dominios ENS:
          </h1>
          <div className="mx-auto max-w-[48.125rem] ">
            <form onSubmit={handleSubmit}>
            <div className="mb-6 ">
              <label
                htmlFor="item-contains"
                className=" text-gray-700 mb-2  dark:text-white"
              >
                Dominios que contengan
              </label>
              <input
                type="text"
                id="item-contains"
                className="dark:bg-gray-700 border-gray-400 border  focus:ring-gray-600 dark:border-gray-600 dark:placeholder:text-gray-300 w-full  rounded-lg py-3 px-3  dark:text-white "
                placeholder="debe contener el término"
                
              />
            </div>
            <div className="mb-6 ">
              <label
                htmlFor="item-nocontains"
                className=" text-gray-700 mb-2  dark:text-white"
              >
                Dominios que no contengan
              </label>
              <input
                type="text"
                id="item-nocontains"
                className="dark:bg-gray-700 border-gray-400 border  focus:ring-gray-600 dark:border-gray-600 dark:placeholder:text-gray-300 w-full  rounded-lg py-3 px-3  dark:text-white "
                placeholder="no debe contener el término"
                
              />
            </div>
            <div className="mb-6 ">
              <label
                htmlFor="item-start"
                className=" text-gray-700 mb-2 dark:text-white"
              >
                Dominios que empiecen por
              </label>
              <input
                type="text"
                id="item-start"
                className="dark:bg-gray-700 border-gray-00 border  focus:ring-gray-600 dark:border-gray-600 dark:placeholder:text-gray-300 w-full  rounded-lg py-3 px-3  dark:text-white "
                placeholder="debe empezar por el término"
                
              />
            </div>
            <div className="mb-6 ">
              <label
                htmlFor="item-end"
                className=" text-gray-700 mb-2 dark:text-white"
              >
                Dominios que terminen en
              </label>
              <input
                type="text"
                id="item-end"
                className="dark:bg-gray-700 border-gray-400 border  focus:ring-gray-600 dark:border-gray-600 dark:placeholder:text-gray-300 w-full  rounded-lg py-3 px-3  dark:text-white "
                placeholder="debe terminar por el término"
               
              />
            </div>
            <div className="mb-6 flex justify-between flex-wrap">
              <div className='max-w-[10rem] mb-5'>
                <label
                    htmlFor="item-end"
                    className=" text-gray-700 mb-2 dark:text-white"
                >
                    Caracteres
                </label>
                <input
                    type="number"
                    id="item-chars"
                    min="3"
                    className="dark:bg-gray-700 border-gray-400 border  focus:ring-gray-600 dark:border-gray-600 dark:placeholder:text-gray-300 w-full  rounded-lg py-3 px-3  dark:text-white "
                    placeholder="ex. 3"
                />
              </div>
              <div className='max-w-[30rem] mb-5 min-w-[15rem] '>
              <label
                    htmlFor="item-emojis"
                    className=" text-gray-700   dark:text-white block"
                >
                    Emojis
                </label>
               <GenericDropDown setValue={setEmojis} items={[
                    {value:true, text: "Con emojis"},
                    {value:false, text: "Sin emojis"},
                    ]
                } buttonClassName="dark:text-white bg-white border-gray-400 border dark:border-gray-600 rounded-lg w-full py-3 px-3  flex items-center  justify-between dark:bg-gray-700 "/>
              </div>
              <div className='max-w-[35rem] mb-5 min-w-[17rem] '>
              <label
                    htmlFor="item-special-char"
                    className=" text-gray-700  dark:text-white block"
                >
                    Caracteres especiales
                </label>
                <GenericDropDown setValue={setSpecialChars} items={[
                    {value:true, text: "Con caracteres especiales"},
                    {value:false, text: "Sin caracteres especiales"},
                    ]
                }
                    
                    buttonClassName="dark:text-white bg-white border-gray-400 border dark:border-gray-600 rounded-lg w-full py-3 px-3  flex items-center  justify-between dark:bg-gray-700 "/>
              </div>
            </div>
            <div className="mb-6 flex justify-end gap-5">
            <button type="reset" className="text-gray-900 bg-white  focus:ring-2 focus:ring-accent focus:outline-none   rounded-lg text-sm px-5 py-2.5 text-center dark:bg-gray-700 dark:text-gray-100 dark:focus:ring-gray-600 ">Limpiar</button>
            <button type="submit" className='dark:border-gray-600   bg-accent group focus:ring-2 focus:ring-accent-light text-white border-jacarta-100 py-2.5   flex  items-center rounded-lg border  px-5 font-semibold transition-colors hover:border-transparent hover:text-white dark:text-white dark:hover:border-transparent dark:hover:text-white capitalize'>
              Buscar
              <FaSearch className='ml-2 mt-0.5 text-sm'/>
            </button>
            </div>
            </form>
          </div>
        </div>
    </section>
  )
}

export default FilterGlobal