import React, {useEffect, useRef, useState} from 'react'
import useOutsideAlerter from '../../hooks/click_outside';
import useComponentVisible from '../../hooks/click_outside';
import { useTranslation } from 'react-i18next';
import { useLangSwitcher } from '../../services/darkmode';


const language = [
    
    {
        id: 'es',
        name: "Español",
        iconSRC: "/images/flags/spain.png",
        description: "Cambiar a Español",
    },
    {
        id: 'en',
        name: "English",
        iconSRC: "/images/flags/england.png",
        description: "Switch to English",
    },
    {
        id: 'pt',
        name: "Portuguese",
        iconSRC: "/images/flags/brasil.png",
        description: "Cambiar a Portugués",
    },
    {
        id: 'fr',
        name: "Français",
        iconSRC: "/images/flags/france.png",
        description: "Passer au français",
    },
    {
        id: 'it',
        name: "Italiano",
        iconSRC: "/images/flags/italy.png",
        description: "Passare all'italiano",
    },
    {
        id: 'ro',
        name: "Română",
        iconSRC: "/images/flags/romania.png",
        description: "Treceți la română",
    },

]

function getLangForID(id) {
    for(let i =0;i < language.length; i++ ) {
        if (id == language[i].id) {
            return language[i]
        }
    }
    return language[0]
}

function LangSwitcher() {
    const {t, i18n} = useTranslation('common');
    const [lang, setLang] = useLangSwitcher()
   
    const [selection, setSelection] = useState(getLangForID(lang))
    const wrapperRef = useRef(null)
    const {clickOutside, setClickOutside} = useOutsideAlerter(wrapperRef)
    const [dropdown, setDropdown] = useState(false);


    function handleSelection(item) {
        i18n.changeLanguage(item.id)
        setLang(item.id)
        setSelection(item)
        setDropdown(false)
    }

    function handleDrownDown() {
        if (!clickOutside) {
            setDropdown(!dropdown)
        } 
        if (clickOutside) {
            setDropdown(true)
        }
        setClickOutside(false)

    }

    useEffect(() => {
        if (!i18n || !lang) {
            return
        }
        i18n.changeLanguage(lang)
      
    }, [i18n, lang])
    
    
   
    

    
    
  return (
    <span className='relative  pt-5' ref={wrapperRef}>
        <button
       
         type="button"
         onClick={handleDrownDown}
         >
         <span>{<img className={'max-h-[24px]   rounded-lg border-2 border-accent-dark'} src={selection.iconSRC} />}</span>
            <svg class="ml-2 w-4 h-4 dark:text-white" aria-hidden="true" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7"></path></svg>
        </button>

        <div  className={!clickOutside && dropdown ? " z-40 absolute w-[40px] md:w-[40px] bg-white rounded-md divide-y divide-gray-100 shadow dark:bg-gray-700" : "hidden"}>
            <ul className=" text-sm text-gray-700 dark:text-gray-200">
                {language?.map(item => {
                    
                    return <li key={item.id} className="block py-1 px-1 hover:bg-gray-100 dark:hover:bg-gray-600 w-full text-start dark:hover:text-white cursor-pointer" onClick={()=> handleSelection(item)}>
                        <img className={item.id === selection.id ? 'max-h-[26px]   rounded-lg border-2 border-accent-dark' : 'max-h-[24px]  border border-gray-600 rounded-lg'} src={item.iconSRC} />
                    </li>
                })}
        </ul>
                
           
        </div>
	</span>
  )
}

export default LangSwitcher