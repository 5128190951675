import Tippy from '@tippyjs/react'
import React, {useState} from 'react'
import ActionsDropDown from '../ActionsDropdown/ActionsDropDown'
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import SkeletonIMG from '../SkeletonIMG/SkeletonIMG'
import { Link } from 'react-router-dom'
import ShortAddress from '../Utils/ShortAddress'
import { useTranslation } from 'react-i18next';

function DomainCard(props) {
    const [isLoadedIMG, setIsLoadedIMG] = useState(false)
    const {t, i18n} = useTranslation('common');
    
    const {
        id,
        name,
        label,
        tag,
        available,
        labelHash,
        owner,
        ownerAlias,
        categories,
        from,
    } = props
    const imagesrc = `https://metadata.ens.domains/mainnet/0x57f1887a8bf19b14fc0df6fd9b2acc9af147ea85/${labelHash}/image`
    
  return (
    
    <div className='dark:bg-black  rounded-3xl block bg-white p-[1.1875rem] shadow-lg dark:shadow-gray-800   transition-shadow hover:shadow-2xl'>
        <Link to={`/domain/${name}`} state={{ ownerAlias:ownerAlias}}>
        <figure className="relative">
            
            <SkeletonIMG imgClass="w-[19rem]  h-[19rem] rounded-[0.625rem] object-cover" src={available ? "/images/ensavailableh.png" : imagesrc } skClass="w-[19rem]  h-[19rem] rounded-[0.625rem] "/>
            

            {tag && tag.length > 3 && <div className='bg-jacarta-700 absolute top-4 right-0 flex items-center space-x-1 rounded-md p-2'>
                <Tippy >
                    <p className='text-white capitalize'>{tag}</p>
                </Tippy>
            </div>}
            
        </figure>
        </Link>
        <div className="mt-4 flex items-center justify-between relative">
            <span className=" text-black hover:text-accent dark:text-white">
                {name}
            </span>
            {/*<FaEllipsisH className='dark:text-white'/>*/}
            <ActionsDropDown domain={name} owner={owner} categories={categories}/>

        </div>
        { from === "filter" && owner? 
        <div className=' text-gray-600 text-sm hover:text-accent dark:text-gray-400'>
            <a href={`/user/${owner}`}><>{`${t('itemdetails.main.owner')} `}</><ShortAddress address={owner} length={12}/></a>
        </div> : ''
}
        
    </div>
     
  )
}

export default DomainCard