import { verifyMessage } from 'ethers/lib/utils';
import React, { useEffect } from 'react'
import { Store } from 'react-notifications-component';
import { useSignMessage } from 'wagmi';
import { useTranslation } from 'react-i18next';

function SignButton({classname, title, fields, setSignature}) {
    const {t, i18n} = useTranslation('common');
    const {data, isError, signMessage, error , isSuccess} = useSignMessage(
        {onSuccess(data, variables) {
            // Verify signature when sign message succeeds
            const addr = verifyMessage(variables.message, data)
            setSignature({address: addr, message:variables.message, signature: data })
        },}
    )
    const handleSignature = () => {
        if(!fields) {
            return
        }
        const mess = JSON.stringify(fields)
        if(mess.legth < 5) {
            return
        }
        signMessage({message:mess})
     };

     useEffect(() => {
        if (error?.message) {
            let errorMess
            if (error.name === "ConnectorNotFoundError") {
                errorMess =  t('itemdetails.categorization.notification.signaturerr.err1')
                
            } else {
                errorMess = t('itemdetails.categorization.notification.signaturerr.err2')
            }
            Store.addNotification({
                title: t('itemdetails.categorization.notification.signaturerr.title'),
                message: errorMess,
                type: "danger",
                insert: "top",
                container: "top-right",
                dismiss: {
                  duration: 5000,
                  onScreen: true
                }
            });
        }
        
     }, [error] )

  return (
    <button className={classname} onClick={handleSignature}>
        {title}
    </button>
  )
}

export default SignButton