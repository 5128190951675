import React, {useState} from 'react'
import { Link, useLocation } from 'react-router-dom';


function DropDownNavButton({title, options, mainRoute, onNavigate}) {
	const location = useLocation();
	const [show, setShow] = useState('')
    const handleItemDropdown = (e) => {
	switchView()
};

	function switchView() {
		if(show.length < 2) {
			setShow("show")
			return
		} 
		setShow('')
	}

	if (options && options.length>0) {
		return (
			<li className={`js-nav-dropdown group relative ${show}`} >
				
				<button
					className="dropdown-toggle text-gray-700 font-display hover:text-accent focus:text-accent dark:hover:text-accent dark:focus:text-accent flex items-center justify-between py-3.5 text-base dark:text-white lg:px-5 w-full"
					
					onClick={(e) => handleItemDropdown(e)}
				>
					<span>{title}</span>
		
					{options?.length > 0 ?
					<i className="lg:hidden">
						<svg
							xmlns="http://www.w3.org/2000/svg"
							viewBox="0 0 24 24"
							width="24"
							height="24"
							className="h-4 w-4 dark:fill-white"
						>
							<path fill="none" d="M0 0h24v24H0z"></path>
							<path d="M12 13.172l4.95-4.95 1.414 1.414L12 16 5.636 9.636 7.05 8.222z"></path>
						</svg>
					</i> : ''}
				</button>
				
				<ul
					className={
					"dropdown-menu dark:bg-gray-900  bg-white top-[85%] z-20 min-w-[200px] gap-x-4 whitespace-nowrap rounded-xle transition-all will-change-transform group-hover:visible hidden group-hover:opacity-100 lg:invisible lg:absolute lg:grid lg:translate-y-4 lg:py-4 lg:px-2 lg:opacity-0 lg:shadow-2xl lg:group-hover:translate-y-2 lg:left-0 relative"}
				>
					{options?.map(({ id, text, ref }) => {
						return (
							<li key={id}>
								<Link to={ref}
								onClick={() => {
									switchView()
									onNavigate()}
								}
								>
									<div
										className="dark:hover:bg-gray-600   hover:text-accent  dark:text-white focus:text-accent hover:bg-gray-100 flex items-center rounded-xl px-5 py-2 transition-colors"											
									>
										<span
											className={
													' text-sm'
											}
										>
											{text}
										</span>	
									</div>
									</Link>
							</li>
						);
					})}
				</ul>
			</li>
		  )
	}

	return (
		<li className='js-nav-dropdown group relative'>
			<Link
					className={"dropdown-toggle text-gray-700 font-display hover:text-accent focus:text-accent dark:hover:text-accent dark:focus:text-accent flex items-center justify-between py-3.5 text-base dark:text-white lg:px-5 w-full"}
					to={mainRoute}
					onClick={() => {onNavigate()}}
				>
					<span className={location.pathname === mainRoute ? "text-accent " : ""}>{title}</span>
			</Link>
		</li>
	)
		
	

 
}

export default DropDownNavButton