import React from 'react'
import { useState } from 'react'
import { FaSearch } from 'react-icons/fa'
import CategoriesDropDown from '../../components/CategoriesDropDown/CategoriesDropDown'
import {useNavigate } from "react-router-dom";
import CatagoriesDropDownWithSearch from '../../components/CategoriesDropDown/CtagoriesDropDownWithSearch';
import GenericDropDown from '../../components/GenericDropDown/GenericDropDown';
import GenericForm from '../../components/GenericDropDown/GenericForm';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import { useTranslation } from 'react-i18next';




function FilterSpanish() {
  const {t, i18n} = useTranslation('common');
  const [advancedSpansih, setAdvancedSpansih] = useState(false)
  const [advancedGlobal, setAdvancedGlobal] = useState(false)

  const simpleFormItems = [
  {
    key: "substring",
    size: "large", 
    label: t('filters.shared.substring.title'), 
    inputType: "input", 
    options: {
      placeholder: t('filters.shared.substring.placeholder'), 
    },
  },
  {
    key: "start_with",
    size: "large", 
    label: t('filters.shared.start_with.title'), 
    inputType: "input", 
    options: {
      placeholder: t('filters.shared.start_with.placeholder'), 
    },
  },
  {
    key: "end_with",
    size: "large", 
    label: t('filters.shared.end_with.title'),  
    inputType: "input", 
    options: {
      placeholder: t('filters.shared.end_with.placeholder'),
    },
  },
  {
    key: "length_equals",
    size: "small", 
    label: t('filters.shared.length_equals.title'), 
    inputType: "number", 
    options: {
      placeholder:t('filters.shared.length_equals.placeholder'),
      min: 3,
    },
  },
  {
    key: "registered",
    size: "medium", 
    label: t('filters.spanish.registered.title'),
    inputType: "dropdown", 
    options: {
      items: [
        {value:false, text: t('filters.spanish.registered.option1')},
        {value:true, text: t('filters.spanish.registered.option2')},
        ]
  
    },
  },
  {
    key: "category",
    size: "medium", 
    label: t('filters.spanish.category.title'),
    inputType: "category-dropdown", 
  
  }
]

const advancedFormItems = [
  {
    key: "substring",
    size: "medium", 
    label: t('filters.shared.substring.title'), 
    inputType: "input", 
    options: {
      placeholder:  t('filters.shared.substring.placeholder'),
    },
  },
  {
    key: "no_substring",
    size: "medium", 
    label: t('filters.shared.no_substring.title'), 
    inputType: "input", 
    options: {
      placeholder: t('filters.shared.no_substring.placeholder'), 
    },
  },
  {
    key: "start_with",
    size: "medium", 
    label: t('filters.shared.start_with.title'), 
    inputType: "input", 
    options: {
      placeholder: t('filters.shared.start_with.placeholder'), 
    },
  },
  {
    key: "no_start_with",
    size: "medium", 
    label: t('filters.shared.no_start_with.title'), 
    inputType: "input", 
    options: {
      placeholder: t('filters.shared.no_start_with.placeholder'), 
    },
  },
  {
    key: "end_with",
    size: "medium", 
    label: t('filters.shared.end_with.title'), 
    inputType: "input", 
    options: {
      placeholder: t('filters.shared.end_with.placeholder'), 
    },
  },
  {
    key: "no_end_with",
    size: "medium", 
    label: t('filters.shared.no_end_with.title'), 
    inputType: "input", 
    options: {
      placeholder:t('filters.shared.no_end_with.placeholder'),
    },
  },
  {
    key: "length_gt",
    size: "small", 
    label:  t('filters.shared.length_gt.title'),
    inputType: "number", 
    options: {
      placeholder: t('filters.shared.length_gt.placeholder'),
      min: 3,
    },
  },
  {
    key: "length_lt",
    size: "small", 
    label:t('filters.shared.length_lt.title'),
    inputType: "number", 
    options: {
      placeholder: t('filters.shared.length_lt.placeholder'),
      min: 4,
    },
  },
  {
    key: "registered",
    size: "medium", 
    label: t('filters.spanish.registered.title'),
    inputType: "dropdown", 
    options: {
      items: [
        {value:false, text: t('filters.spanish.registered.option1')},
        {value:true, text: t('filters.spanish.registered.option2')},
        ]
  
    },
  },
  {
    key: "category",
    size: "medium", 
    label: t('filters.spanish.category.title'),
    inputType: "category-dropdown", 
  
  },
  {
    key: "no_category",
    size: "medium", 
    label: t('filters.spanish.no_category.title'),
    inputType: "category-dropdown", 
  },
  {
    key: "special_chars",
    size: "medium", 
    label: t('filters.shared.special_chars.title'), 
    inputType: "dropdown", 
    options: {
      items: [
        {value:true, text:  t('filters.shared.special_chars.option1')},
        {value:false, text: t('filters.shared.special_chars.option2')},
        ]
  
    },
  },
  
  {
    key: "emoji",
    size: "medium", 
    label: t('filters.shared.emojis.title'), 
    inputType: "dropdown", 
    options: {
      items: [
        {value:true, text: t('filters.shared.emojis.option1')},
        {value:false, text: t('filters.shared.emojis.option2')},
        ]
  
    },
  },

]

const globaladvancedFormItems = [
  {
    key: "substring",
    size: "medium", 
    label: t('filters.shared.substring.title'), 
    inputType: "input", 
    options: {
      placeholder: t('filters.shared.substring.placeholder'),
    },
  },
  {
    key: "no_substring",
    size: "medium", 
    label: t('filters.shared.no_substring.title'),
    inputType: "input", 
    options: {
      placeholder: t('filters.shared.no_substring.placeholder'), 
    },
  },
  {
    key: "start_with",
    size: "medium", 
    label: t('filters.shared.start_with.title'), 
    inputType: "input", 
    options: {
      placeholder: t('filters.shared.start_with.placeholder'), 
    },
  },
  {
    key: "no_start_with",
    size: "medium", 
    label: t('filters.shared.no_start_with.title'), 
    inputType: "input", 
    options: {
      placeholder: t('filters.shared.no_start_with.placeholder'), 
    },
  },
  {
    key: "end_with",
    size: "medium", 
    label:t('filters.shared.end_with.title'), 
    inputType: "input", 
    options: {
      placeholder: t('filters.shared.end_with.placeholder'), 
    },
  },
  {
    key: "no_end_with",
    size: "medium", 
    label: t('filters.shared.no_end_with.title'), 
    inputType: "input", 
    options: {
      placeholder: t('filters.shared.no_end_with.placeholder'),
    },
  },
  {
    key: "length_gt",
    size: "small", 
    label: t('filters.shared.length_gt.title'),
    inputType: "number", 
    options: {
      placeholder: t('filters.shared.length_gt.placeholder'),
      min: 3,
    },
  },
  {
    key: "length_lt",
    size: "small", 
    label: t('filters.shared.length_lt.title'), 
    inputType: "number", 
    options: {
      placeholder: t('filters.shared.length_lt.placeholder'),
      min: 4,
    },
  },
  {
    key: "special_chars",
    size: "medium", 
    label:t('filters.shared.special_chars.title'), 
    inputType: "dropdown", 
    options: {
      items: [
        {value:true, text: t('filters.shared.special_chars.option1')},
        {value:false, text: t('filters.shared.special_chars.option2')},
        ]
  
    },
  },
  
  {
    key: "emoji",
    size: "medium", 
    label: t('filters.shared.emojis.title'), 
    inputType: "dropdown", 
    options: {
      items: [
        {value:true, text: t('filters.shared.emojis.option1')},
        {value:false, text: t('filters.shared.emojis.option2')},
        ]
  
    },
  },

]

const globalSimpleForm =  [
  {
    key: "substring",
    size: "large", 
    label: t('filters.shared.substring.title'), 
    inputType: "input", 
    options: {
      placeholder:  t('filters.shared.substring.placeholder')
    },
  },
  {
    key: "start_with",
    size: "large", 
    label: t('filters.shared.start_with.title'), 
    inputType: "input", 
    options: {
      placeholder: t('filters.shared.start_with.placeholder')
    },
  },
  {
    key: "end_with",
    size: "large", 
    label: t('filters.shared.end_with.title'), 
    inputType: "input", 
    options: {
      placeholder: t('filters.shared.end_with.placeholder')
    },
  },
  {
    key: "length_equals",
    size: "small", 
    label: t('filters.shared.length_equals.title'),
    inputType: "number", 
    options: {
      placeholder: t('filters.shared.length_equals.placeholder'),
      min: 3,
    },
  },
  {
    key: "special_chars",
    size: "medium", 
    label: t('filters.shared.special_chars.title'), 
    inputType: "dropdown", 
    options: {
      items: [
        {value:true, text: t('filters.shared.special_chars.option1')},
        {value:false, text: t('filters.shared.special_chars.option2')},
        ]
  
    },
  },
  
  {
    key: "emoji",
    size: "medium", 
    label: t('filters.shared.emojis.title'), 
    inputType: "dropdown", 
    options: {
      items: [
        {value:true, text: t('filters.shared.emojis.option1')},
        {value:false, text: t('filters.shared.emojis.option2')},
        ]
  
    },
  },
]

  
  const navigate = useNavigate();

  function handlSpanishSubmit(params) {
    navigate("result",{state: {params:params, filter: "spanish"}})
  }

  function handleGlobalSubmit(params) {
    navigate("result",{state: {params:params, filter: "global"}})
}
  return (
    <section className="relative ">
      <Tabs selectedTabClassName="border-b-2 border-accent text-gray-800 font-semibold shadow-md">
        <TabList  className="nav nav-tabs flex items-center justify-center gap-5" >
           <Tab className="nav-item  text-gray-600 dark:text-gray-400 p-3 text-lg  mb-0.5 cursor-pointer"  key={"spanish"}> 
              Español
           </Tab>
           <Tab className="nav-item  text-gray-600 dark:text-gray-400 text-lg  p-3 mb-0.5 cursor-pointer"  key={"local"}> 
              Global
           </Tab>
        </TabList>
        <TabPanel>
						{/* <!-- Details --> */}
						<div
							className="tab-pane fade"
							id="details"
							role="tabpanel"
							aria-labelledby="details-tab"
						>
                <div className="container mb-40">
            <h1 className=" text-gray-700 py-14 text-center text-4xl font-medium dark:text-white">
                 {t('filters.spanish.title')}
            </h1>
            <div className="mx-auto max-w-[48.125rem] flex flex-col">
              <GenericForm formsItems={advancedSpansih ? advancedFormItems : simpleFormItems} onSubmit={handlSpanishSubmit}/>
              <button onClick={() => {setAdvancedSpansih(!advancedSpansih)}} className=' border-none border-accent text-accent self-end hover:underline '>{advancedSpansih ? `${t('filters.shared.simplified')} ->` : `${t('filters.shared.advanced')} ->`}</button>
              
            </div>
        </div>
              
            </div>
      
        
        </TabPanel>
        <TabPanel>
						{/* <!-- Details --> */}
						<div
							className="tab-pane fade"
							id="details"
							role="tabpanel"
							aria-labelledby="details-tab"
						>
                <div className="container mb-40">
            <h1 className=" text-gray-700 py-14 text-center text-4xl font-medium dark:text-white">
            {t('filters.global.title')}
            </h1>
            <div className="mx-auto max-w-[48.125rem] flex flex-col">
              <GenericForm formsItems={advancedGlobal ? globaladvancedFormItems :   globalSimpleForm} onSubmit={handleGlobalSubmit}/> 
              <button onClick={() => {setAdvancedGlobal(!advancedGlobal)}} className=' border-none border-accent text-accent self-end hover:underline '>{advancedGlobal ? `${t('filters.shared.simplified')} ->` : `${t('filters.shared.advanced')} ->`}</button>
              
            </div>
        </div>
              
            </div>
      
        
        </TabPanel>
      </Tabs>
    </section>
  )
}

export default FilterSpanish
