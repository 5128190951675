import React, {useState, useEffect} from 'react'
import SkeletonIMG from '../SkeletonIMG/SkeletonIMG';
import Tippy from '@tippyjs/react';
import CategorizationModal from './CategorizationModal';
import TextPopOver from '../TextPopOver/TextPopOver';
import { TwitterIcon, TwitterShareButton } from 'react-share';
import CopyButton from '../CopyButton/CopyButton';
import { useTranslation } from 'react-i18next';

const omit = "español"

function ItemDetailNoRegistered({name,categories }) {
    const {t, i18n} = useTranslation('common');
    const tag = categories?.Language === 'ES' ? "español" : undefined
    const [modal, setmodal] = useState(false)
    
  return (
    <section className="relative lg:mt-10 lg:pt-10 lg:pb-24 mt-18 pt-12 pb-24">
        <div className="container">
            <div className="md:flex md:flex-wrap md:items-center md:justify-around" key={name}>
                {/* <!-- Image --> */}
                <figure className="mb-8 md:w-2/5 lg:w-3/8 md:flex-shrink-0 md:flex-grow-0 md:basis-auto ">
                    <button className=" w-full relative">
                    <SkeletonIMG imgClass="rounded-2xl cursor-pointer w-full" src="/images/ensavailableh.png" skClass="rounded-2xl w-full pt-[100%]"/>
                        {tag && tag.length > 3 && <div className='bg-jacarta-700 text-lg absolute top-10 right-0 flex items-center space-x-1 rounded-md p-2'>
                            <Tippy >
                                <p className='text-white capitalize'>{tag}</p>
                            </Tippy>
                </div>}
                    </button>
                </figure>
                <div className="md:w-3/5 md:basis-auto md:pl-8 lg:w-1/2 lg:pl-[3.75rem] lg:pb-40">
                    <h1 className="font-display text-jacarta-700 mb-2 text-4xl font-semibold dark:text-white">
                        {name}
                    </h1>
                    <div className='pl-2 mt-5'>
                    <p className="dark:text-gray-300 text-gray-600">{t('itemdetails.available.title')}</p>
                    
                    </div>
                    <div className='flex justify-start mt-4 items-center gap-4 ml-2'>
                        
                        <TextPopOver text="Compartir en Twitter">
                            <TwitterShareButton
                                title={"Échale un vistazo a este dominio en @ENSesp"}
                                url={`https://ensesp.io/domain/${name}`}
                                hashtags={["ENS", "ENSESP"]}
                            >
                                
                                <TwitterIcon size={32} round />
                            </TwitterShareButton>
                            </TextPopOver>
                        <CopyButton text={`https://ensesp.io/domain/${name}`} btnClass="h-[28px] w-[28px]  cursor-pointer mb-[1px]"/>                   
                    </div>
                    <div className="mb-4 mt-10 flex flex-wrap gap-x-4 gap-y-3">
                    {categories.approved?.map((categorization)=> {
                        const category = categorization.Category
                        if (category === omit) {
                            return
                        }
                        return <a href={`/category/${category}`} className='dark:border-gray-600 dark:bg-gray-900 hover:shadow-lg dark:shadow-gray-800 group hover:border-transparent dark:hover:border-transparent  border-jacarta-100 font-display text-jacarta-500 flex h-9 items-center rounded-lg border bg-white px-4 text-sm font-semibold transition-colors   dark:text-white '>
                            {category}
                        </a>
                    })}

                        <a target={"_blank"} href={`https://app.ens.domains/name/${name}/register`} className='bg-accent lg:max-w-[600px] shadow-accent-volume hover:bg-accent-dark inline-block w-full rounded-full py-3 px-8 text-center font-semibold text-white transition-all'>
                        {/*<img className='w-[30px] h-[30px]' src="https://raw.githubusercontent.com/ensdomains/ensdomains-v2/5dd186fd7311249c3f28df5130a7dff30b487f38/src/assets/ensLogo.svg"></img>*/}
                            {t('itemdetails.available.ensdomains')}
                        </a>
                    </div>
                   
                    {/*<CategorizationModal open={modal} setOpen={setmodal} domain={name} domainCategories={categories?.Categories}/>*/}
                </div>
            </div>
        </div>
    </section>
  )
}

export default ItemDetailNoRegistered