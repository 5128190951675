import React from 'react'
import TextPopOver from '../TextPopOver/TextPopOver'
import { useTranslation } from 'react-i18next';

function SeeInEtherScan({route, className = "w-[32px] h-[32px]"}) {
  const {t, i18n} = useTranslation('common');
  return (
    <figure className={className}>
    <TextPopOver text={t('utils.etherscan')}>
        <a alt="cosa" target="_blank" href={`https://etherscan.io/${route}`}> 
            <img className='dark:hidden' src="https://etherscan.io/images/brandassets/etherscan-logo-circle.png"></img> 
            <img className='hidden dark:block' src="https://etherscan.io/images/brandassets/etherscan-logo-light-circle.png"></img>
        </a>

    </TextPopOver>
</figure>
  )
}

export default SeeInEtherScan