import React from 'react'
import { useEnsAddress } from 'wagmi'
import User from './User'

function UserNoContextAddress({username}) {
    const { data } = useEnsAddress({
        name: username,
      })
      return (
        <User address={data} user={username}/>
      )
}

export default UserNoContextAddress