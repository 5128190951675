import React from 'react'
import { useGetStatsResumeQuery } from '../../services/domainsAPI'
import { FaEye } from 'react-icons/fa';
import LoadingSpinner from '../Utils/LoadingSpinner'
import NotFound from '../Utils/NotFound'
import StatsTiles from './StatsTiles';
import { useTranslation } from 'react-i18next';


const LIMIT_TO_LOAD = 50000

function Resume({showTitle=true, showLoadSpinner=false}) {
	const {t, i18n} = useTranslation('common');
    const {data, isLoading, isError} = useGetStatsResumeQuery()
    if(isLoading) {
		return showLoadSpinner ? <div className='mt-5 flex justify-center items-center flex-col'>
		<LoadingSpinner />
	   	<div className='mt-2 dark:text-white'>{t('stats.loading')}</div>
	   </div> :<div>
		</div>
	 }
     
	 if(!data) {
		return <NotFound text={t('stats.error')}/>
	  }
    const statsDisplay = [
		{
			key: "categories_count",
			label: t('stats.resume.categories_count'),
			value: data["categories-count"],
			to: "/categories"

		},
		{
			key: "global_domains",
			label: t('stats.resume.global_domains'),
			value: data?.["domains-count"]?.toLocaleString(undefined) ,
		},
		{
			key: "domains_in_categories",
			label: t('stats.resume.domains_in_categories'),
			value: data["spanish-domains-in-categories"]?.toLocaleString(undefined) ,
		},
		{
			key: "domains_in_spanish_registered",
			label: t('stats.resume.domains_in_spanish_registered'),
			value: data["spanish-registered"].toLocaleString(undefined) ,
		},
		{
			key: "domains_in_spanish_not_registered",
			label: t('stats.resume.domains_in_spanish_not_registered'),
			value: (data["spanish-domains-in-categories"] - data["spanish-registered"]).toLocaleString(undefined) ,
		},
		
		{
			key: "categorizations_last_month",
			label: t('stats.resume.categorizations_last_month'),
			value: data["categorization-last-month"].toLocaleString(undefined) ,
			
		},
		{
			key: "domains_in_spanish_last_month",
			label: t('stats.resume.domains_in_spanish_last_month'),
			value: data["spanish-registered-last-month"].toLocaleString(undefined) ,
			to: (data["spanish-registered-last-month"] > LIMIT_TO_LOAD || data["spanish-registered-last-month"]  <1 ) ? undefined : "/stats/detailed",
			state: {query: "/stats/spanish-registered/last?verbose=true&interval=month"}
		},
		{
			key: "words_spanish_3_letters",
			label: t('stats.resume.words_spanish_3_letters'),
			value: data["3letters-spanish"].toLocaleString(undefined) ,
			to: (data["3letters-spanish"]> LIMIT_TO_LOAD || data["3letters-spanish"]  <1 ) ? undefined : "/stats/detailed",
			state: {query: "/stats/nletters?verbose=true&count=3"}
		},
		{
			key: "domains-more-3-categories",
			label: t('stats.resume.domains-more-3-categories'),
			value: data["categorization-more-than-3-categories"].toLocaleString(undefined),
			to: (data["categorization-more-than-3-categories"]> LIMIT_TO_LOAD || data["categorization-more-than-3-categories"]  <1 ) ? undefined : "/stats/detailed",
			state: {query: "/stats/ncategories?verbose=true"}
		},
        
	 ]
  return (
	<>
	{ showTitle ?
	<h1 className=" text-gray-700 py-3 text-center text-3xl font-medium dark:text-white">
	{t('stats.resume.title')}
	</h1> : ''
	}
	<StatsTiles stats={statsDisplay}/>
	</>
	
  )
}

export default Resume