import React from 'react'

import { ConnectButton } from '@rainbow-me/rainbowkit';

function WalletButton() {
  return (
    <ConnectButton/>
  )
}

export default WalletButton