import React, {useState, useEffect} from 'react'
import { FaExchangeAlt } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import BackToTop from '../../components/BackToTopButton/BackToTop';
import CategoriesCollapsible from '../../components/CategoriesDropDown/CategoriesCollapsible';
import CategoryIcon from '../../components/CategoryIcon/CategoryIcon';
import GenericDropDown from '../../components/GenericDropDown/GenericDropDown';
import LoadingSpinner from '../../components/Utils/LoadingSpinner';
import NotFound from '../../components/Utils/NotFound';
import { useGetCategoriesTicketsQuery } from '../../services/domainsAPI';
import { useTranslation } from 'react-i18next';

const omit = "español"
const noGroup = "otros"

const minPoints = 30

const domainsSortOptions = [
	{
		id: "relevance",
		title: "más relevantes",
		sortCondition: function(a, b) {return   b.points - a.points},
	},
	{
		id: "alfabetically",
		title: "alfabéticamente",
		sortCondition: function(a, b) {return a.category.localeCompare(b.category)}
	},
]



function Categories() {
	const {t, i18n} = useTranslation('common');
	const {data, isLoading, isError} = useGetCategoriesTicketsQuery()
	const [sortIndex, setSortIndex] = useState(0)
	const [categories, setCategories] = useState([])
	const [filteredCategories, setFilteredCategories] = useState([])


	const sortOptions = [
		{
			id: "relevance",
			title: t('categories.groups.sortOptions.option1'),
			sortCondition: function(a, b) {return   b.points - a.points},
		},
		{
			id: "alfabetically",
			title: t('categories.groups.sortOptions.option2'),
			sortCondition: function(a, b) {return a.group.localeCompare(b.group)}
		},
	]

	
	function handleChange(e) {
		const input = e.target.value
		
		const nGrups = categories.map((group) => {
			
			const nCats = group.categories.filter((cat) => {
				return cat.category.toLowerCase().includes(input.toLowerCase())
			})

			return {group: group.group, categories: nCats, points: group.points}
		})
		setFilteredCategories(nGrups)
	}

	useEffect(() => {
		if(isLoading || isError || !data) {
		 return
		}
		 const groupMap = new Map();
		 data.categories.forEach(element => {
			if (element.points < minPoints) {
				return
			}
			let group
			if (!element.group || element.group.length < 1) {
				
				group = noGroup
			} else {
				group = element.group
			}
			
			const val = groupMap.get(group)
			
			if (!val) {
				groupMap.set(group, {
					categories: [element],
					points: element.points
				})
			} else {
				groupMap.set(group, {
					categories: [...val.categories, element],
					points: val.points + element.points
				})
			}
		 })
		 

		 const groupsArr =[]

		 groupMap.forEach((value, key)=> {

			groupsArr.push({group: key, categories: value.categories, points: value.points})
		 })


		setCategories(groupsArr)
		setFilteredCategories(groupsArr)
	  
		
	  }, [isLoading])

	if(isLoading) {
		return <div className='mt-5 flex justify-center items-center flex-col'>
		 <LoadingSpinner />
		<div className='mt-2 dark:text-white'>{t('categories.loading')}</div>
		</div>
	 }

	 if(!data || !data.categories || data.categories.length < 1 || isError || categories.length < 1) {
		return <NotFound text={t('categories.error')}/>
	  }

	  

	 
	 

	 function handleSwitch() {
		const newSort = (sortIndex+1)%sortOptions.length
		setSortIndex(newSort)
		const nGroups = categories.map((group) => {
			const sorted = [...group.categories].sort(domainsSortOptions[newSort].sortCondition)
			return {group: group.group, categories: sorted, points: group.points}
		})
		
		const sortedCategories = nGroups.sort(sortOptions[newSort].sortCondition)
		
		setCategories(sortedCategories)
		setFilteredCategories(sortedCategories)
	 }


	 const statsDisplay = [
		{
			key: "categories_count",
			label: t('categories.stats.categories_count'),
			value: data?.categories?.length -1 ,

		},
		{
			key: "global_domains",
			label: t('categories.stats.global_domains'),
			value: data?.domains_count.toLocaleString(undefined) ,
		},
		{
			key: "domains_in_categories",
			label: t('categories.stats.domains_in_categories'),
			value: data?.spanish_count.toLocaleString(undefined) ,
		},
		{
			key: "domains_in_spanish_registered",
			label: t('categories.stats.domains_in_spanish_registered'),
			value: data?.spanish_registered_count.toLocaleString(undefined) ,
		},
		{
			key: "domains_in_spanish_not_registered",
			label: t('categories.stats.domains_in_spanish_not_registered'),
			value: (data?.spanish_count - data?.spanish_registered_count).toLocaleString(undefined) ,
		},
		{
			key: "domains_in_spanish_with_special",
			label: t('categories.stats.domains_in_spanish_with_special'),
			value: data?.spanish_registered_special_count.toLocaleString(undefined) ,
		},
	 ]


	
  return (
	<div>
		<img loading="lazy" className='rounded-2xl shadow-md shadow-accent mt-10 md:hidden' src="/images/categories_page_bannermobile.jpg"></img>
		<img loading="lazy" className='rounded-2xl shadow-md shadow-accent mt-5 hidden md:block' src="/images/categories_page_bannerdesk.jpg"></img>
		<div className="my-5 w-full flex gap-4 flex-wrap justify-center">
		{statsDisplay.map(stat => {
			return <div className="w-full rounded-2lg bg-gray-100 p-4 text-center dark:bg-gray-900 sm:w-[32%]">
				<span className="block font-display text-3xl text-transparent  bg-clip-text bg-gradient-to-r from-[#fca5a5] via-accent to-[#a855f7]">{stat.value}</span>
				<span className="block font-display  text-gray-600 dark:text-white mt-1">
				{stat.label}
				</span>
			</div>
		})}
		
							
		</div>
		<div className='text-center'>
		<Link to={"/stats"} className='text-accent underline '>{t('categories.stats.title')}</Link>	</div>			
		<div className='flex flex-col justify-center gap-5 mb-10'>
		<div className='flex justify-center flex-wrap  items-center gap-2 pt-12 text-4xl' >
               <h1 className=" text-gray-700  text-center   dark:text-white">{t('categories.groups.title')}</h1> 
			   <span onClick={() => handleSwitch()} className='flex font-medium text-transparent  bg-clip-text bg-gradient-to-r from-[#fca5a5]  via-accent-light to-accent  items-center gap-2 cursor-pointer  md:mt-[1px] hover:animate-pulse'>
			   {sortOptions[sortIndex].title}
			   <FaExchangeAlt className='text-3xl text-accent ml-1 mt-1' />
			   </span>
			   
          	</div>
			  <input				
				className=" text-gray-700   border-gray-400 dark:border-gray-600 md:mx-[80px]  rounded-2xl border py-2.5 pl-10  dark:bg-gray-900 dark:text-white"
				placeholder={t('categories.groups.placeholder')}
				onChange={e => handleChange(e)}
			/>
		</div>
			
		 
			<BackToTop/>
	
		<div className='flex flex-wrap justify-center md:justify-between  gap-3 mb-36'> 
		{
			
			filteredCategories.map(value=> {
				const groupIMG = value.group
				return <div className='lg:min-w-[49%] sm:min-w-[320px] w-full md:max-w-[49%] '>
					<CategoriesCollapsible group={value.group} categories={value.categories} imgSRC={`/images/group/${groupIMG}.png`}/> 
				</div>
			}
				
			)
		}
		</div>
	
    
	</div>
	
  )
}

export default Categories


/*


*/