import React from 'react'
import { useCategoriesForLabelQuery } from '../../services/domainsAPI'
import ItemDetailPage from './ItemDetailPage'
import { useEnsName} from 'wagmi'


function ItemDetailNoContext({domainName, data, registered, categories}) {
 const { data: alias, isError } = useEnsName({
  address: data.registrations[0]?.registrant?.id,
})
 
  return (
    <ItemDetailPage domainName={domainName} ownerAlias={alias} categories={categories} registration={data?.registrations[0]} registered={registered} loadingCategories={false}/>
  )
}

export default ItemDetailNoContext