import React from 'react'
import { useLocation, useParams } from 'react-router-dom'
import LoadingSpinner from '../../components/Utils/LoadingSpinner'
import NotFound from '../../components/Utils/NotFound'
import { useGetDomainsStatsDetailedQuery } from '../../services/domainsAPI'
import FilterResultBuilder from '../Filters/FilterResultBuilder'

function GenericDomainCategoryDetailed() {
    const {state} = useLocation()
	
    const {data, isLoading, isError} = useGetDomainsStatsDetailedQuery({queryURL:state.query})
    if(isLoading) {
		return <div className='mt-5 flex justify-center items-center flex-col'>
		 <LoadingSpinner />
		<div className='mt-2 dark:text-white'>Cargando historial</div>
		</div>
	 }
   
	 if(!data || !data.involved || isError) {
		return <NotFound text="No encontramos dominios para ese período"/>
	  }
      const domains = data.involved?.map(item => {
        const vals = item.split('-', 2)
		if (vals[0].endsWith(".eth")) {
			return vals[0].slice(0,vals[0].length - 4)
		}
		return vals[0]
	  })
  return (
    <FilterResultBuilder domains={domains} from="local" ></FilterResultBuilder>
  )
}

export default GenericDomainCategoryDetailed