import React, { useRef, useState } from 'react'
import { useEffect } from 'react';
import { FaCheck } from 'react-icons/fa';
import useOutsideAlerter from '../../hooks/click_outside';
import { useGetCategoriesQuery } from '../../services/domainsAPI';
import { useTranslation } from 'react-i18next';

function CatagoriesDropDownWithSearch({buttonClassName, setCategorie}) {
    const {t, i18n} = useTranslation('common');
    const [selection, setSelection] = useState(t('utils.no_selection'))
    const wrapperRef = useRef(null)
    const {clickOutside, setClickOutside} = useOutsideAlerter(wrapperRef)
    //const categories = ["verbos", "adjetivos", "sustantivos", "razón"]
    const [dropdown, setDropdown] = useState(false);
    const {data: items, isLoading, isError } = useGetCategoriesQuery()
    const [categories, setCategories] = useState([])
    const [filteredCategories, setfilteredCategories] = useState([])

    
    function handleSelection(item) {
        if (item=== selection) {
          setCategorie(undefined)
          setSelection( t('utils.no_selection'))
          return
        }
        setSelection(item)
        setCategorie(item)
        setDropdown(false)
    }

    function handleChange(e) {
        const input = e.target.value
        const nFiltered = categories.filter(cat => {
            return cat.toLowerCase().includes(input.toLowerCase())
        })
        setfilteredCategories(nFiltered)
    }

    function handleDrownDown() {
          if (!clickOutside) {
            setDropdown(!dropdown)
        } 
        if (clickOutside) {
            setDropdown(true)
        }
        setClickOutside(false)


    }

    useEffect(() => {
      if (isLoading || isError || !items) {
        return
      }
      const nCats = []
      items.map(item => {
        if (item.points < 1) {
            return
        }
        nCats.push(item.category)
      })
      const sorted = nCats.sort((a,b) => {return a.localeCompare(b)})
      setCategories(sorted)
      setfilteredCategories(sorted)
    
      
    }, [items])
    

  return (
    <div className='relative' ref={wrapperRef}>
        <button  onClick={handleDrownDown} className="dark:text-white bg-white border-gray-400 border dark:border-gray-600 rounded-lg w-full py-3 px-3  flex items-center  justify-between dark:bg-gray-700" type="button">{selection}<svg class="w-4 h-4 ml-2" aria-hidden="true" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7"></path></svg></button>

<div className={dropdown && !clickOutside ? "absolute w-full z-50  bg-white rounded divide-y divide-gray-100 shadow dark:bg-gray-700" : "hidden"}>
    <div class="p-3">
      <div class="relative">
        <div class="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
          <svg class="w-5 h-5 text-gray-500 dark:text-gray-400" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z" clip-rule="evenodd"></path></svg>
        </div>
        <input type="text" onChange={e => handleChange(e)} className="block w-full p-2 pl-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" 
        placeholder={t('filters.spanish.category.placeholder')}/>
      </div>
    </div>
    <ul className="h-48 px-3 pb-3 overflow-y-auto text-sm text-gray-700 dark:text-gray-200" >
      {filteredCategories.map(cat=> {
      return <li onClick={() => handleSelection(cat)}>
        <div className="flex items-center pl-2 rounded hover:bg-gray-100 dark:hover:bg-gray-600">
          
          <label  className="w-full py-2 ml-2 text-sm font-medium text-gray-900 rounded dark:text-gray-300">{cat}</label>
          {cat === selection ? <FaCheck className='text-accent mr-2'/> : ''}
        </div>
      </li>
      })}
     
    </ul>
   
</div>
    </div>
  )
}

export default CatagoriesDropDownWithSearch